import React from "react";

import Layout from "../Layouts/Layout";
import OptimizingMarketingPerformance from "../Components/Case Studies/OptimizingMarketingPerformance";

export default function OptimizingMarketingPerformancePage() {
  return (
    <Layout>
      <div>
        <OptimizingMarketingPerformance />
      </div>
    </Layout>
  );
}
