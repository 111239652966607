import React from "react";

import DataManagementConsulting from "../Components/DataManagementConsulting";
import Layout from "../Layouts/Layout";

export default function DataManagementConsultingPage() {
  return (
    <Layout>
      <div>
        <DataManagementConsulting />
      </div>
    </Layout>
  );
}
