import React, { useState, useEffect, useRef } from "react";
import "../Styles/AIConsulting.css";
import DataStrategyModal from "./DataStrategyModal";
import PhoneInput from "react-phone-number-input";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import marketing_business_intelligence from "../Assets/Img/marketing_business_intelligence.png";
import ai_consulting_blog from "../Assets/Img/ai_consulting_blog.png";
import ai_consulting_case_study from "../Assets/Img/ai_consulting_case_study.png";

export default function AIConsulting() {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [position, setPosition] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const LocationData = [
    { id: 1, title: "USA", label: "usa" },
    { id: 2, title: "UK", label: "uk" },
    { id: 3, title: "EU (EUROPEAN UNION)", label: "eu" },
    { id: 4, title: "AUSTRALIA", label: "au" },
    { id: 5, title: "OTHER", label: "other" },
  ];

  const PositionData = [
    { id: 1, title: "Manager", label: "manager" },
    { id: 2, title: "Director", label: "director" },
    { id: 3, title: "VP", label: "vp" },
    { id: 4, title: "CxO", label: "cxo" },
    { id: 5, title: "Other", label: "other" },
  ];

  const form = useRef();
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();
    // navigate("/");

    emailjs
      .sendForm("service_rjnzoj6", "template_xpcbqbx", form.current, {
        publicKey: "dLGaSfNHHKUpDuLYk",
      })

      .then(
        () => {
          setSubmissionSuccess(true);
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert("An error occurred while sending the message.");
        }
      );

    form.current.reset();
    setLocation("");
    setPosition("");
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const title = "Artificial intelligence (AI) Consulting | White Box Data";
  const DESCRIPTION =
    "Unlock the power of AI with our expert AI consulting services. From AI strategy and adoption to implementation and analytics, we are your strategic AI partner.";

  useEffect(() => {
    document.title = title;
  });

  return (
    <div className="data-strategy-container-main">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      >
        <link
          rel="canonical"
          href="https://www.whiteboxdata.com/ai-consulting-services"
        />
      </Helmet>
      <section className="bi_consulting_landing">
        <div className="bi_consulting_landing_text">
          <h1>Artificial intelligence (AI) Consulting</h1>

          <div className="marketing_analytics_landing_boxes">
            <div className="marketing_analytics_box">
              <h3>Deep Expertise</h3>
            </div>
            <div className="marketing_analytics_box">
              <h3>Industry-Specific Solutions</h3>
            </div>
            <div className="marketing_analytics_box">
              <h3>Maximize ROI</h3>
            </div>
          </div>
          <p>
            Our AI Consulting services are designed to help organizations
            leverage AI-driven analytics, optimize operations and unlock new
            growth opportunities. Whether you're at the early stages of AI
            adoption or looking to enhance your existing AI capabilities, our
            team of experts is here to guide you every step of the way.
          </p>
        </div>
      </section>

      <section className="data-driven-insights-container">
        <h2>AI Driven Solutions for Growth</h2>
        <p>
          Artificial intelligence (AI) is no longer just a buzzword — it is
          rapidly becoming a critical component of business growth and success.
          It should go without saying that for any successful AI project, having
          a sound strategy is a must. It is crucial to understand your business
          goals and challenges and develop a tailored AI strategy that aligns
          with your vision. Each solution that needs to be developed must solve
          a purpose. It is easy to get caught up in the FOMO and start building
          out products and solutions in the race to stay ahead of competitors.
          But it is a sure shot recipe of wasted resources.
        </p>

        <p>
          It is imperative to assess your current infrastructure and identify
          opportunities for AI adoption rather than trying to build the next
          shiny AI tool in the market. We must ensure a smooth integration of AI
          into your business workflows, minimizing disruption and maximizing
          efficiency. At White Box Data, we help you make systematic and
          data-driven headways with your AI solutions ensuring business success.
        </p>
        <h2>Our AI Consulting Services</h2>

        <section>
          <div className="data-driven-insights-section">
            <div className="data-driven-insights-box bi_consulting_img_height">
              <h3 className="h-auto">AI Strategy Development</h3>
              <span className="tableau_consulting_span">
                We help you craft a comprehensive AI strategy tailored to your
                business goals. From identifying use cases to defining ROI
                metrics, we ensure your AI investments align with your strategic
                objectives.
              </span>
            </div>
            <div className="data-driven-insights-box">
              <h3>AI Implementation</h3>
              <span className="tableau_consulting_span">
                Our team of experienced AI engineers and data scientists will
                handle the entire AI implementation process, from data
                preparation and model development to deployment and monitoring.
                We specialize in smooth AI adoption with minimal disruption.
              </span>
            </div>
            <div className="data-driven-insights-box">
              <h3>AI-Driven Analytics</h3>
              <span className="tableau_consulting_span">
                Unlock the full potential of your data with AI-driven analytics.
                Our solutions helps you uncover hidden patterns, trends, and
                correlations, empowering you to make data-driven decisions that
                boost performance, profitability and competitive advantage.
              </span>
            </div>
          </div>
        </section>

        <section className="mt-[-2%] ai_consulting_services_boxes">
          <div className="data-driven-insights-section">
            <div className="data-driven-insights-box">
              <h3>AI Training and Adoption</h3>
              <span className="tableau_consulting_span">
                We offer training programs to help your team get up to speed
                with AI tools and techniques. From initial strategy development
                to ongoing maintenance and optimization, providing comprehensive
                support throughout your AI journey.
              </span>
            </div>
            <div className="data-driven-insights-box">
              <h3>Custom AI Solutions</h3>
              <span className="tableau_consulting_span">
                Every business is unique, and so are its needs. We design and
                develop custom AI solutions that address your specific
                challenges and opportunities, from predictive analytics to
                machine learning models.
              </span>
            </div>
          </div>
        </section>

        <div className="how_we_work_section">
          <h2>How We Work</h2>
          <div className="ai_consulting_how_we_work">
            <div className="ipad_ai_consulting_box">
              <div className="ai_consulting_box">
                <div className="ai_box_numbers">
                  <h5>1</h5>
                  <div>
                    <h3>Discovery and Assessment</h3>
                    <p>
                      We begin by understanding your business, challenges, and
                      objectives. This helps us identify the most impactful AI
                      use cases.
                    </p>
                  </div>
                </div>
              </div>
              <div className="ai_consulting_box">
                <div className="ai_box_numbers">
                  <h5>2</h5>
                  <div>
                    <h3>Custom AI Strategy Design</h3>
                    <p>
                      We develop a tailored AI strategy that aligns with your
                      goals, complete with a roadmap for implementation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="ipad_ai_consulting_box">
              <div className="ai_consulting_box">
                <div className="ai_box_numbers">
                  <h5>3</h5>
                  <div>
                    <h3>Implementation and Integration</h3>
                    <p>
                      Our team works closely with yours to implement AI
                      solutions, ensuring they integrate seamlessly with your
                      existing processes.
                    </p>
                  </div>
                </div>
              </div>
              <div className="ai_consulting_box">
                <div className="ai_box_numbers">
                  <h5>4</h5>
                  <div>
                    <h3>Continuous Support and Optimization</h3>
                    <p>
                      AI is a journey, and we strive to be your North Star. We
                      provide ongoing support to optimize your AI systems and
                      ensure they evolve with your business needs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2>FAQs</h2>

        <p>
          <strong className="font-semibold">What is AI Consulting?</strong>
          <br />
          AI Consulting involves helping businesses adopt and implement AI
          technologies to solve specific problems and achieve business goals.
        </p>

        <p>
          <strong className="font-semibold">
            How does AI Consulting benefit my business?
          </strong>
          <br />
          AI can automate processes, improve decision-making, and unlock new
          revenue streams by leveraging data more effectively.
        </p>

        <p>
          <strong className="font-semibold">
            What industries can benefit from AI Consulting?
          </strong>
          <br />
          AI has applications across various industries, including healthcare,
          finance, retail, manufacturing, and more.
        </p>

        <p>
          <strong className="font-semibold">
            How long does it take to implement AI solutions?
          </strong>
          <br />
          The timeline for AI implementation varies based on the complexity of
          the project and the readiness of your data and infrastructure.
          Typically, projects can range from a few weeks to several months.
        </p>

        <p>
          <strong className="font-semibold">
            Can AI be integrated with our existing systems?
          </strong>
          <br />
          Yes, we ensure that AI solutions are seamlessly integrated with your
          current systems and workflows to enhance their functionality without
          causing disruptions.
        </p>

        <p>
          <strong className="font-semibold">
            What kind of ROI can we expect from AI implementation?
          </strong>
          <br />
          The ROI from AI implementation varies depending on the use case.
          However, businesses typically see significant improvements in
          efficiency, cost savings, and revenue growth.
        </p>

        <p>
          <strong className="font-semibold">
            Do you provide AI training for our team?
          </strong>
          <br />
          Yes, we offer comprehensive training programs to ensure your team can
          effectively use and manage AI tools and technologies.
        </p>

        <p>
          <strong className="font-semibold">
            Is AI consulting only for large enterprises?
          </strong>
          <br />
          No, AI consulting is beneficial for businesses of all sizes. We tailor
          our services to meet the needs of both small businesses and large
          corporations.
        </p>
      </section>

      <div className="datastrategy-form ai_consulting_datastrategy_Form">
        <p className="ai_consulting_form_text">
          Ready to transform your business with AI? Contact us today!
        </p>
        {!submissionSuccess && (
          <form ref={form} onSubmit={sendEmail}>
            <div className="grid gap-6 mb-6 md:grid-cols-3">
              <div>
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  First Name*
                </label>
                <input
                  type="text"
                  id="first_name"
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_first_name"
                  required
                  pattern="[A-Za-z\s]+"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Last Name*
                </label>
                <input
                  type="text"
                  id="last_name"
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_last_name"
                  required
                  pattern="[A-Za-z\s]+"
                  value={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              <div className="mb-6">
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Phone*
                </label>
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  name="from_mobile"
                  required
                  defaultCountry="US"
                  value={phone}
                  onChange={setPhone}
                  className="flex text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                />
              </div>

              <div className="mb-6">
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Email Address*
                </label>
                <input
                  type="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div>
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Position*
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_position"
                  required
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                >
                  <option value="" disabled>
                    Select a Position
                  </option>
                  {PositionData.map((item) => (
                    <option key={item.id} value={item.title}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Location*
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_location"
                  required
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                >
                  <option value="" disabled>
                    Select a location
                  </option>
                  {LocationData.map((item) => (
                    <option key={item.id} value={item.title}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                Message
              </label>
              <textarea
                className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block width-message p-2.5 h-[7rem]"
                type="text"
                as="textarea"
                name="message"
                // required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>

            <button
              type="submit"
              className="text-white bg-[#0156b8] font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-3.5 text-center"
            >
              Submit
            </button>
          </form>
        )}
        {submissionSuccess && (
          <div className="message_ai_consulting">
            <div className="success-ai_consulting" id="success">
              Thank you! We have received your Information and one of our Expert
              Consultant will reach out within 24 hrs. We are eager to learn
              more and help you on your Data Journey.
            </div>
          </div>
        )}
      </div>

      <div className="marketing_services_container bi_cta_boxes">
        <div className="marketing_services_box">
          <a href="/case-studies/bi-driven-analytics-transformation-for-a-lending-company">
            <div className="marketing_services_image">
              <img src={ai_consulting_case_study} alt="" loading="lazy"></img>
            </div>
            <div className="bi_consulting_title">
              <p>Case Study</p>
              <span className="">
                Business Intelligence Driven Analytics Transformation
              </span>
            </div>
          </a>
        </div>
        <div className="marketing_services_box">
          <a href="/blog/marketing-analytics-consulting-a-fractional-approach">
            <div className="marketing_services_image">
              <img src={ai_consulting_blog} alt="" loading="lazy"></img>
            </div>
            <div className="bi_consulting_title">
              <p>Blog</p>
              <span className="">Marketing Analytics Fractional Approach</span>
            </div>
          </a>
        </div>

        <div className="marketing_services_box">
          <a href="/services/business-intelligence-and-analytics">
            <div className="marketing_services_image">
              <img
                src={marketing_business_intelligence}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <div className="bi_consulting_title">
              <p>Services</p>
              <span className="">Business Intelligence & Analytics</span>
            </div>
          </a>
        </div>
      </div>

      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setIsModalOpen(true);
        }}
        className="consulting_fixed_btn"
      >
        Talk to a Data Expert
      </a>

      <DataStrategyModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}
