import React from "react";

import Layout from "../Layouts/Layout";
import MarketingAnalyticsConsultingBlog from "../Components/Blogs/MarketingAnalyticsConsultingBlog";

export default function MarketingAnalyticsConsultingBlogPage() {
  return (
    <Layout>
      <div>
        <MarketingAnalyticsConsultingBlog />
      </div>
    </Layout>
  );
}
