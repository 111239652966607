import React from "react";

import Layout from "../Layouts/Layout";
import BiTransformFinancialBlog from "../Components/Blogs/BiTransformFinancialBlog";

export default function BiTransformFinancialBlogPage() {
  return (
    <Layout>
      <div>
        <BiTransformFinancialBlog />
      </div>
    </Layout>
  );
}
