import React from "react";

import Layout from "../Layouts/Layout";
import BiTransformHealthcareBlog from "../Components/Blogs/BiTransformHealthcareBlog";

export default function BiTransformHealthcareBlogPage() {
  return (
    <Layout>
      <div>
        <BiTransformHealthcareBlog />
      </div>
    </Layout>
  );
}
