import React, { useState, useEffect, useRef } from "react";
import "../Styles/DataManagementConsulting.css";
import DataStrategyModal from "./DataStrategyModal";
import DMCETLvsELT from "../Assets/Img/DMCETLvsELT.png";
import DMCETLTools from "../Assets/Img/DMCETLTools.png";
import DMCDataManagementBlog from "../Assets/Img/DMCDataManagementBlog.png";
import Advanced_Analytics from "../Assets/Img/Advanced Analytics.png";
import Data_Strategy from "../Assets/Img/Data Strategy.png";
import data_architecture from "../Assets/icons/data architecture.svg";
import data_governance from "../Assets/icons/data governance.svg";
import quality_framework from "../Assets/icons/quality framework.svg";
import cloud_data_migration from "../Assets/icons/cloud and data migration.svg";
import PhoneInput from "react-phone-number-input";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import DataManagementConsultingImage from "../Assets/Img/DataManagementConsultingImage.png";

export default function DataManagementConsulting() {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);

  const ServiceData = [
    { id: 1, title: "Data Strategy" },
    { id: 2, title: "Data Warehouse & Data Lake Implementation" },
    { id: 3, title: "Data Analytics Enablement" },
    { id: 4, title: "Data Governance & Quality" },
    { id: 5, title: "Data Migration and Cloud Services" },
  ];

  const form = useRef();
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();
    // navigate("/");

    emailjs
      .sendForm("service_rjnzoj6", "template_2lh97lv", form.current, {
        publicKey: "dLGaSfNHHKUpDuLYk",
      })

      .then(
        () => {
          setSubmissionSuccess(true);
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert("An error occurred while sending the message.");
        }
      );
    form.current.reset();

    setSelectedServices([]);
  };

  const handleServiceChange = (e) => {
    const value = e.target.value;
    setSelectedServices((prev) =>
      prev.includes(value)
        ? prev.filter((service) => service !== value)
        : [...prev, value]
    );
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const title = "Data Management Consulting | White Box Data";
  const DESCRIPTION =
    "Expert Data Management Consulting to help your business organize, secure, and leverage data for better decision-making. Improve data governance and drive success.";

  useEffect(() => {
    document.title = title;
  });

  return (
    <div className="data-strategy-container-main">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      >
        <link
          rel="canonical"
          href="https://www.whiteboxdata.com/business-intelligence-consulting"
        />
      </Helmet>
      <section className="bi_consulting_landing">
        <div className="bi_consulting_landing_text">
          <h1>Data Management Consulting</h1>

          <div className="marketing_analytics_landing_boxes">
            <div className="marketing_analytics_box">
              <h3>Integrated Data Systems</h3>
            </div>
            <div className="marketing_analytics_box">
              <h3>Accurate and Reliable Data</h3>
            </div>
            <div className="marketing_analytics_box">
              <h3>Improved Data Governance</h3>
            </div>
          </div>
          <p>
            Unlock the Full Potential of Your Data with Expert Data Management
            Consulting. White Box Data helps businesses organize, secure and
            manage their data to drive growth and actionable insights.
          </p>
        </div>
      </section>

      <section className="data-driven-insights-container">
        <div className="business_success_section">
          <div className="business_success_section1">
            <h2>Why Data Management is Critical to Business Success?</h2>
            <p>
              As businesses grow, so do their data challenges. Organizing,
              protecting and managing vast amounts of information is a complex
              task. Without a solid{" "}
              <span className="font-semibold">data strategy, </span>
              your business risks losing valuable insights, facing compliance
              issues or even security breaches. This is where our{" "}
              <span className="font-semibold">
                Data Management Consulting services
              </span>{" "}
              come in.
            </p>

            <p>
              <span className="font-semibold">White Box Data</span> specializes
              in helping organizations of all sizes structure their data, ensure
              security, and maximize the value of information. Our proven
              methods help you create a reliable, scalable, and secure data
              ecosystem tailored to your specific needs.
            </p>
          </div>
          <div className="business_success_section2">
            <img
              className="DataManagementConsultingImage"
              alt="Data Management Consulting"
              src={DataManagementConsultingImage}
            ></img>
          </div>
        </div>

        <h2>Our Data Management Consulting Services</h2>
        <h3 className="how_wo_work_h3">
          Tailored Solutions to Fit Your Unique Needs
        </h3>

        <p>
          We provide comprehensive data management consulting, covering every
          aspect of your data ecosystem. Our services include:
        </p>
        <section>
          <div className="data-driven-insights-section">
            <div className="data-driven-insights-box bi_consulting_img_height">
              <div className="bi-infra-img">
                <img
                  className="Data_Strategy"
                  src={Data_Strategy}
                  alt=""
                  loading="lazy"
                ></img>
              </div>
              <h3>Data Strategy & Planning</h3>
              <p>
                We create a tailored data strategy that aligns your business
                goals with the right data initiatives. Our experts develop a
                clear roadmap for seamless integration of technology and data
                processes, ensuring your data infrastructure supports growth,
                efficiency, and innovation.
              </p>
            </div>
            <div className="data-driven-insights-box">
              <div className="bi-infra-img">
                <img
                  className="data_architecture"
                  src={data_architecture}
                  alt=""
                  loading="lazy"
                ></img>
              </div>
              <h3>Data Integration and Architecture</h3>
              <p>
                We help you integrate data from various sources (internal,
                cloud, and third-party systems) into a unified system for
                seamless access and improved decision-making. Our scalable data
                solutions grow with your business, ensuring long-term
                performance and reliability.
              </p>
            </div>
            <div className="data-driven-insights-box">
              <div className="bi-infra-img">
                <img
                  className="Advanced_Analytics"
                  src={Advanced_Analytics}
                  alt=""
                  loading="lazy"
                ></img>
              </div>
              <h3>Data Analytics Enablement</h3>
              <p>
                Businesses that leverage advanced analytics and BI tools gain a
                significant competitive edge. Unlock the power of your data by
                enabling advanced analytics and BI tools. We enable you to not
                only monitor past performance but also predict future trends and
                identify growth opportunities before your competitors do.
              </p>
            </div>
          </div>
        </section>

        <section className="mt-[-2%]">
          <div className="data-driven-insights-section">
            <div className="data-driven-insights-box">
              <div className="bi-infra-img">
                <img
                  className="data_architecture"
                  src={cloud_data_migration}
                  alt=""
                  loading="lazy"
                ></img>
              </div>
              <h3>Data Migration & Cloud Services</h3>
              <p>
                Easily transition your data to modern cloud platforms with
                minimal disruption. Our team ensures a secure, efficient
                migration process that minimizes downtime and data loss. We
                handle everything from legacy system transfers to cloud
                optimization, ensuring a seamless transition.
              </p>
            </div>
            <div className="data-driven-insights-box">
              <div className="bi-infra-img">
                <img
                  className="data_architecture"
                  src={quality_framework}
                  alt=""
                  loading="lazy"
                ></img>
              </div>
              <h3>Data Governance & Compliance</h3>
              <p>
                Create a structured framework to manage your data assets
                effectively. We help you define policies, roles and processes to
                maintain data integrity and ensure compliance. Also stay
                compliant with data protection regulations such as GDPR and
                CCPA.
              </p>
            </div>
            <div className="data-driven-insights-box">
              <div className="bi-infra-img">
                <img
                  className="Data_Governance_framework"
                  src={data_governance}
                  alt=""
                  loading="lazy"
                ></img>
              </div>
              <h3>Data Quality Framework</h3>
              <p>
                Our team ensures that your data is clean, accurate and reliable.
                We create sustainable and scalable processes that help reduce
                errors and improve the quality of insights for effective
                data-driven decisions.
              </p>
            </div>
          </div>
        </section>

        <h2>Why Choose Us for Data Management Consulting?</h2>
        <h3 className="how_wo_work_h3">
          Your Trusted Partner for Data Success
        </h3>
        <p>
          With years of experience across various industries, our consultants
          understand the unique challenges businesses face when managing data.
          Our team of data experts helps you build a future-proof data
          environment that’s secure, scalable and compliant.
        </p>

        <ul>
          <li>
            <strong class="font-semibold">Expert Team: </strong>Our consultants
            are data management specialists with deep knowledge in data
            governance, security and architecture design.
          </li>
          <li>
            <strong class="font-semibold">Customized Solutions: </strong>We
            don't believe in one-size-fits-all. Every business is different, and
            we provide tailored solutions to meet your unique data needs.
          </li>
          <li>
            <strong class="font-semibold">Cutting-Edge Tools: </strong>
            We leverage the latest tools and technologies to provide efficient
            and secure data management solutions.
          </li>
          <li>
            <strong class="font-semibold">Proven Results: </strong>We’ve helped
            companies reduce data silos, improve data quality and enhance
            decision-making capabilities.
          </li>
        </ul>

        <div className="how_we_work_section data_management_process">
          <h3 className="how_wo_work_h3">How We Work with You</h3>
          <p>
            We believe in transparency and collaboration at every stage. Our
            consulting process ensures that you are involved in building a data
            strategy that meets your needs:
          </p>
          <div className="ai_consulting_how_we_work">
            <div className="ipad_ai_consulting_box">
              <div className="ai_consulting_box">
                <div className="ai_box_numbers">
                  <h5>1</h5>
                  <div>
                    <h3>Assessment</h3>
                    <p>
                      We evaluate your current data infrastructure, processes,
                      and business goals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="ai_consulting_box">
                <div className="ai_box_numbers">
                  <h5>2</h5>
                  <div>
                    <h3>Planning</h3>
                    <p>
                      Our team develops a customized roadmap tailored to your
                      specific challenges and objectives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="ipad_ai_consulting_box">
              <div className="ai_consulting_box">
                <div className="ai_box_numbers">
                  <h5>3</h5>
                  <div>
                    <h3>Implementation</h3>
                    <p>
                      We implement and integrate tools, systems, and governance
                      frameworks to ensure data security and performance.
                    </p>
                  </div>
                </div>
              </div>
              <div className="ai_consulting_box">
                <div className="ai_box_numbers">
                  <h5>4</h5>
                  <div>
                    <h3>Monitoring & Support</h3>
                    <p>
                      After implementation, we continuously monitor and provide
                      support, ensuring your data processes evolve as your
                      business grows.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="datastrategy-form ai_consulting_datastrategy_Form">
        <p className="ai_consulting_form_text">
          Ready to Transform Your Data? Contact Us for a Free Consultation
        </p>
        <p className="data_management_form_text">
          Take the next step in improving your business's data management.
          Schedule a free consultation with our expert data consultants, and
          let’s discuss how we can help you organize, secure, and leverage your
          data for long-term success.
        </p>
        {!submissionSuccess && (
          <form ref={form} onSubmit={sendEmail}>
            <div className="grid gap-6 mb-6 md:grid-cols-3">
              <div>
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  First Name*
                </label>
                <input
                  type="text"
                  id="first_name"
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_first_name"
                  required
                  pattern="[A-Za-z\s]+"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Last Name*
                </label>
                <input
                  type="text"
                  id="last_name"
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_last_name"
                  required
                  pattern="[A-Za-z\s]+"
                  value={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              <div className="mb-6">
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Phone*
                </label>
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  name="from_mobile"
                  required
                  defaultCountry="US"
                  value={phone}
                  onChange={setPhone}
                  className="flex text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full"
                />
              </div>

              <div className="mb-6">
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Email Address*
                </label>
                <input
                  type="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div>
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Company Name*
                </label>
                <input
                  type="text"
                  id="company"
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_company"
                  required
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                What are you looking for? (select all that applies)
              </label>
              <div className="checkbox-group">
                {ServiceData.map((service) => (
                  <div key={service.id}>
                    <input
                      type="checkbox"
                      id={`service-${service.id}`}
                      value={service.title}
                      checked={selectedServices.includes(service.title)}
                      onChange={handleServiceChange}
                      name="from_data_management_services"
                    />
                    <label
                      htmlFor={`service-${service.id}`}
                      className="ml-2 text-sm text-[#002c5f]"
                    >
                      {service.title}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            {/* <div className="flex items-start mb-6">
              <div className="flex items-center h-5">
                <input
                  id="remember"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                  required
                />
              </div>

              <label
                for="remember"
                className="ms-2 text-sm font-medium text-[#002c5f]"
              >
                By checking this box, you agree to our{" "}
                <a href="https://www.whiteboxdata.com/privacy-policy">
                  <u>Privacy Policy,</u>{" "}
                </a>{" "}
                and we may contact you about our products and services
              </label>
            </div> */}
            <button
              type="submit"
              className="text-white bg-[#0156b8] font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-3.5 text-center mt-[1.5rem]"
            >
              Submit
            </button>
          </form>
        )}
        {submissionSuccess && (
          <div className="message_ai_consulting">
            <div className="success-ai_consulting" id="success">
              Thank you! We have received your Information and one of our Expert
              Consultant will reach out within 24 hrs. We are eager to learn
              more and help you on your Data Journey.
            </div>
          </div>
        )}
      </div>

      <div className="marketing_services_container bi_cta_boxes">
        <div className="marketing_services_box">
          <a href="/blog/best-etl-tools">
            <div className="marketing_services_image">
              <img src={DMCETLTools} alt="" loading="lazy"></img>
            </div>
            <div className="bi_consulting_title">
              <p>Blog</p>
              <span className="">
                Best 5 ETL Tools - Overview, Advantages and Disadvantages
              </span>
            </div>
          </a>
        </div>
        <div className="marketing_services_box">
          <a href="/blog/what-is-data-management">
            <div className="marketing_services_image">
              <img src={DMCDataManagementBlog} alt="" loading="lazy"></img>
            </div>
            <div className="bi_consulting_title">
              <p>Blog</p>
              <span className="">What is Data Management?</span>
            </div>
          </a>
        </div>

        <div className="marketing_services_box">
          <a href="/blog/etl-vs-elt">
            <div className="marketing_services_image">
              <img src={DMCETLvsELT} alt="" loading="lazy"></img>
            </div>
            <div className="bi_consulting_title">
              <p>Blog</p>
              <span className="">
                ETL vs. ELT: A Comprehensive Guide for Modern Data Integration
              </span>
            </div>
          </a>
        </div>
      </div>

      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setIsModalOpen(true);
        }}
        className="consulting_fixed_btn"
      >
        Talk to a Data Expert
      </a>

      <DataStrategyModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}
