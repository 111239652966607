import React from "react";

import Layout from "../Layouts/Layout";
import FractionalBusinessIntelligenceConsultingBlog from "../Components/Blogs/FractionalBusinessIntelligenceConsultingBlog";

export default function FractionalBusinessIntelligenceConsultingBlogPage() {
  return (
    <Layout>
      <div>
        <FractionalBusinessIntelligenceConsultingBlog />
      </div>
    </Layout>
  );
}
