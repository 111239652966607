import React, { useEffect } from "react";
import DataWarehouseModernizationInner from "../../Assets/Img/DataWarehouseModernizationInner.png";

import { Helmet } from "react-helmet-async";

import "../../Styles/CustomerEngagementRevenue.css";

export default function StreamliningFinancialReportingBI() {
  const title =
    "Data Warehouse Modernization for a Healthcare Analytics Firm | White Box Data";
  const DESCRIPTION =
    "Learn how White Box Data modernized a healthcare analytics company’s data warehouse, achieving 60% faster queries and ensuring HIPAA-compliant, secure data storage.";

  useEffect(() => {
    document.title = title;
  });

  return (
    <div className="case-study-main-container">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      ></Helmet>

      <section className="fixed-blog-box">
        <div>
          <p>Get your insights now with White Box Data</p>
        </div>

        <div className="blog-getintouch">
          <a href="/contactus">Get in touch</a>
        </div>
      </section>

      <section className="case-study-content">
        <img
          src={DataWarehouseModernizationInner}
          alt=""
          className="case_studies-image"
        ></img>

        <h1>Data Warehouse Modernization for a Healthcare Analytics Firm</h1>

        <div className="industry-technology-block">
          <div className="industry-block">
            <h3>Industry</h3>
            <span className="industry-text">Healthcare, Analytics </span>
          </div>

          <div className="technology-block">
            <h3>Technology & Tools Stack</h3>
            <span className="industry-text">Amazon Web Services, Airflow</span>
          </div>
        </div>

        <h2>Customer</h2>

        <p className="case-study-content-p">
          <strong>Client: </strong>A US healthcare analytics company providing
          insights and analytics to hospitals, insurance companies and
          pharmaceutical firms.
        </p>
        <p className="case-study-content-p">
          <strong>Customer Overview: </strong>
          The client collects vast amounts of patient data, clinical trial
          information, and healthcare metrics, offering valuable insights to
          their customers. Their outdated data infrastructure struggled with
          integrating and processing the data efficiently, resulting in delayed
          analytics delivery and compliance issues.
        </p>

        <h2>Challenge</h2>
        <p className="case-study-content-p">
          The healthcare analytics company faced several data challenges:
        </p>

        <ul className="case-study-ul-list">
          <li>
            <strong>Legacy Data Warehouse: </strong>
            Their existing on-premise data warehouse could no longer support the
            growing volume of healthcare data, leading to slow query performance
            and long processing times.
          </li>
          <li>
            <strong>Data Integration Issues: </strong>
            The company needed to integrate structured clinical data, patient
            records, and unstructured data (such as physician notes) from
            multiple systems.
          </li>
          <li>
            <strong>Compliance and Security: </strong>
            With stringent regulations in healthcare (HIPAA), ensuring data
            security and privacy was a top priority.
          </li>
          <li>
            <strong>Delayed Analytics: </strong>
            Due to inefficient data processing, insights were often delivered
            too late for hospitals and insurers to take action.
          </li>
        </ul>

        <h2>Solution</h2>
        <p className="case-study-content-p">
          <strong>White Box Data</strong> delivered a comprehensive{" "}
          <strong>data warehouse modernization </strong>solution, focusing on
          scalability, performance, and compliance with healthcare data
          regulations.
        </p>
        <p className="case-study-content-p">
          Key components of the solution included:
        </p>

        <ol>
          <li>
            <strong>Migration to a Cloud-Based Data Warehouse:</strong>
            <p>
              We migrated the client’s data warehouse to{" "}
              <strong> Amazon Redshift, </strong>a fully-managed cloud data
              warehouse designed for scalability and fast querying. This
              provided the client with the ability to handle large datasets and
              run complex queries at high speeds.
            </p>
          </li>
          <li>
            <strong>ETL Automation with Apache Airflow:</strong>
            <p>
              We automated the data ingestion and transformation process using{" "}
              <strong> Apache Airflow, </strong>allowing the client to pull in
              data from various sources such as
              <strong> EHR (Electronic Health Records) </strong> systems,
              clinical trial databases, and wearable device data. The ETL
              process ensured that all data was cleaned and structured for
              analysis.
            </p>
          </li>
          <li>
            <strong>Data Compliance and Security:</strong>
            <p>
              We implemented advanced<strong> data encryption, </strong>
              role-based access controls, and<strong> audit trails </strong>to
              comply with<strong> HIPAA </strong>regulations. Additionally,
              <strong> AWS KMS </strong> (Key Management Service) was used to
              manage encryption keys and ensure secure data access.
            </p>
          </li>
          <li>
            <strong>Data Quality Monitoring:</strong>
            <p>
              Using<strong> AWS Glue, </strong>we set up data cataloging and
              quality monitoring tools to ensure that all incoming data was
              accurate, consistent, and ready for analysis.
            </p>
          </li>
          <li>
            <strong>Advanced Analytics Integration:</strong>
            <p>
              We integrated<strong> Amazon SageMaker </strong>to enable
              predictive analytics on patient outcomes, allowing healthcare
              providers to make data-driven decisions regarding treatment plans
              and resource allocation.
            </p>
          </li>
        </ol>

        <h2>Results</h2>
        <p className="case-study-content-p">
          The data warehouse modernization led to significant improvements:
        </p>

        <ul className="case-study-ul-list">
          <li>
            <strong>60% Faster Data Queries: </strong>
            Queries that once took hours were now completed in minutes, enabling
            faster analytics delivery to clients.
          </li>
          <li>
            <strong>Scalable Infrastructure: </strong>
            The cloud-based infrastructure could easily scale to handle
            increased data from new customers and larger healthcare providers
            without performance issues.
          </li>
          <li>
            <strong>Enhanced Data Security: </strong>
            The system met all <strong> HIPAA </strong>compliance requirements,
            ensuring patient data was secure and protected.
          </li>
          <li>
            <strong>Improved Data Integration: </strong>
            The new system seamlessly integrated structured and unstructured
            data, providing a complete view of patient outcomes, treatment
            efficacy, and healthcare costs.
          </li>
          <li>
            <strong>Predictive Analytics: </strong>
            With Amazon SageMaker, the client could deliver advanced insights to
            hospitals, enabling them to optimize resource allocation and improve
            patient outcomes.
          </li>
        </ul>
        <h2>Technology Stack</h2>

        <ul className="case-study-ul-list">
          <li>
            <strong>Data Warehouse: </strong>
            Amazon Redshift
          </li>
          <li>
            <strong>ETL Automation: </strong>
            Apache Airflow
          </li>
          <li>
            <strong>Data Cataloging & Quality: </strong>
            AWS Glue
          </li>
          <li>
            <strong>Data Security: </strong>
            AWS KMS (Key Management Service)
          </li>
          <li>
            <strong>Predictive Analytics: </strong>
            Amazon SageMaker
          </li>
          <li>
            <strong>Cloud Infrastructure: </strong>
            Amazon Web Services (AWS)
          </li>
        </ul>
      </section>

      <a href="/contactus">
        <button className="fixed-button">Request a Consultation</button>
      </a>
    </div>
  );
}
