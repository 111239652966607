import React from "react";
import InsightsForm from "./InsightsForm"; 
import "../Styles/DataStrategyModal.css";

const InsightsFormModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <InsightsForm />
      </div>
    </div>
  );
};

export default InsightsFormModal;
