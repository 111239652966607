import React from "react";

import Layout from "../Layouts/Layout";
import ETLvsELTBlog from "../Components/Blogs/ETLvsELTBlog";

export default function ETLvsELTBlogPage() {
  return (
    <Layout>
      <div>
        <ETLvsELTBlog />
      </div>
    </Layout>
  );
}
