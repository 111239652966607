import React, { useState, useEffect } from "react";
import meta from "../Assets/Img/meta.png";
import adobe from "../Assets/Img/adobe.png";
import splunk from "../Assets/Img/splunk.png";
import ck from "../Assets/Img/ck.png";
import intuit from "../Assets/Img/intuit.png";
import walmart_labs from "../Assets/Img/walmart labs.png";
import amazon from "../Assets/Img/amazon.png";
import { InlineWidget, PopupWidget } from "react-calendly";
import tick from "../Assets/icons/tick.svg";
import cross from "../Assets/icons/cross.svg";
import graytick from "../Assets/icons/gray-tick.svg";
import insightsimg from "../Assets/Img/insightsimg.png";
import save from "../Assets/icons/save.svg";
import timeline4 from "../Assets/Img/timeline.png";
import { Helmet } from "react-helmet-async";
import Ad_Hoc_Analysis_Tools from "../Assets/Img/Ad Hoc Analysis Tools.png";
import Reporting_and_visualization from "../Assets/Img/Reporting and visualization.png";
import customer from "../Assets/Img/customer.svg";
import { PopupButton } from "react-calendly";
import InsightsFormModal from "./InsightsFormModal";

import "../Styles/Insights.css";

function Insights() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const title = "Analytics as a Service | White Box Data";
  const DESCRIPTION =
    "Scale your analytics with Insights Pro. Your complete Analytics-as-a-Service solution offering automated reports, data pipelines and scalable dashboards.";

  useEffect(() => {
    document.title = title;
  });

  return (
    <div className="insights-main">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      >
        <link rel="canonical" href="https://www.whiteboxdata.com/insights" />
      </Helmet>

      <section className="landing-section">
        <div className="md:flex mt-[1rem]">
          <div className="box-70">
            <h1 className="wbd-title">Analytics as a Service</h1>
            <p className="mt-[1rem] text-center md:text-left">
              Unlock the power of data with Insights Pro—a fully managed
              Analytics-as-a-Service platform designed to drive actionable
              insights and business growth. Whether you're scaling your
              operations or optimizing decision-making, Insights Pro provides
              tailored dashboards and data solutions at a fixed monthly cost.
            </p>
            <p className="font-semibold md:mt-[2rem] mt-[1rem]">
              Key Features of Insights Pro:{" "}
            </p>
            <ul>
              <li>
                <span className="font-semibold"> US Based Consultants:</span>{" "}
                Work with only US based Analysts and Consultants
              </li>
              <li>
                <span className="font-semibold">Automated Reporting:</span>{" "}
                Scalable, real-time reports delivered straight to your team.
              </li>
              <li>
                <span className="font-semibold">No Hiring Needed:</span> Get a
                complete data team without expanding your headcount.
              </li>
              <li>
                <span className="font-semibold"> Real-Time Analytics:</span>{" "}
                Make decisions based on the most current data available.
              </li>
              <li>
                <span className="font-semibold">Predictable Pricing: </span>
                Transparent, flat-rate monthly pricing with no hidden fees.
              </li>
            </ul>
            <div className="pricing_insights_btn">
              <a href="#pricing" className="know-more-btn">
                View Pricing
              </a>
            </div>
          </div>
          <div className="insights-img">
            <img src={insightsimg} alt="Insights landing page image"></img>
          </div>
        </div>

        {/* <div className="bottom-container">
          <p className="bottom-text">
            "Data Experts with combined experience of over 25 years working at
            some of the best Tech Companies in Silicon Valley"
          </p>
        </div> */}
      </section>

      <section className="we_help_insights">
        <h2>How We Help You</h2>
        <p>
          Hiring and maintaining an in-house data team can be time-consuming and
          expensive. From recruitment to salaries, the cost of data engineers,
          analysts, and BI specialists can quickly add up. With Insights Pro,
          you get the same level of expertise without the overhead. Our
          Analytics-as-a-Service model provides a complete data solution,
          offering real-time insights, custom dashboards, and ongoing
          support—all at a fraction of the cost.
        </p>

        <div className="data-driven-insights-section">
          <div className="data-driven-insights-box bi_consulting_img_height">
            <h3>Access Analytics Expertise, On-Demand</h3>
            <span className="tableau_consulting_span">
              No need to recruit, train, or retain a full-time data team. We
              provide the skills and experience you need, when you need them.
            </span>
          </div>
          <div className="data-driven-insights-box">
            <h3>Cost-Effective Analytics Solutions</h3>
            <span className="tableau_consulting_span">
              Avoid the high costs of building an in-house analytics team. Our
              flexible pricing model scales with your business, ensuring optimal
              value.
            </span>
          </div>
          <div className="data-driven-insights-box">
            <h3>Focus on What Matters Most</h3>
            <span className="tableau_consulting_span">
              Let us handle the complexities of data management and analysis.
              You focus on growing your business and achieving your strategic
              goals.
            </span>
          </div>
        </div>
      </section>

      <section className="benefits_insights_pro">
        <h2>Benefits of Insights Pro</h2>
        <div className="data-driven-insights-section">
          <div className="data-driven-insights-box bi_consulting_img_height">
            <div className="bi-infra-img">
              <img
                className="Reporting_and_visualization"
                src={Reporting_and_visualization}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <h3>Automated Scalable Reporting</h3>
            <span className="tableau_consulting_span">
              Say goodbye to manual reporting. With Insights Pro, your data is
              processed and transformed into automated, real-time reports.
              Whether you need daily, weekly, or monthly updates, our platform
              scales with your needs, ensuring you have up-to-date analytics at
              your fingertips.
            </span>
          </div>
          <div className="data-driven-insights-box">
            <div className="bi-infra-img">
              <img
                className="hiring_insights"
                src={customer}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <h3>Build a Data Team without Hiring</h3>
            <span className="tableau_consulting_span">
              Skip the costly process of hiring and training a full data team.
              Insights Pro provides all the expertise you need—dashboards, data
              pipelines, and analytics—without adding to your payroll. Our
              experienced data engineers and analysts will handle the heavy
              lifting, allowing you to focus on strategy.
            </span>
          </div>
          <div className="data-driven-insights-box">
            <div className="bi-infra-img">
              <img
                className="md:w-[100px] w-[70px]"
                src={Ad_Hoc_Analysis_Tools}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <h3>ROI with Real-Time Analytics</h3>
            <span className="tableau_consulting_span">
              Access real-time insights that help you stay ahead of the curve.
              With real-time analytics, you can monitor key business metrics as
              they happen, allowing for more proactive and informed
              decision-making. Whether you're tracking sales, customer behavior,
              or operational performance, real-time data means you’re always on
              top of the latest trends.
            </span>
          </div>
        </div>
      </section>

      <section id="pricing">
        <div className="container-pricing">
          <h1 className="pricing-title">Pricing</h1>
          <div className="package-container">
            <div className="packages_pricing1">
              <h3 className="bg-white"></h3>
              <ul className="pricing_list">
                <p
                  className="monthly_pricing font-bold p-[10px] flex items-center"
                  style={{ borderColor: "#575d65", borderBottomWidth: "0.5px" }}
                >
                  Monthly Pricing
                </p>
                <li
                  className="bg-[#b1ceef]"
                  style={{ borderColor: "#575d65", borderBottomWidth: "0.5px" }}
                >
                  <p className="font-bold">Analytics Platform</p>
                </li>
                <li className="bg-[#E7F5F5]">
                  <p>Team</p>
                </li>
                <li className="bg-[#E7F5F5]">
                  <p>Licensing & Software Costs</p>
                </li>
                <li className="bg-[#E7F5F5]">
                  <p>Length of Implementation</p>
                </li>
                <li
                  className="bg-[#E7F5F5]"
                  style={{ borderColor: "#575d65", borderBottomWidth: "0.5px" }}
                >
                  <p>User Training</p>
                </li>

                <li
                  className="bg-[#b1ceef]"
                  style={{ borderColor: "#575d65", borderBottomWidth: "0.5px" }}
                >
                  <p className="font-bold">Features</p>
                </li>
                <li className="bg-[#E7F5F5]">
                  <p>Full Service Analytics Platform</p>
                </li>
                <li className="bg-[#E7F5F5]">
                  <p>Custom Dashboard Templates</p>
                </li>
                <li className="bg-[#E7F5F5]">
                  <p>Self-Serve Analytics</p>
                </li>

                <li className="bg-[#E7F5F5]">
                  <p>Data Pipeline Maintenance</p>
                </li>
                <li className="bg-[#E7F5F5]">
                  <p>Data Governance & Security</p>
                </li>
                <li className="bg-[#E7F5F5]">
                  <p>Plug & Play Data Connectors</p>
                </li>
                <li className="bg-[#E7F5F5]">
                  <p>Custom Data Connectors</p>
                </li>
                <li className="bg-[#E7F5F5]">
                  <p>Data Alerts & Monitoring</p>
                </li>
                <li className="bg-[#E7F5F5]">
                  <p>Access to Data Experts</p>
                </li>
              </ul>
            </div>
            <div className="packages_pricing2">
              <h3>Insights Pro</h3>
              <ul className="pricing_list">
                <p
                  className="monthly_pricing p-[10px]"
                  style={{ borderColor: "#575d65", borderBottomWidth: "0.5px" }}
                >
                  <span className="md:text-[11px] text-[6px]">starting at</span>{" "}
                  <br />
                  <span className=" md:text-[22px] text-[10px]">
                    $5000<span className="text-[11px]">/mo.</span>
                  </span>
                </p>
                <li
                  className="bg-[#b1ceef]"
                  style={{ borderColor: "#575d65", borderBottomWidth: "0.5px" }}
                ></li>
                <li className="bg-[#E7F5F5]">
                  <p className="font-[12px]">US based Analysts & Engineers</p>
                </li>
                <li className="bg-[#E7F5F5]">
                  <p>Included</p>
                </li>
                <li className="bg-[#E7F5F5]">
                  <p>Weeks</p>
                </li>
                <li
                  className="bg-[#E7F5F5]"
                  style={{ borderColor: "#575d65", borderBottomWidth: "0.5px" }}
                >
                  <p>Included</p>
                </li>

                <li
                  className="bg-[#b1ceef]"
                  style={{ borderColor: "#575d65", borderBottomWidth: "0.5px" }}
                ></li>
                <li className="bg-[#E7F5F5]">
                  <img
                    className="tick-img"
                    src={tick}
                    alt="Green tick image"
                    loading="lazy"
                  ></img>
                </li>
                <li className="bg-[#E7F5F5]">
                  <img
                    className="tick-img"
                    src={tick}
                    alt="Green tick image"
                    loading="lazy"
                  ></img>
                </li>
                <li className="bg-[#E7F5F5]">
                  <img
                    className="tick-img"
                    src={tick}
                    alt="Green tick image"
                    loading="lazy"
                  ></img>
                </li>
                <li className="bg-[#E7F5F5]">
                  <img
                    className="tick-img"
                    src={tick}
                    alt="Green tick image"
                    loading="lazy"
                  ></img>
                </li>
                <li className="bg-[#E7F5F5]">
                  <img
                    className="tick-img"
                    src={tick}
                    alt="Green tick image"
                    loading="lazy"
                  ></img>
                </li>
                <li className="bg-[#E7F5F5]">
                  <img
                    className="tick-img"
                    src={tick}
                    alt="Green tick image"
                    loading="lazy"
                  ></img>
                </li>
                <li className="bg-[#E7F5F5]">
                  <img
                    className="tick-img"
                    src={tick}
                    alt="Green tick image"
                    loading="lazy"
                  ></img>
                </li>
                <li className="bg-[#E7F5F5]">
                  <img
                    className="tick-img"
                    src={tick}
                    alt="Green tick image"
                    loading="lazy"
                  ></img>
                </li>
                <li className="bg-[#E7F5F5]">
                  <img
                    className="tick-img"
                    src={tick}
                    alt="Green tick image"
                    loading="lazy"
                  ></img>
                </li>
              </ul>
            </div>
            <div className="packages_pricing3">
              <h3>Build it Yourself</h3>
              <ul className="pricing_list">
                <p
                  className="monthly_pricing p-[10px]"
                  style={{ borderColor: "#575d65", borderBottomWidth: "0.5px" }}
                >
                  <br />
                  <span className=" md:text-[22px] text-[10px]">
                    &gt;$20,000+
                  </span>
                </p>
                <li
                  className="bg-[#b1ceef]"
                  style={{ borderColor: "#575d65", borderBottomWidth: "0.5px" }}
                ></li>
                <li className="bg-[#E7F5F5]">
                  <p>Multiple Hires, salaries & benefits</p>
                </li>
                <li className="bg-[#E7F5F5]">
                  <p>Manage Vendor Relationships & Cost</p>
                </li>
                <li className="bg-[#E7F5F5]">
                  <p>Upto a year</p>
                </li>
                <li
                  className="bg-[#E7F5F5]"
                  style={{ borderColor: "#575d65", borderBottomWidth: "0.5px" }}
                >
                  <p>Additional Training Budget</p>
                </li>

                <li
                  className="bg-[#b1ceef]"
                  style={{ borderColor: "#575d65", borderBottomWidth: "0.5px" }}
                ></li>
                <li className="bg-[#E7F5F5]">
                  <img
                    className="tick-img"
                    src={cross}
                    alt="Red cross image"
                    loading="lazy"
                  ></img>
                </li>
                <li className="bg-[#E7F5F5]">
                  <img
                    className="tick-img"
                    src={cross}
                    alt="Red cross image"
                    loading="lazy"
                  ></img>
                </li>
                <li className="bg-[#E7F5F5]">
                  <img
                    className="tick-img"
                    src={cross}
                    alt="Red cross image"
                    loading="lazy"
                  ></img>
                </li>
                <li className="bg-[#E7F5F5]">
                  <img
                    className="tick-img"
                    src={cross}
                    alt="Red cross image"
                    loading="lazy"
                  ></img>
                </li>
                <li className="bg-[#E7F5F5]">
                  <img
                    className="tick-img"
                    src={cross}
                    alt="Red cross image"
                    loading="lazy"
                  ></img>
                </li>
                <li className="bg-[#E7F5F5]">
                  <img
                    className="tick-img"
                    src={cross}
                    alt="Red cross image"
                    loading="lazy"
                  ></img>
                </li>
                <li className="bg-[#E7F5F5]">
                  <img
                    className="tick-img"
                    src={cross}
                    alt="Red cross image"
                    loading="lazy"
                  ></img>
                </li>
                <li className="bg-[#E7F5F5]">
                  <img
                    className="tick-img"
                    src={cross}
                    alt="Red cross image"
                    loading="lazy"
                  ></img>
                </li>
                <li className="bg-[#E7F5F5]">
                  <img
                    className="tick-img"
                    src={cross}
                    alt="Red cross image"
                    loading="lazy"
                  ></img>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="insights_pro_section">
        <h2>Get Started in 3 Simple Steps</h2>
        <div className="what-we-do-section">
          <div className="what-we-do-box">
            <h5>1</h5>
            <h3>Schedule a Free Consultation</h3>
          </div>
          <div className="what-we-do-box">
            <h5>2</h5>
            <h3> In-depth Discovery Session</h3>
          </div>
          <div className="what-we-do-box">
            <h5>3</h5>
            <h3>Implementation & Support</h3>
          </div>
        </div>
        <div className="insights_pro_btn">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setIsModalOpen(true);
            }}
            className="talk-to-expert-btn"
          >
            Talk to a Data Expert
          </a>
        </div>
      </div>

      <section className="scroll_insights">
        <p>
          Our Analysts and Engineers are highly qualified data experts with
          years of experience.
        </p>
        <div className="scroll">
          <ul>
            <li>
              <img
                className="scroll-img1"
                src={meta}
                alt="Meta logo"
                loading="lazy"
              />
            </li>
            <li>
              <img
                className="scroll-img2"
                src={adobe}
                alt="Adobe logo"
                loading="lazy"
              />
            </li>
            <li>
              <img
                className="scroll-img3"
                src={amazon}
                alt="Amazon logo"
                loading="lazy"
              />
            </li>
            <li>
              <img
                className="scroll-img4"
                src={splunk}
                alt="Splunk logo"
                loading="lazy"
              />
            </li>
            <li>
              <img
                className="scroll-img5"
                src={ck}
                alt="Ck logo"
                loading="lazy"
              />
            </li>
            <li>
              <img
                className="scroll-img6"
                src={intuit}
                alt="Intuit logo"
                loading="lazy"
              />
            </li>
            <li>
              <img
                className="scroll-img7"
                src={walmart_labs}
                alt="Walmart labs logo"
                loading="lazy"
              />
            </li>

            <li>
              <img
                className="scroll-img1"
                src={meta}
                alt="Meta logo"
                loading="lazy"
              />
            </li>
            <li>
              <img
                className="scroll-img2"
                src={adobe}
                alt="Adobe logo"
                loading="lazy"
              />
            </li>
            <li>
              <img
                className="scroll-img3"
                src={amazon}
                alt="Amazon logo"
                loading="lazy"
              />
            </li>
            <li>
              <img
                className="scroll-img4"
                src={splunk}
                alt="Splunk logo"
                loading="lazy"
              />
            </li>
            <li>
              <img
                className="scroll-img5"
                src={ck}
                alt="Ck logo"
                loading="lazy"
              />
            </li>
            <li>
              <img
                className="scroll-img6"
                src={intuit}
                alt="Intuit logo"
                loading="lazy"
              />
            </li>
            <li>
              <img
                className="scroll-img7"
                src={walmart_labs}
                alt="Walmart labs logo"
                loading="lazy"
              />
            </li>
          </ul>
        </div>
      </section>

      {/* <section className="timeline-main">
        <section class="timeline">
          <h1>Get Started in 3 Simple Steps</h1>
          <div class="container-timeline">
            <div class="timeline-item">
              <div class="timeline-img">1</div>

              <div
                
                class="timeline-content"
              >
                <h2 className="timelinebox">Schedule a Free Consultation</h2>

                <p className="ml-[12%] mr-[15%]">
                  Book a free consultation with our lead data consultants to
                  explore how we can help you
                </p>
              </div>
            </div>

            <div class="timeline-item">
              <div class="timeline-img">2</div>

              <div
                
                class="timeline-content"
              >
                <h2 className="timelinebox">Follow Up Discovery Session</h2>

                <p>
                  We will meet with you to understand your goals, identify &
                  define as-is vs to-be tech stack, and recommend variety of
                  solutions that could help you make data-driven decisions.
                </p>
              </div>
            </div>

            <div class="timeline-item">
              <div class="timeline-img">3</div>

              <div
                
                class="timeline-content"
              >
                <h2 className="timelinebox">Implementation & Support</h2>
                <p>
                  We will start the process of gathering the data from various
                  sources.Your team can start requesting dashboards & get
                  insights in as little as 1 weeks’ time
                </p>
              </div>
            </div>

            <div class="timeline-item">
              <div class="timeline-img">4</div>

              <div
                
                class="timeline-content box4-height h-30 "
              >
                <h2 className="timelinebox">Insights at Your Finger Tips</h2>

                <img
                  className="laptop-img"
                  src={timeline4}
                  alt="Insights stats"
                  loading="lazy"
                ></img>
              </div>
            </div>
          </div>
        </section>
      </section> */}

      {/* <section className="calendly"> */}
      {/* <h1>Schedule a Call with Our Experts</h1>
        <div className="inline-calendly">
          <InlineWidget
            url="https://calendly.com/consulting-sjqf/30min?hide_gdpr_banner=1"
            className="calendly-inline"
            styles={{
              height: "80vh",
              minWidth: "320px",
              maxWidth: "100%",
            }}
          />
        </div> */}
      <PopupWidget
        url="https://calendly.com/consulting-sjqf/30min?hide_gdpr_banner=1"
        rootElement={document.getElementById("root")}
        text="Request a Demo"
        className="insights_demo_btn"
      />

      <InsightsFormModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}

export default Insights;
