import React from "react";

import Layout from "../Layouts/Layout";
import GrowthMarketing from "../Components/GrowthMarketing";

export default function GrowthMarketingPage() {
  return (
    <Layout>
      <div>
        <GrowthMarketing />
      </div>
    </Layout>
  );
}
