import React from "react";

import MarketingAnalyticsTrendsIn2024Blog from "../Components/Blogs/MarketingAnalyticsTrendsIn2024Blog";
import Layout from "../Layouts/Layout";

export default function MarketingAnalyticsTrendsIn2024BlogPage() {
  return (
    <Layout>
      <div>
        <MarketingAnalyticsTrendsIn2024Blog />
      </div>
    </Layout>
  );
}
