import React from "react";
import DataStrategyForm from "./DataStrategyForm"; 
import "../Styles/DataStrategyModal.css";

const DataStrategyModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>
          &times;
        </button>
        <DataStrategyForm />
      </div>
    </div>
  );
};

export default DataStrategyModal;
