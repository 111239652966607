import React, { useEffect, useRef, useState } from "react";
import growth_marketing from "../Assets/Img/growth_marketing.png";
import arrow_up from "../Assets/icons/arrow_up.svg";
import ga4 from "../Assets/icons/ga4.svg";
import media_marketing from "../Assets/icons/Social Media Marketing_growthmarketing.svg";
import paid_marketing from "../Assets/icons/Paid Marketing_Growthmarketing.svg";
import rate_optimization from "../Assets/icons/Conversion Rate Optimization_growthmarketing.svg";
import seo_sem from "../Assets/icons/SEO, SEM & Brand_growthmarketing.svg";
import { Helmet } from "react-helmet-async";
import DataStrategyModal from "./DataStrategyModal";

import "../Styles/GrowthMarketing.css";

export default function GrowthMarketing() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const wrapperRef = useRef(null);
  const carouselRef = useRef(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const carousel = carouselRef.current;

    if (!wrapper || !carousel) return;

    const firstCardWidth = carousel.querySelector(".card").offsetWidth;
    const arrowBtns = wrapper.querySelectorAll("i");
    const carouselChildrens = [...carousel.children];

    let isDragging = false,
      isAutoPlay = true,
      startX,
      startScrollLeft,
      timeoutId;

    // Get the number of cards that can fit in the carousel at once
    let cardPerView = Math.round(carousel.offsetWidth / firstCardWidth);

    // Insert copies of the last few cards to beginning of carousel for infinite scrolling
    carouselChildrens
      .slice(-cardPerView)
      .reverse()
      .forEach((card) => {
        carousel.insertAdjacentHTML("afterbegin", card.outerHTML);
      });

    // Insert copies of the first few cards to end of carousel for infinite scrolling
    carouselChildrens.slice(0, cardPerView).forEach((card) => {
      carousel.insertAdjacentHTML("beforeend", card.outerHTML);
    });

    // Scroll the carousel at appropriate position to hide first few duplicate cards on Firefox
    carousel.classList.add("no-transition");
    carousel.scrollLeft = carousel.offsetWidth;
    carousel.classList.remove("no-transition");

    // Add event listeners for the arrow buttons to scroll the carousel left and right
    arrowBtns.forEach((btn) => {
      btn.addEventListener("click", () => {
        carousel.scrollLeft +=
          btn.id === "left" ? -firstCardWidth : firstCardWidth;
      });
    });

    const dragStart = (e) => {
      isDragging = true;
      carousel.classList.add("dragging");
      // Records the initial cursor and scroll position of the carousel
      startX = e.pageX;
      startScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e) => {
      if (!isDragging) return; // if isDragging is false return from here
      // Updates the scroll position of the carousel based on the cursor movement
      carousel.scrollLeft = startScrollLeft - (e.pageX - startX);
    };

    const dragStop = () => {
      isDragging = false;
      carousel.classList.remove("dragging");
    };

    const infiniteScroll = () => {
      // If the carousel is at the beginning, scroll to the end
      if (carousel.scrollLeft === 0) {
        carousel.classList.add("no-transition");
        carousel.scrollLeft = carousel.scrollWidth - 2 * carousel.offsetWidth;
        carousel.classList.remove("no-transition");
      }
      // If the carousel is at the end, scroll to the beginning
      else if (
        Math.ceil(carousel.scrollLeft) ===
        carousel.scrollWidth - carousel.offsetWidth
      ) {
        carousel.classList.add("no-transition");
        carousel.scrollLeft = carousel.offsetWidth;
        carousel.classList.remove("no-transition");
      }

      // Clear existing timeout & start autoplay if mouse is not hovering over carousel
      clearTimeout(timeoutId);
      if (!wrapper.matches(":hover")) autoPlay();
    };

    const autoPlay = () => {
      if (window.innerWidth < 800 || !isAutoPlay) return; // Return if window is smaller than 800 or isAutoPlay is false
      // Autoplay the carousel after every 2500 ms
      timeoutId = setTimeout(() => {
        carousel.scrollLeft += firstCardWidth;
      }, 2500);
    };
    autoPlay();

    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("mousemove", dragging);
    document.addEventListener("mouseup", dragStop);
    carousel.addEventListener("scroll", infiniteScroll);
    wrapper.addEventListener("mouseenter", () => clearTimeout(timeoutId));
    wrapper.addEventListener("mouseleave", autoPlay);

    return () => {
      // Clean up event listeners on component unmount
      carousel.removeEventListener("mousedown", dragStart);
      carousel.removeEventListener("mousemove", dragging);
      document.removeEventListener("mouseup", dragStop);
      carousel.removeEventListener("scroll", infiniteScroll);
      wrapper.removeEventListener("mouseenter", () => clearTimeout(timeoutId));
      wrapper.removeEventListener("mouseleave", autoPlay);
    };
  }, []);

  const title = "Growth & Marketing Technology | White Box Data";
  const DESCRIPTION =
    "Growth marketing services | Marketing technology solutions | Customer acquisition | ROI optimization | Marketing automation | Personalization | White Box Data: Your Growth Partner";

  useEffect(() => {
    document.title = title;
  });

  return (
    <div className="growthmarketing-container">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      >
        <title>{title}</title>
        <link
          rel="canonical"
          href="https://www.whiteboxdata.com/services/growth-and-marketing-technology"
        />
      </Helmet>

      <section className="growth-home">
        <div className="growth-home-content">
          <div className="growth_landing">
            <h1>
              Growth & Marketing <br /> Technology
            </h1>
            <p>
              With so many marketing initiatives competing for your customer's
              attention; each creative you make, each campaign you launch and
              each dollar you spend, must be backed by a solid technical
              foundation and strategy. We work at the intersection of growth,
              marketing & analytics to achieve maximum ROI, user growth &
              increase your brand visibility.
            </p>
            <a href="/contactus" className="growth-home-btn">
              Get In Touch
            </a>
          </div>
          <div>
            <div className="wrapper" ref={wrapperRef}>
              <i id="left" className="fa-solid fa-angle-left"></i>
              <ul className="carousel" ref={carouselRef}>
                <li className="card">
                  <div className="arrow_up_growth">
                    <h1>10x</h1>
                  </div>
                  <h2>
                    Growth in Organic <br /> & Direct Traffic
                  </h2>
                  <p>SEO</p>
                </li>
                <li className="card">
                  <div className="arrow_up_growth">
                    <h1>23% </h1>
                    <img
                      className="arrow_up"
                      src={arrow_up}
                      alt="Arrow up"
                      loading="lazy"
                    ></img>
                  </div>
                  <h2>
                    Brand <br /> Awareness
                  </h2>
                  <p>SEM & Brand</p>
                </li>
                <li className="card">
                  <div className="arrow_up_growth">
                    <h1>63% </h1>
                    <img
                      className="arrow_up"
                      src={arrow_up}
                      alt="Arrow up"
                      loading="lazy"
                    ></img>
                  </div>
                  <h2>
                    Lead <br /> Generation
                  </h2>
                  <p>Paid Marketing</p>
                </li>
                <li className="card">
                  <div className="arrow_up_growth">
                    <h1>34% </h1>

                    <img
                      className="arrow_up"
                      src={arrow_up}
                      alt="Arrow up"
                      loading="lazy"
                    ></img>
                  </div>
                  <h2 className="mb-[1.3rem]">Orders</h2>
                  <p>CRO</p>
                </li>
                <li className="card">
                  <div className="arrow_up_growth">
                    <h1>19% </h1>
                    <img
                      className="arrow_up"
                      src={arrow_up}
                      alt="Arrow up"
                      loading="lazy"
                    ></img>
                  </div>
                  <h2>
                    Customer <br /> Retention
                  </h2>
                  <p>CRO</p>
                </li>
              </ul>
              <i id="right" className="fa-solid fa-angle-right"></i>
            </div>
          </div>
        </div>

        <div className="growth-image">
          <img src={growth_marketing} alt="Growth Marketing image"></img>
        </div>
      </section>

      <h1 className="icon-box-section-h1">
        Our Paid & Organic Marketing Services
      </h1>

      <section className="main_services_section">
        <div className="data-driven-insights-section services_boxes_section_padding">
          <div className="data-driven-insights-box bi_consulting_img_height">
            <div className="bi-infra-img">
              <img
                className="paid_marketing"
                src={paid_marketing}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <h3>Paid Marketing</h3>
            <p>
              Supercharge your customer acquisition with our data-driven paid
              marketing strategies. We craft targeted campaigns across channels
              like search, social, and display, optimizing for maximum ROI.
              Harness the power of audience segmentation, A/B testing, and
              real-time analytics to reach the right people with the right
              message at the right time. Whether you're looking to drive website
              traffic, generate leads, or boost sales, our experts will tailor a
              paid marketing strategy that delivers results.
            </p>
          </div>
          <div className="data-driven-insights-box">
            <div className="bi-infra-img">
              <img
                className="media_marketing"
                src={media_marketing}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <h3>Social Media Marketing</h3>
            <p>
              Build a thriving community and amplify your brand's voice with our
              social media marketing expertise. We develop engaging content,
              manage social channels, and execute targeted advertising campaigns
              to expand your reach and drive engagement. Leverage the power of
              social listening to understand your audience, identify trends, and
              craft data-informed strategies that resonate. From increasing
              brand awareness to driving website traffic and fostering customer
              loyalty, we help you achieve your social media goals.
            </p>
          </div>
          <div className="data-driven-insights-box">
            <div className="bi-infra-img">
              <img className="ga4" src={ga4} alt="" loading="lazy"></img>
            </div>
            <h3>GA4 (Audit & Implementation)</h3>
            <p>
              Gain a comprehensive understanding of your website's performance
              with Google Analytics 4 (GA4). Our experts conduct thorough audits
              to identify gaps, ensure accurate tracking, and optimize your GA4
              implementation. Harness the power of advanced analytics, machine
              learning, and cross-platform tracking to unlock valuable insights
              into user behavior, conversions, and campaign effectiveness. Make
              data-driven decisions that enhance your marketing ROI and drive
              business growth with White Box Data's GA4 expertise.
            </p>
          </div>
        </div>
      </section>

      <section className="mt-[-2%]">
        <div className="data-driven-insights-section services_boxes_section_padding">
          <div className="data-driven-insights-box">
            <div className="bi-infra-img">
              <img
                className="rate_optimization"
                src={rate_optimization}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <h3>Conversion Rate Optimization</h3>
            <p>
              Turn website visitors into paying customers with our conversion
              rate optimization (CRO) services. We analyze user behavior,
              conduct A/B testing, and implement data-backed strategies to
              improve your website's performance. Optimize landing pages,
              streamline checkout processes, and personalize the user experience
              to maximize conversions and boost revenue. Our data-driven
              approach ensures that every change we make is focused on
              increasing your bottom line.
            </p>
          </div>
          <div className="data-driven-insights-box">
            <div className="bi-infra-img">
              <img
                className="seo_sem"
                src={seo_sem}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <h3>SEO, SEM & Brand</h3>
            <p>
              Achieve top rankings in search results and build a powerful brand
              presence online. Our SEO and SEM experts develop customized
              strategies to improve your website's visibility, drive organic
              traffic, and maximize conversions. Conduct thorough keyword
              research, optimize on-page and off-page elements, and build
              high-quality backlinks to boost your search rankings. With our SEM
              expertise, we'll craft targeted campaigns that drive qualified
              traffic and deliver measurable results.
            </p>
          </div>
          <div className="data-driven-insights-box flex items-center bg-[#b1ceef]">
            <div className="services_quote_text ">
              <h2>Looking for a custom solution?</h2>

              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
                className="talk-to-expert-btn services_button"
              >
                Request a Consultation
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="icon-box-section">
        <div className="icon-box-row">
          <div className="growth_box_image">
            <img
              className="paid_marketing"
              src={paid_marketing}
              alt="Paid Marketing logo"
              loading="lazy"
            ></img>
          </div>
          <div className="box">
            <h2>Paid Marketing</h2>
            <p className="desktop_box_content">
              Supercharge your customer acquisition with our data-driven paid
              marketing strategies. We craft targeted campaigns across channels
              like search, social, and display, optimizing for maximum ROI.
              Harness the power of audience segmentation, A/B testing, and
              real-time analytics to reach the right people with the right
              message at the right time. Whether you're looking to drive website
              traffic, generate leads, or boost sales, our experts will tailor a
              paid marketing strategy that delivers results.
            </p>
            <p className="mobile_content">
              Supercharge your customer acquisition with our data-driven paid
              marketing strategies. We craft targeted campaigns across channels
              like search, social, and display, optimizing for maximum ROI.
            </p>
          </div>
        </div>

        <div className="icon-box-row mobile_reverse">
          <div className="growth_box_image">
            <img
              className="media_marketing"
              src={media_marketing}
              alt="Social Media Marketing logo"
              loading="lazy"
            ></img>
          </div>
          <div className="box">
            <h2>Social Media Marketing</h2>
            <p className="desktop_box_content">
              Build a thriving community and amplify your brand's voice with our
              social media marketing expertise. We develop engaging content,
              manage social channels, and execute targeted advertising campaigns
              to expand your reach and drive engagement. Leverage the power of
              social listening to understand your audience, identify trends, and
              craft data-informed strategies that resonate. From increasing
              brand awareness to driving website traffic and fostering customer
              loyalty, we help you achieve your social media goals.
            </p>
            <p className="mobile_content">
              Build a thriving community and amplify your brand's voice with our
              social media marketing expertise. We develop engaging content,
              manage social channels, and execute targeted advertising campaigns
              to expand your reach and drive engagement.
            </p>
          </div>
        </div>

        <div className="icon-box-row">
          <div className="growth_box_image">
            <img className="ga4" src={ga4} alt="GA4 logo" loading="lazy"></img>
          </div>
          <div className="box">
            <h2>GA4 (Audit & Implementation)</h2>
            <p className="desktop_box_content">
              Gain a comprehensive understanding of your website's performance
              with Google Analytics 4 (GA4). Our experts conduct thorough audits
              to identify gaps, ensure accurate tracking, and optimize your GA4
              implementation. Harness the power of advanced analytics, machine
              learning, and cross-platform tracking to unlock valuable insights
              into user behavior, conversions, and campaign effectiveness. Make
              data-driven decisions that enhance your marketing ROI and drive
              business growth with White Box Data's GA4 expertise.
            </p>
            <p className="mobile_content">
              Gain a comprehensive understanding of your website's performance
              with Google Analytics 4 (GA4). Our experts conduct thorough audits
              to identify gaps, ensure accurate tracking, and optimize your GA4
              implementation.
            </p>
          </div>
        </div>

        <div className="icon-box-row mobile_reverse">
          <div className="growth_box_image">
            <img
              className="rate_optimization"
              src={rate_optimization}
              alt="Conversion Rate Optimization logo"
              loading="lazy"
            ></img>
          </div>
          <div className="box">
            <h2>Conversion Rate Optimization</h2>
            <p className="desktop_box_content">
              Turn website visitors into paying customers with our conversion
              rate optimization (CRO) services. We analyze user behavior,
              conduct A/B testing, and implement data-backed strategies to
              improve your website's performance. Optimize landing pages,
              streamline checkout processes, and personalize the user experience
              to maximize conversions and boost revenue. Our data-driven
              approach ensures that every change we make is focused on
              increasing your bottom line.
            </p>
            <p className="mobile_content">
              Turn website visitors into paying customers with our conversion
              rate optimization (CRO) services. We analyze user behavior,
              conduct A/B testing, and implement data-backed strategies to
              improve your website's performance.
            </p>
          </div>
        </div>

        <div className="icon-box-row">
          <div className="growth_box_image">
            <img
              className="seo_sem"
              src={seo_sem}
              alt="SEO,SEM & Brand logo"
              loading="lazy"
            ></img>
          </div>
          <div className="box">
            <h2>SEO, SEM & Brand</h2>
            <p className="desktop_box_content">
              Achieve top rankings in search results and build a powerful brand
              presence online. Our SEO and SEM experts develop customized
              strategies to improve your website's visibility, drive organic
              traffic, and maximize conversions. Conduct thorough keyword
              research, optimize on-page and off-page elements, and build
              high-quality backlinks to boost your search rankings. With our SEM
              expertise, we'll craft targeted campaigns that drive qualified
              traffic and deliver measurable results.
            </p>
            <p className="mobile_content">
              Achieve top rankings in search results and build a powerful brand
              presence online. Our SEO and SEM experts develop customized
              strategies to improve your website's visibility, drive organic
              traffic, and maximize conversions.
            </p>
          </div>
        </div>
      </section> */}

      <a href="/contactus">
        <button className="fixed-button">Request a Consultation</button>
      </a>

      <DataStrategyModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}
