import React from "react";

import Layout from "../Layouts/Layout";
import AiBiBlog from "../Components/Blogs/AiBiBlog";

export default function AiBiBlogPage() {
  return (
    <Layout>
      <div>
        <AiBiBlog />
      </div>
    </Layout>
  );
}
