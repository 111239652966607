import React from "react";

import MarketingAnalyticsConsulting from "../Components/MarketingAnalyticsConsulting";
import Layout from "../Layouts/Layout";

export default function MarketingAnalyticsConsultingPage() {
  return (
    <Layout>
      <div>
        <MarketingAnalyticsConsulting />
      </div>
    </Layout>
  );
}
