import React, { useEffect } from "react";
import OptimizingMarketingPerformanceInner from "../../Assets/Img/OptimizingMarketingPerformanceInner.webp";

import { Helmet } from "react-helmet-async";

import "../../Styles/CustomerEngagementRevenue.css";

export default function OptimizingMarketingPerformance() {
  const title =
    "Optimizing Marketing Performance for a B2B SaaS Company with Advanced Analytics | White Box Data";
  const DESCRIPTION =
    "Discover how White Box Data helped a B2B SaaS company improve conversion rates by 25% using advanced marketing analytics and customer segmentation.";

  useEffect(() => {
    document.title = title;
  });

  return (
    <div className="case-study-main-container">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      ></Helmet>

      <section className="fixed-blog-box">
        <div>
          <p>Get your insights now with White Box Data</p>
        </div>

        <div className="blog-getintouch">
          <a href="/contactus">Get in touch</a>
        </div>
      </section>

      <section className="case-study-content">
        <img
          src={OptimizingMarketingPerformanceInner}
          alt=""
          className="case_studies-image"
        ></img>

        <h1>
          Optimizing Marketing Performance for a B2B SaaS Company with Advanced
          Analytics
        </h1>

        <div className="industry-technology-block">
          <div className="industry-block">
            <h3>Industry</h3>
            <span className="industry-text">SaaS, B2B </span>
          </div>

          <div className="technology-block">
            <h3>Technology & Tools Stack</h3>
            <span className="industry-text">Python, Power BI</span>
          </div>
        </div>

        <h2>Customer</h2>

        <p className="case-study-content-p">
          <strong>Client: </strong>A B2B US SaaS company offering project
          management software to enterprises.
        </p>
        <p className="case-study-content-p">
          <strong>Customer Overview: </strong>
          The client provides project management solutions to large enterprises
          but was struggling with marketing performance. Despite high traffic,
          their conversion rates remained low, and they lacked the necessary
          insights to optimize campaigns effectively.
        </p>

        <h2>Challenge</h2>
        <p className="case-study-content-p">
          The client’s key challenges included:
        </p>

        <ul className="case-study-ul-list">
          <li>
            <strong>Low Conversion Rates: </strong>
            Despite generating significant web traffic, conversion rates from
            leads to customers were low, leading to inefficient marketing spend.
          </li>
          <li>
            <strong>Unoptimized Campaigns: </strong>
            The company lacked detailed insights into campaign performance and
            customer behavior, resulting in underperforming marketing
            strategies.
          </li>
          <li>
            <strong>Customer Segmentation Issues: </strong>
            They needed a more effective way to segment B2B clients to better
            target marketing efforts, but their existing data was incomplete and
            inconsistent.
          </li>
        </ul>
        <p className="case-study-content-p">
          The client needed advanced marketing analytics tools and customer
          segmentation strategies to improve conversion rates and optimize
          campaigns.
        </p>

        <h2>Solution</h2>
        <p className="case-study-content-p">
          <strong>White Box Data</strong> implemented an advanced{" "}
          <strong>marketing analytics solution </strong>that improved campaign
          performance and helped the client better target high-value B2B
          customers.
        </p>
        <p className="case-study-content-p">
          Key components of the solution included:
        </p>

        <ol>
          <li>
            <strong>Customer Segmentation Using K-Means Clustering:</strong>
            <p>
              We used <strong> K-Means Clustering </strong>to segment the
              client’s B2B customer base based on company size, industry, and
              engagement level. This enabled the client to tailor campaigns to
              different segments, increasing relevance and engagement.
            </p>
          </li>
          <li>
            <strong>Campaign Performance Analytics:</strong>
            <p>
              Using <strong> Google Analytics 360 </strong>and
              <strong> Power BI, </strong>we set up detailed
              dashboards that provided insights into campaign performance,
              customer journeys, and behavior patterns. This helped the client
              identify underperforming campaigns and optimize content and
              targeting.
            </p>
          </li>
          <li>
            <strong>A/B Testing Framework:</strong>
            <p>
              We implemented an A/B testing framework to experiment with
              different landing page designs, CTAs, and email campaigns. This
              allowed the client to continuously improve marketing strategies
              based on real-time data.
            </p>
          </li>
          <li>
            <strong>Predictive Lead Scoring:</strong>
            <p>
              Using<strong> machine learning algorithms, </strong>we developed a
              predictive lead scoring system that identified high-potential
              leads, enabling the sales and marketing teams to focus on the most
              valuable prospects.
            </p>
          </li>
        </ol>

        <h2>Results</h2>
        <p className="case-study-content-p">
          The marketing analytics solution significantly improved the client’s
          campaign outcomes:
        </p>

        <ul className="case-study-ul-list">
          <li>
            <strong>25% Increase in Conversion Rates: </strong>
            By segmenting customers and optimizing campaigns, conversion rates
            increased by 25% over three months.
          </li>
          <li>
            <strong>20% Reduction in Cost per Lead: </strong>
            Targeted marketing efforts helped the client reduce wasteful
            spending, cutting cost per lead by 20%.
          </li>
          <li>
            <strong>Actionable Campaign Insights: </strong>
            Detailed analytics dashboards provided continuous insights into
            performance, enabling ongoing optimization.
          </li>
          <li>
            <strong>Improved Lead Quality: </strong>
            The predictive lead scoring system increased the number of
            high-quality leads handed off to sales by 30%, improving overall
            sales efficiency.
          </li>
        </ul>
        <h2>Technology Stack</h2>

        <ul className="case-study-ul-list">
          <li>
            <strong>Customer Segmentation: </strong>
            Python, K-Means Clustering
          </li>
          <li>
            <strong>Marketing Analytics: </strong>
            Google Analytics 360, Power BI
          </li>
          <li>
            <strong>A/B Testing: </strong>
            Google Optimize
          </li>
          <li>
            <strong>Lead Scoring: </strong>
            Machine Learning Algorithms (scikit-learn)
          </li>
        </ul>
      </section>

      <a href="/contactus">
        <button className="fixed-button">Request a Consultation</button>
      </a>
    </div>
  );
}
