import React from "react";

import Layout from "../Layouts/Layout";
import ECommerceWithBIBlog from "../Components/Blogs/ECommerceWithBIBlog";

export default function ECommerceWithBIBlogPage() {
  return (
    <Layout>
      <div>
        <ECommerceWithBIBlog />
      </div>
    </Layout>
  );
}
