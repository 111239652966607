import React, { useState, useEffect } from "react";
import "../Styles/TableauConsulting.css";
import DataStrategyModal from "./DataStrategyModal";
import tableau_AI_driven_BI from "../Assets/Img/tableau_AI_driven_BI.png";
import tableau_data_visulization from "../Assets/Img/tableau_data_visulization.png";
import marketing_data_strategy_consulting from "../Assets/Img/marketing_data_strategy_consulting.png";
import data_sources from "../Assets/icons/data sources.png";
import Reporting_and_visualization from "../Assets/Img/Reporting and visualization.png";
import Data_Access_and_User_Management from "../Assets/Img/Data Access and User Management.png";
import server_implementation from "../Assets/Img/server implementation.png";
import Tableau_Embedded_Analytics from "../Assets/Img/Tableau Embedded Analytics.png";
import Tableau_Pulse from "../Assets/Img/Tableau Pulse.png";

import { Helmet } from "react-helmet-async";

export default function TableauConsulting() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const title = "Tableau Consulting | White Box Data";
  const DESCRIPTION =
    "Tableau Consulting Partners. White Box Data's Tableau Consultants possess extensive experience in Tableau Implementation and Tableau Solutions delivery.";

  useEffect(() => {
    document.title = title;
  });

  return (
    <div className="data-strategy-container-main">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      >
        <link rel="canonical" href="https://www.whiteboxdata.com/tableau-consulting" />

      </Helmet>
      <section className="bi_consulting_landing">
        <div className="bi_consulting_landing_text">
          <h1>Tableau Consulting</h1>

          <div className="marketing_analytics_landing_boxes">
            <div className="marketing_analytics_box">
              <h3>Consultants with 10+ years of Tableau Experience</h3>
            </div>
            <div className="marketing_analytics_box">
              <h3>Meaningful Insights into your Data</h3>
            </div>
            <div className="marketing_analytics_box">
              <h3>AI Driven Analytics with Tableau Pulse</h3>
            </div>
          </div>
          <p>
            We help you design, develop and manage your analytics workspace
            using Tableau. We offer a complete range of Tableau Consulting and
            Data Analytics services. Whether you are looking for a Tableau
            Dashboard Specialist, migrating to tableau from another reporting
            solution, setting up Data Governance around your Tableau environment
            or are to deploy a full scale Enterprise Tableau Server solution, we
            can help.
          </p>
        </div>
      </section>

      <section className="tableau_lookingto_section">
        <h2>Considering Tableau as your BI Tool?</h2>
        <p>
          Tableau is one of the most powerful and market leading Business
          Intelligence tools that helps you get meaningful insights out of your
          data. It provides 100+ out of the box connectors for a variety of your
          data sources to effectively manage and integrate insights into a
          single view. Tableau works well for a team of any size as it is highly
          scalable and fits well within your current data infrastructure set up.
          Dashboards built with Tableau are highly customizable and caters to
          all of your teams across your organization.
        </p>
      </section>

      <section className="data-driven-insights-container mb-[2.5rem]">
        <h2>
          Tableau Consulting Services - Our Expertise
        </h2>

        <p>
          All of White Box Data’s Tableau consultants are skilled professionals
          with at least 8+ years of experience managing and deploying tableau
          analytics solutions at Fortune 50 and startups alike. Whether you are
          considering Tableau as another dashboarding tool or are wanting to
          upgrade to Tableau from legacy reporting or want help managing your
          Tableau Server implementation, our consultants will help you implement
          and deploy scalable, reliable and cost effective Tableau solutions.
        </p>

        <section>
          <div className="data-driven-insights-section">
            <div className="data-driven-insights-box bi_consulting_img_height">
              <div className="bi-infra-img">
                <img
                  className="Reporting_and_visualization"
                  src={Reporting_and_visualization}
                  alt=""
                  loading="lazy"
                ></img>
              </div>
              <h3>Tableau Dashboards and Visualization</h3>
              <span className="tableau_consulting_span">
                We help you create and develop dashboards, manage and automate
                your data sources, set up governance and institutionalized
                reporting so that Tableau becomes your single source of truth
                for all data points.
              </span>
            </div>
            <div className="data-driven-insights-box">
              <div className="bi-infra-img">
                <img className="" src={data_sources} alt="" loading="lazy"></img>
              </div>
              <h3>Migration to Tableau</h3>
              <span className="tableau_consulting_span">
                Tired of your current BI reporting setup? We conduct in-depth
                research and create a comprehensive plan for your existing
                reporting and new report development in Tableau. We guarantee to
                leave you in a better reporting state that enables you and your
                team to make faster data backed decisions.
              </span>
            </div>
            <div className="data-driven-insights-box">
              <div className="bi-infra-img">
                <img
                  className="Data_Access_and_User_Management"
                  src={Data_Access_and_User_Management}
                  alt=""
                  loading="lazy"
                ></img>
              </div>
              <h3>Tableau Governance - Data Access and User Management</h3>
              <span className="tableau_consulting_span">
                We help you set up governance processes to reduce unnecessary
                duplicated data overhead cost, manage and implement company wide
                data sources for consumption and ad-hoc reporting and manage and
                implement security at user level.
              </span>
            </div>
          </div>
        </section>

        <section className="">
          <div className="data-driven-insights-section">
            <div className="data-driven-insights-box bi_consulting_img_height">
              <div className="bi-infra-img">
                <img
                  className="server_implementation"
                  src={server_implementation}
                  alt=""
                  loading="lazy"
                ></img>
              </div>
              <h3>Tableau Server Implementation</h3>
              <span className="tableau_consulting_span">
                We analyze your current reporting state and recommend the best
                solution and practices to implement Tableau Cloud or On-premise
                Tableau server from scratch. All our Tableau Consultants have
                over 8+ years of experience managing and deploying Tableau
                solutions.
              </span>
            </div>
            <div className="data-driven-insights-box">
              <div className="bi-infra-img">
                <img className="Tableau_Pulse" src={Tableau_Pulse} alt="" loading="lazy"></img>
              </div>
              <h3>AI Integration with Tableau Pulse</h3>
              <span className="tableau_consulting_span">
                Looking to help your teams get more efficient with Tableau?
                Introducing Tableau Pulse. Next generation of tableau offering
                which provides personalized reporting for all stakeholders
                across the organization. Whether it is a Dashboard creator who
                needs to optimize data sources, or a Vertical Product leader
                looking for specific KPIs, it helps them all.
              </span>
            </div>
            <div className="data-driven-insights-box">
              <div className="bi-infra-img">
                <img
                  className="Tableau_Embedded_Analytics"
                  src={Tableau_Embedded_Analytics}
                  alt=""
                  loading="lazy"
                ></img>
              </div>
              <h3>Tableau Embedded Analytics</h3>
              <span className="tableau_consulting_span">
                Create your partner or client specific dashboards with the
                Branding of your choice. We help you create solutions that are
                extremely personalized yet maintain the security and integrity
                of your Tableau solution.
              </span>
            </div>
          </div>
        </section>

        <h2>
          Benefits of White Box Data’s Tableau Consulting
        </h2>

        <p>
          For a successful Tableau implementation project, creating a holistic
          strategy with clearly defined goals and expected outcomes is a must.
          With our expertise as a Strategic Tableau Consultant, we follow a
          unique approach to make sure you get the best out of your Tableau
          Analytics Framework.
        </p>

        <ul>
          <li>
            <strong className="font-semibold">Consulting Call - </strong>It starts with a consulting call
            with our lead Tableau consultant to understand your Tableau needs.
          </li>
          <li>
            <strong className="font-semibold">Onboarding and Research Document - </strong>We follow it up
            with in-depth research about your systems and interviews with
            relevant stakeholders and create a defined road map for
            implementation.
          </li>
          <li>
            <strong className="font-semibold">Data Integration and ETL Processes - </strong>We build a
            scalable ETL framework or Data layers with 100% accurate data points
            that are important for your business.
          </li>
          <li>
            <strong className="font-semibold">Dashboard Creation and Management - </strong>
            Based on your requirements we create beautiful, highly personalized
            and completely customized dashboards for your organization.
          </li>
          <li>
            <strong className="font-semibold">Data Governance and Alerting - </strong>
            We set up extensive relevant data alerts for all your critical KPIs
            to help you become proactive instead of reactive.
          </li>
          <li>
            <strong className="font-semibold">Continued Support and Learning - </strong>
            We help you add and maintain your dashboards for relevancy and
            accurate reporting as your teams and business grows. We help you
            become a data-driven organization.
          </li>
        </ul>
      </section>

      <section className="consulting_cta_button">
        <h2>Ready to begin your Tableau Journey?</h2>
        <p>
          Start getting personalized insights into your data. <br />
          Schedule your consultation with our lead consultant today.
        </p>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setIsModalOpen(true);
          }}
          className="talk-to-expert-btn mb-[10%]"
        >
          Request a Consultation
        </a>
      </section>

      <div className="marketing_services_container bi_cta_boxes">
        <div className="marketing_services_box">
          <a href="/blog/the-art-and-science-of-data-visualization">
            <div className="marketing_services_image">
              <img src={tableau_data_visulization} alt="" loading="lazy"></img>
            </div>
            <div className="bi_consulting_title">
              <p>Blog</p>
              <span className="">
                The Art and Science of Data Visualization
              </span>
            </div>
          </a>
        </div>
        <div className="marketing_services_box">
          <a href="/blog/ai-driven-bi">
            <div className="marketing_services_image">
              <img src={tableau_AI_driven_BI} alt="" loading="lazy"></img>
            </div>
            <div className="bi_consulting_title">
              <p>Blog</p>
              <span className="">AI Driven Business Intelligence</span>
            </div>
          </a>
        </div>

        <div className="marketing_services_box">
          <a href="/data">
            <div className="marketing_services_image">
              <img src={marketing_data_strategy_consulting} alt="" loading="lazy"></img>
            </div>
            <div className="bi_consulting_title">
              <p>Consulting</p>
              <span className="">Analytics Consulting</span>
            </div>
          </a>
        </div>
      </div>

      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setIsModalOpen(true);
        }}
        className="consulting_fixed_btn"
      >
        Talk to a Data Expert
      </a>

      <DataStrategyModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}
