import React from "react";

import Layout from "../Layouts/Layout";
import AIConsulting from "../Components/AIConsulting";

export default function AIConsultingPage() {
  return (
    <Layout>
      <div>
        <AIConsulting />
      </div>
    </Layout>
  );
}
