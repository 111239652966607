import React from "react";

import Layout from "../Layouts/Layout";
import CustomerEngagementRevenue from "../Components/Case Studies/CustomerEngagementRevenue";

export default function CustomerEngagementRevenuePage() {
  return (
    <Layout>
      <div>
        <CustomerEngagementRevenue />
      </div>
    </Layout>
  );
}
