import React from "react";

import Layout from "../Layouts/Layout";
import TableauConsulting from "../Components/TableauConsulting";

export default function TableauConsultingPage() {
  return (
    <Layout>
      <div>
        <TableauConsulting />
      </div>
    </Layout>
  );
}
