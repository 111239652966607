import React from "react";

import BenefitsBIServicesBlog from "../Components/Blogs/BenefitsBIServicesBlog";
import Layout from "../Layouts/Layout";

export default function BenefitsBIServicesBlogPage() {
  return (
    <Layout>
      <div>
        <BenefitsBIServicesBlog />
      </div>
    </Layout>
  );
}
