import React from "react";

import Layout from "../Layouts/Layout";
import DataStrategy from "../Components/DataStrategy";

export default function DataStrategyPage() {
  return (
    <Layout>
      <div>
        <DataStrategy />
      </div>
    </Layout>
  );
}
