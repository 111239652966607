import React from "react";

import Layout from "../Layouts/Layout";
import Home from "../Components/Home";

export default function HomePage() {
  return (
    <Layout>
      <div>
        <Home />
      </div>
    </Layout>
  );
}
