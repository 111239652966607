import React from "react";

import PrivacyPolicy from "../Components/PrivacyPolicy";
// import Layout from "../Layouts/Layout";

export default function PrivacyPolicyPage() {
  return (
    // <Layout>
      <div>
        <PrivacyPolicy />
      </div>
    // </Layout>
  );
}
