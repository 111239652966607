import React, { useState, useLayoutEffect } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import logo from "../../Assets/icons/logo.webp";
import { NavLink, useLocation } from "react-router-dom";
import DataStrategyModal from "../DataStrategyModal";
import { FaChevronDown } from "react-icons/fa";
import "../../Styles/Header.css";

const Header = () => {
  const [nav, setNav] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resourcesOpen, setResourcesOpen] = useState(false);
  const location = useLocation();

  const handleNav = () => {
    setNav(!nav);
  };

  const closeNav = () => {
    setNav(false);
  };

  const handleMouseEnter = (section) => {
    if (section === "services") {
      setServicesOpen(true);
    } else if (section === "resources") {
      setResourcesOpen(true);
    }
  };

  const handleMouseLeave = (section) => {
    if (section === "services") {
      setServicesOpen(false);
    } else if (section === "resources") {
      setResourcesOpen(false);
    }
  };

  const toggleSubmenu = (section) => {
    if (section === "services") {
      setServicesOpen(!servicesOpen);
      setResourcesOpen(false);
    } else if (section === "resources") {
      setResourcesOpen(!resourcesOpen);
      setServicesOpen(false);
    }
    setNav(true);
  };

  // const isActiveService = location.pathname.startsWith("/services");
  // const isActiveResource =
  //   location.pathname.startsWith("/blog") ||
  //   location.pathname.startsWith("/about");

  useLayoutEffect(() => {
    const isAtTop = window.scrollY === 0;
    if (!isAtTop) {
      window.scrollTo(0, 0);
    }
    setServicesOpen(false);
    setResourcesOpen(false);
  }, [location.pathname]);

  const navItems = [
    {
      id: 1,
      text: "What We Do",
      submenu: [
        {
          id: 1,
          text: "Consulting",
          submenu: [
            {
              id: 1,
              text: "Marketing Analytics",
              path: "/marketing-analytics-consulting",
            },
            {
              id: 2,
              text: "Business Intelligence",
              path: "/business-intelligence-consulting",
            },
            {
              id: 3,
              text: "Data Management",
              path: "/data-management-consulting",
            },
            {
              id: 4,
              text: "AI Consulting",
              path: "/ai-consulting-services",
            },
            {
              id: 5,
              text: "Tableau Consulting",
              path: "/tableau-consulting",
            },
          ],
        },
        {
          id: 2,
          text: "Services",
          submenu: [
            {
              id: 1,
              text: "Growth & Marketing technology",
              path: "/services/growth-and-marketing-technology",
            },
            {
              id: 2,
              text: "Business Intelligence & Analytics",
              path: "/services/business-intelligence-and-analytics",
            },
            {
              id: 3,
              text: "Data Engineering & Infrastructure",
              path: "/services/data-engineering-and-infrastructure",
            },
          ],
        },
        {
          id: 3,
          text: "Subscription",
          submenu: [{ id: 1, text: "Insights Pro", path: "/insights" }],
        },
      ],
    },
    {
      id: 2,
      text: "Resources",
      submenu: [
        { id: 1, text: "Blog", path: "/blog" },
        { id: 2, text: "Case Studies", path: "/case-studies" },
        { id: 3, text: "About Us ", path: "/aboutus" },

      ],
    },
    { id: 3, text: "Contact Us", path: "/contactus" },
    {
      id: 4,
      text: "Data Strategy Session",
      path: "#",
      className: "data-strategy-button",
      onClick: () => setIsModalOpen(true),
    },
  ];

  return (
    <>
      <div className="navbar flex justify-between items-center w-full [font-size:18px] text-white">
        <a href="/home" className="flex items-center">
          <img className="logo" src={logo} alt="Whiteboxdata logo"></img>
        </a>

        <ul className="nav-item hidden md:flex">
          {navItems.map((item) => (
            <li
              key={item.id}
              className={`p-10 cursor-pointer duration-300 ${item.className}`}
              onMouseEnter={() =>
                item.text === "What We Do"
                  ? handleMouseEnter("services")
                  : item.text === "Resources"
                  ? handleMouseEnter("resources")
                  : null
              }
              onMouseLeave={() =>
                item.text === "What We Do"
                  ? handleMouseLeave("services")
                  : item.text === "Resources"
                  ? handleMouseLeave("resources")
                  : null
              }
              onClick={item.onClick ? item.onClick : null}
            >
              {item.text === "What We Do" || item.text === "Resources" ? (
                <span>{item.text}</span>
              ) : (
                <NavLink
                  to={item.path}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  {item.text}
                </NavLink>
              )}

              {item.text === "What We Do" && servicesOpen && (
                <div className="services-submenu-container shadow-md absolute center-screen">
                  <div className="services-submenu-header">
                    {/* <NavLink to="/services" className="">
                      What We Do
                    </NavLink> */}
                  </div>
                  <div className="services-submenu-content flex">
                    {item.submenu.map((subItem) => (
                      <div
                        key={subItem.id}
                        className="services-submenu-section p-4"
                      >
                        <NavLink
                          to={
                            subItem.text === "Consulting"
                              ? "/data"
                              : subItem.text === "Services"
                              ? "/services"
                              : subItem.text === "Subscription"
                              ? "/insights"
                              : "#"
                          }
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                          onClick={closeNav}
                        >
                          <div className="font-bold-subtitle">
                            {subItem.text}
                          </div>
                        </NavLink>

                        {subItem.submenu && (
                          <ul>
                            {subItem.submenu.map((subSubItem) => (
                              <li
                                key={subSubItem.id}
                                className="flex items-center justify-between"
                              >
                                <NavLink
                                  to={subSubItem.path}
                                  className={({ isActive }) =>
                                    isActive ? "active" : ""
                                  }
                                >
                                  {subSubItem.text}
                                </NavLink>
                                <span className="arrow">&gt;</span>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* {item.text === "Resources" && (
                <div className={isActiveResource ? "active" : ""}>
                  {item.text}
                </div>
              )} */}
              {item.text === "Resources" && resourcesOpen && (
                <ul className="submenu shadow-md absolute">
                  {item.submenu.map((subItem) => (
                    <li
                      key={subItem.id}
                      className="p-2 flex items-center justify-between"
                    >
                      <NavLink
                        to={subItem.path}
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        <span>{subItem.text}</span>
                      </NavLink>
                      <span className="arrow">&gt;</span>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>

        <div onClick={handleNav} className="block md:hidden hamburger-menu">
          {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
        </div>

        <ul
          className={
            nav
              ? "fixed hamburger-menu left-0 top-0 w-[80%] h-full border-r border-r-gray-600 bg-white ease-in-out duration-500 [font-size:17px] text-[#0156b8] [font-weight:500]"
              : "ease-in-out w-[70%] duration-500 fixed top-0 bottom-0 left-[-100%]"
          }
        >
          {navItems.map((item) => (
            <li
              key={item.id}
              className="p-4 border-b border-gray-600 hover: cursor-pointer duration-300 [font-weight:700]"
            >
              <div
                className="flex justify-between items-center"
                onClick={() =>
                  toggleSubmenu(
                    item.text === "What We Do"
                      ? "services"
                      : item.text.toLowerCase()
                  )
                }
              >
                <NavLink
                  to={item.path}
                  onClick={() => {
                    if (item.onClick) item.onClick();
                    closeNav();
                  }}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  {item.text}
                </NavLink>
                {item.submenu && (
                  <FaChevronDown className="ml-2 text-[#0156b8]" />
                )}
              </div>
              {item.text === "What We Do" && servicesOpen && (
                <ul className="ml-4">
                  {item.submenu.map((subItem) => (
                    <li key={subItem.id} className="p-2 [font-weight:700]">
                      <NavLink
                        to={
                          subItem.text === "Consulting"
                            ? "/data"
                            : subItem.text === "Services"
                            ? "/services"
                            : subItem.text === "Subscription"
                            ? "/insights"
                            : "#"
                        }
                        onClick={closeNav}
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        {subItem.text}
                      </NavLink>
                      {subItem.submenu && (
                        <ul className="ml-4">
                          {subItem.submenu.map((subSubItem) => (
                            <li key={subSubItem.id} className="p-2">
                              <NavLink
                                to={subSubItem.path}
                                onClick={closeNav}
                                className={({ isActive }) =>
                                  isActive ? "active" : ""
                                }
                              >
                                {subSubItem.text}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              )}
              {item.text === "Resources" && resourcesOpen && (
                <ul className="ml-4">
                  {item.submenu.map((subItem) => (
                    <li key={subItem.id} className="p-2">
                      <NavLink
                        to={subItem.path}
                        onClick={closeNav}
                        className={({ isActive }) => (isActive ? "active" : "")}
                      >
                        {subItem.text}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>

      <DataStrategyModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default Header;
