import React from "react";

import RealTimeMarketingAnalyticsBlog from "../Components/Blogs/RealTimeMarketingAnalyticsBlog";
import Layout from "../Layouts/Layout";

export default function RealTimeMarketingAnalyticsBlogPage() {
  return (
    <Layout>
      <div>
        <RealTimeMarketingAnalyticsBlog />
      </div>
    </Layout>
  );
}
