import React from "react";

import Layout from "../Layouts/Layout";
import ContactUs from "../Components/ContactUs";

export default function ContactPage() {
  return (
    <Layout>
      <div>
        <ContactUs />
      </div>
    </Layout>
  );
}
