import React from "react";

import Layout from "../Layouts/Layout";
import DataDrivenTransformation from "../Components/Case Studies/DataDrivenTransformation";

export default function DataDrivenTransformationPage() {
  return (
    <Layout>
      <div>
        <DataDrivenTransformation />
      </div>
    </Layout>
  );
}
