import React, { useState, useRef } from "react";
import PhoneInput from "react-phone-number-input";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";

import "../Styles/DataStrategyForm.css";

export default function DataStrategyForm() {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [location, setLocation] = useState("");
  const [hearabout, setHearabout] = useState("");
  const [message, setMessage] = useState("");
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const LocationData = [
    { id: 1, title: "USA", label: "usa" },
    { id: 2, title: "UK", label: "uk" },
    { id: 3, title: "EU (EUROPEAN UNION)", label: "eu" },
    { id: 4, title: "AUSTRALIA", label: "au" },
    { id: 5, title: "OTHER", label: "other" },
  ];

  const PositionData = [
    { id: 1, title: "Manager", label: "manager" },
    { id: 2, title: "Director", label: "director" },
    { id: 3, title: "VP", label: "vp" },
    { id: 4, title: "CxO", label: "cxo" },
    { id: 5, title: "Other", label: "other" },
  ];

  const form = useRef();
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();
    // navigate("/");

    emailjs
      .sendForm("service_rjnzoj6", "template_ypceb2u", form.current, {
        publicKey: "dLGaSfNHHKUpDuLYk",
      })

      .then(
        () => {
          setSubmissionSuccess(true);
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert("An error occurred while sending the message.");
        }
      );

    form.current.reset();
    setLocation("");
    setPosition("");
  };

  return (
    <div className="datastrategy-container">
      <div className="form-section1-text">
        <h1>Data Strategy Session</h1>
        <p>
          We understand the complexities of data. It can be often confusing,
          time-consuming, and even frustrating. Your team, processes and
          technology must all align if you want to succeed with your data.
          Schedule a strategy Session with one of Silicon Valley's top data
          analytics experts who will understand your challenges and help
          identify areas of focus & optimization to empower your business with
          the right tools and strategies. Let us be your data guide.
        </p>

        <p>
          Fill out this form to get a 30-minute Data Strategy Session with one
          of our analytics experts.
        </p>
      </div>

      <div className="datastrategy-form">
        {!submissionSuccess && (
          <form ref={form} onSubmit={sendEmail}>
            <div className="grid gap-6 mb-6 md:grid-cols-2">
              <div>
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  First Name*
                </label>
                <input
                  type="text"
                  id="first_name"
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_first_name"
                  required
                  pattern="[A-Za-z\s]+"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Last Name*
                </label>
                <input
                  type="text"
                  id="last_name"
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_last_name"
                  required
                  pattern="[A-Za-z\s]+"
                  value={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              <div className="mb-6">
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Phone*
                </label>
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  name="from_mobile"
                  required
                  defaultCountry="US"
                  value={phone}
                  onChange={setPhone}
                  className="text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full flex"
                />
              </div>

              <div className="mb-6">
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Email Address*
                </label>
                <input
                  type="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div>
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Company Name*
                </label>
                <input
                  type="text"
                  id="company"
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_company"
                  required
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
              </div>

              <div>
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Position*
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_position"
                  required
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                >
                  <option value="" disabled>
                    Select a Position
                  </option>
                  {PositionData.map((item) => (
                    <option key={item.id} value={item.title}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Location*
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_location"
                  required
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                >
                  <option value="" disabled>
                    Select a location
                  </option>
                  {LocationData.map((item) => (
                    <option key={item.id} value={item.title}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                Message
              </label>
              <textarea
                className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-[7rem]"
                type="text"
                as="textarea"
                name="message"
                // required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            {/* <div className="flex items-start mb-6">
              <div className="flex items-center h-5">
                <input
                  id="remember"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                  required
                />
              </div>

              <label
                for="remember"
                className="ms-2 text-sm font-medium text-[#002c5f]"
              >
                By checking this box, you agree to our{" "}
                <a href="https://www.whiteboxdata.com/privacy-policy">
                  <u>Privacy Policy,</u>{" "}
                </a>{" "}
                and we may contact you about our products and services
              </label>
            </div> */}
            <button
              type="submit"
              className="text-white bg-[#0156b8] font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-3.5 text-center"
            >
              Request a Consultation
            </button>
          </form>
        )}
        {submissionSuccess && (
          <div className="message-datastrategy">
            <div className="success-datastrategy" id="success">
              Thank you! We have received your Information and one of our Expert
              Consultant will reach out within 24 hrs. We are eager to learn
              more and help you on your Data Journey.
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
