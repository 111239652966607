import React from "react";

import Layout from "../Layouts/Layout";
import CaseStudy from "../Components/CaseStudy";

export default function CaseStudyPage() {
  return (
    <Layout>
      <div>
        <CaseStudy />
      </div>
    </Layout>
  );
}
