import React, { useEffect } from "react";
import CustomerEngagementRevenueOuter from "../Assets/Img/CustomerEngagementRevenueOuter.jpg";
import DataDrivenTransformationOuter from "../Assets/Img/DataDrivenTransformationOuter.png";
import RealTimeOperationalDashboardsOuter from "../Assets/Img/RealTimeOperationalDashboardsOuter.png";
import DataWarehouseModernizationOuter from "../Assets/Img/DataWarehouseModernizationOuter.png";
import ScalableDataEngineeringSolutionOuter from "../Assets/Img/ScalableDataEngineeringSolutionOuter.png";
import BoostingROIMultiChannelAttributionOuter from "../Assets/Img/BoostingROIMultiChannelAttributionOuter.webp";
import EnhancingSalesPerformanceBIDashboardsOuter from "../Assets/Img/EnhancingSalesPerformanceBIDashboardsOuter.webp";
import ImprovingCustomerRetentionPredictiveAnalyticsOuter from "../Assets/Img/ImprovingCustomerRetentionPredictiveAnalyticsOuter.webp";
import OptimizingMarketingPerformanceOuter from "../Assets/Img/OptimizingMarketingPerformanceOuter.webp";
import StreamliningFinancialReportingBIOuter from "../Assets/Img/StreamliningFinancialReportingBIOuter.webp";

import { Helmet } from "react-helmet-async";

import "../Styles/CaseStudy.css";
export default function CaseStudy() {
  const title = "Case Studies | White Box Data";
  const DESCRIPTION =
    "Learn how White Box Data helps clients with their Data Journey";

  useEffect(() => {
    document.title = title;
  });

  return (
    <div className="casestudy-main-container">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      >
        <link rel="canonical" href="https://www.whiteboxdata.com/case-studies" />

      </Helmet>
      <section className="casestudy-home">
        <div className="casestudy-home-content">
          <h1>Case Studies</h1>

          <p>
            See how our advanced analytics, attribution modeling, data
            warehousing and reporting solutions are put into action. These case
            studies demonstrate how White Box Data tackles complex problems and
            delivers actionable insights to drive smarter marketing decisions.
          </p>
        </div>

        <div className="casestudy-container">
          <div className="casestudy-box">
            <a href="/case-studies/marketing-data-analytics-implementation-for-a-financial-services-company">
              <div className="casestudy-image">
                <img src={CustomerEngagementRevenueOuter} alt=""></img>
              </div>
              <div className="casestudy-title">
                <span className="casestudy-title-span">
                  Data Analytics Solution for a 360 Channel Attribution View for
                  Customer Engagement & Revenue
                </span>
                <span className="read-more">Read More &gt;&gt;&gt;</span>
              </div>
            </a>
          </div>

          <div className="casestudy-box">
            <a href="/case-studies/bi-driven-analytics-transformation-for-a-lending-company">
              <div className="casestudy-image">
                <img src={DataDrivenTransformationOuter} alt=""></img>
              </div>
              <div className="casestudy-title">
                <span className="casestudy-title-span">
                  Business Intelligence Driven Analytics Transformation
                </span>
                <span className="read-more">Read More &gt;&gt;&gt;</span>
              </div>
            </a>
          </div>
        </div>

        <div className="casestudy-container">
          <div className="casestudy-box">
            <a href="/case-studies/real-time-tableau-dashboards">
              <div className="casestudy-image">
                <img src={RealTimeOperationalDashboardsOuter} alt=""></img>
              </div>
              <div className="casestudy-title">
                <span className="casestudy-title-span">
                  Real Time Reporting and Analytics Solution for an EdTech
                  company
                </span>
                <span className="read-more">Read More &gt;&gt;&gt;</span>
              </div>
            </a>
          </div>

          <div className="casestudy-box">
            <a href="/case-studies/data-warehouse-modernization-healthcare">
              <div className="casestudy-image">
                <img src={DataWarehouseModernizationOuter} alt=""></img>
              </div>
              <div className="casestudy-title">
                <span className="casestudy-title-span">
                Data Warehouse Modernization for a Healthcare Analytics Firm
                </span>
                <span className="read-more">Read More &gt;&gt;&gt;</span>
              </div>
            </a>
          </div>
        </div>

        <div className="casestudy-container">
          <div className="casestudy-box">
            <a href="/case-studies/scalable-data-engineering-solution-ecommerce">
              <div className="casestudy-image">
                <img src={ScalableDataEngineeringSolutionOuter} alt=""></img>
              </div>
              <div className="casestudy-title">
                <span className="casestudy-title-span">
                Scalable Data Engineering Solution for E-Commerce Personalization
                </span>
                <span className="read-more">Read More &gt;&gt;&gt;</span>
              </div>
            </a>
          </div>

          <div className="casestudy-box">
            <a href="/case-studies/multi-channel-attribution-marketing-agency">
              <div className="casestudy-image">
                <img src={BoostingROIMultiChannelAttributionOuter} alt=""></img>
              </div>
              <div className="casestudy-title">
                <span className="casestudy-title-span">
                Boosting ROI with Multi-Channel Attribution for a Digital Advertising & Marketing Agency
                </span>
                <span className="read-more">Read More &gt;&gt;&gt;</span>
              </div>
            </a>
          </div>
        </div>

        <div className="casestudy-container">
          <div className="casestudy-box">
            <a href="/case-studies/bi-dashboards-sales-performance-retail">
              <div className="casestudy-image">
                <img src={EnhancingSalesPerformanceBIDashboardsOuter} alt=""></img>
              </div>
              <div className="casestudy-title">
                <span className="casestudy-title-span">
                Enhancing Sales Performance with BI Dashboards for a Retail Chain
                </span>
                <span className="read-more">Read More &gt;&gt;&gt;</span>
              </div>
            </a>
          </div>

          <div className="casestudy-box">
            <a href="/case-studies/reduce-churn-predictive-analytics-subscription">
              <div className="casestudy-image">
                <img src={ImprovingCustomerRetentionPredictiveAnalyticsOuter} alt=""></img>
              </div>
              <div className="casestudy-title">
                <span className="casestudy-title-span">
                Improving Customer Retention through Predictive Analytics for a Subscription-Based Service
                </span>
                <span className="read-more">Read More &gt;&gt;&gt;</span>
              </div>
            </a>
          </div>
        </div>

        <div className="casestudy-container">
          <div className="casestudy-box">
            <a href="/case-studies/marketing-analytics-b2b-saas-conversions">
              <div className="casestudy-image">
                <img src={OptimizingMarketingPerformanceOuter} alt=""></img>
              </div>
              <div className="casestudy-title">
                <span className="casestudy-title-span">
                Optimizing Marketing Performance for a B2B SaaS Company with Advanced Analytics
                </span>
                <span className="read-more">Read More &gt;&gt;&gt;</span>
              </div>
            </a>
          </div>

          <div className="casestudy-box">
            <a href="/case-studies/bi-financial-reporting-healthcare">
              <div className="casestudy-image">
                <img src={StreamliningFinancialReportingBIOuter} alt=""></img>
              </div>
              <div className="casestudy-title">
                <span className="casestudy-title-span">
                Streamlining Financial Reporting with Business Intelligence for a Healthcare Provider
                </span>
                <span className="read-more">Read More &gt;&gt;&gt;</span>
              </div>
            </a>
          </div>
        </div>
      </section>

      {/* <a href="/contactus">
        <button className="fixed-button">Request a Consultation</button>
      </a> */}
    </div>
  );
}
