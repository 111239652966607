import React from "react";

import Insights from "../Components/Insights";
import Layout from "../Layouts/Layout";

export default function InsightsPage() {
  return (
    <Layout>
      <div>
        <Insights />
      </div>
    </Layout>
  );
}
