import React from "react";

import MarketingAutomationConsultingPartnerBlog from "../Components/Blogs/MarketingAutomationConsultingPartnerBlog";
import Layout from "../Layouts/Layout";

export default function MarketingAutomationConsultingPartnerBlogPage() {
  return (
    <Layout>
      <div>
        <MarketingAutomationConsultingPartnerBlog />
      </div>
    </Layout>
  );
}
