import React, {useEffect} from "react";

import about_desktop from "../Assets/Img/about_desktop.svg";
import about_mobile from "../Assets/Img/about_mobile.svg";
import guide from "../Assets/icons/icon_guide.svg";
import experience from "../Assets/icons/icon_experience.svg";
import map from "../Assets/icons/icon_map.png";
import about_wall from "../Assets/Img/about_wall.png";
import customer from "../Assets/Img/customer.svg";
import progress from "../Assets/Img/progress.svg";
import ownership from "../Assets/Img/ownership.svg";
import empathy from "../Assets/Img/empathy.svg";
import { Helmet } from "react-helmet-async";


import "../Styles/AboutUs.css";

export default function AboutUs() {

  const title = "About Us | White Box Data";
  const DESCRIPTION =
    "Discover White Box Data, a leading data consulting firm dedicated to empowering businesses with data-driven insights.Our team of expert consultants specializes in Business Intelligence, Data Engineering, Marketing Analytics and Growth Marketing solutions tailored to your unique needs. Learn more about our mission, values, and team.";

  useEffect(() => {
    document.title = title;
  });


  return (
    <div className="about-main">
     <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      >
        <link rel="canonical" href="https://www.whiteboxdata.com/aboutus" />

      </Helmet>

      <section className="story-section">
        <div className="about-text">
          <h1>
            Our Story
          </h1>
          <p className="story-text">
            Founded by Silicon Valley data analytics experts, White Box Data was
            born out of a vision to bridge the gap between complex data and
            actionable insights. With a decade of experience in silicon valley,
            our team brings together a diverse blend of expertise, cutting-edge
            technology, and a genuine desire to empower our clients.
          </p>
        </div>

        <div className="story-image">
          <img
            className="about-desktop"
            src={about_desktop}
            alt="About us image"
          ></img>
          <img
            className="about-mobile"
            src={about_mobile}
            alt="About us image"
          ></img>
        </div>
      </section>

      <section className="philosophy-section">
        <div className="philosophy">
          <h1>
            Our Philosophy
          </h1>
          <p className="philosophy-box">
            We believe that data holds the key to unlocking untapped potential
            within every organization. Our name, “White Box Data”, reflects our
            commitment to transparency and clarity. We are determined to open
            the “white box” of data, revealing its inner workings in a way that
            demystifies complex analytics and empowers our clients to harness
            its true value.
          </p>
        </div>
        <div className="philosophy mt-[0rem]">
          <h1>
            What Sets Us Apart?
          </h1>
          <p className="philosophy-box">
            At White Box Data, we go beyond surface-level analyses. Our team of
            seasoned data scientists, analysts, data engineers and consultants
            dives deep into the data ecosystem to extract meaningful insights.
            We combine advanced analytics, data visualization, and
            industry-specific knowledge to deliver tailor-made solutions that
            align with your unique business goals.
          </p>
        </div>
      </section>

      <section className="boxes-section">
        <div className="guide-box">
          <img
            style={{ marginBottom: "20px" }}
            src={map}
            alt="Map logo"
            loading="lazy"
          ></img>
          <p>Complete Packages for All Your Data Needs</p>
        </div>
        <div className="guide-box">
          <img
            style={{ marginBottom: "20px" }}
            src={experience}
            alt="Experience logo"
            loading="lazy"
          ></img>
          <p>Combined Experience Over 25 years</p>
        </div>
        <div className="guide-box">
          <img
            style={{ marginBottom: "20px" }}
            src={guide}
            alt="Guide logo"
            loading="lazy"
          ></img>
          <p>Consult with Silicon Valley Data Experts</p>
        </div>
      </section>

      <section className="wall-section">
        <img
          className="about_wall"
          src={about_wall}
          alt="Expertise image"
          loading="lazy"
        ></img>
      </section>

      <section id="careers">
        <h1>
          Our Values
        </h1>
        <div className="values-container">
          <div className="values-box">
            <div className="customer-box">
              {" "}
              <img
                src={customer}
                alt="Customer Centric logo"
                loading="lazy"
              ></img>
              <h2>Customer Centric</h2>
            </div>

            <p className="value-text textbox1">
              We always put customers at the center of everything we do. We work
              tirelessly to attain the best possible results for them and to
              earn and keep their trust.
            </p>
          </div>
          <div className="values-box">
            <div className="progress-box">
              <img src={progress} alt="Progress logo" loading="lazy"></img>
              <h2 className="progress-h2-text">Progress</h2>
            </div>

            <p className="value-text textbox2">
              We always want to move forward. We follow a strategic and data
              driven approach to get the best results for our customers.
            </p>
          </div>

          <div className="values-box">
            <div className="ownership-box">
              <img src={ownership} alt="Ownership logo" loading="lazy"></img>
              <h2>Ownership</h2>
            </div>
            <p className="value-text textbox3">
              We achieve great results by owning our work as a team and as
              individuals.
            </p>
          </div>

          <div className="values-box">
            <div className="empathy-box">
              <img src={empathy} alt="Empathy logo" loading="lazy"></img>
              <h2>Empathy</h2>
            </div>
            <p className="value-text textbox4">
              We constantly strive to achieve utmost understanding. Not just
              with our team but for all our customers.
            </p>
          </div>
        </div>
      </section>

      <section className="joinus-section">
        <p>
          "Interested in working at white box data? Come Join Us!". <br />
          We are growing! Please drop a line
          along with the role you might be interested in and your resume at{" "}
          <a href="mailto:consulting@whiteboxdata.com">
            <u>consulting@whiteboxdata.com.</u>
          </a>
        </p>
      </section>
    </div>
  );
}
