import React from "react";
import { createBrowserRouter } from "react-router-dom";

import HomePage from "./Pages/HomePage";
import ContactPage from "./Pages/ContactPage";
import BlogPage from "./Pages/BlogPage";
import PrivacyPolicyPage from "./Pages/PrivacyPolicyPage";
import InsightsPage from "./Pages/InsightsPage";
import AboutUsPage from "./Pages/AboutUsPage";
import GrowthMarketingPage from "./Pages/GrowthMarketingPage";
import BusinessIntelligencePage from "./Pages/BusinessIntelligencePage";
import DataInfrastructurePage from "./Pages/DataInfrastructurePage";
import DataLakeBlogPage from "./Pages/DataLakeBlogPage";
import DataVisualizationBlogPage from "./Pages/DataVisualizationBlogPage";
import ServicesPage from "./Pages/ServicesPage";
import AiBiBlogPage from "./Pages/AiBiBlogPage";
import DataStrategyPage from "./Pages/DataStrategyPage";
import BiTransformHealthcareBlogPage from "./Pages/BiTransformHealthcareBlogPage";
import BiTransformFinancialBlogPage from "./Pages/BiTransformFinancialBlogPage";
import GoogleCookieBlogPage from "./Pages/GoogleCookieBlogPage";
import CaseStudyPage from "./Pages/CaseStudyPage";
import CustomerEngagementRevenuePage from "./Pages/CustomerEngagementRevenuePage";
import DataDrivenTransformationPage from "./Pages/DataDrivenTransformationPage";
import MarketingAnalyticsConsultingPage from "./Pages/MarketingAnalyticsConsultingPage";
import BusinessIntelligenceConsultingPage from "./Pages/BusinessIntelligenceConsultingPage";
import BenefitsBIServicesBlogPage from "./Pages/BenefitsBIServicesBlogPage";
import TableauConsultingPage from "./Pages/TableauConsultingPage";
import RealTimeOperationalDashboardsPage from "./Pages/RealTimeOperationalDashboardsPage";
import FractionalBusinessIntelligenceConsultingBlogPage from "./Pages/FractionalBusinessIntelligenceConsultingBlogPage";
import MarketingAutomationConsultingPartnerBlogPage from "./Pages/MarketingAutomationConsultingPartnerBlogPage";
import MarketingAnalyticsTrendsIn2024BlogPage from "./Pages/MarketingAnalyticsTrendsIn2024BlogPage";
import MarketingAnalyticsConsultingBlogPage from "./Pages/MarketingAnalyticsConsultingBlogPage";
import AIConsultingPage from "./Pages/AIConsultingPage";
import RealTimeMarketingAnalyticsBlogPage from "./Pages/RealTimeMarketingAnalyticsBlogPage";
import ECommerceWithBIBlogPage from "./Pages/ECommerceWithBIBlogPage";
import DataManagementBlogPage from "./Pages/DataManagementBlogPage";
import ETLvsELTBlogPage from "./Pages/ETLvsELTBlogPage";
import ETLToolsBlogPage from "./Pages/ETLToolsBlogPage";
import DataManagementConsultingPage from "./Pages/DataManagementConsultingPage";
import DataWarehouseModernizationPage from "./Pages/DataWarehouseModernizationPage";
import ScalableDataEngineeringSolutionPage from "./Pages/ScalableDataEngineeringSolutionPage";
import BoostingROIMultiChannelAttributionPage from "./Pages/BoostingROIMultiChannelAttributionPage";
import EnhancingSalesPerformanceBIDashboardsPage from "./Pages/EnhancingSalesPerformanceBIDashboardsPage";
import ImprovingCustomerRetentionPredictiveAnalyticsPage from "./Pages/ImprovingCustomerRetentionPredictiveAnalyticsPage";
import OptimizingMarketingPerformancePage from "./Pages/OptimizingMarketingPerformancePage";
import StreamliningFinancialReportingBIPage from "./Pages/StreamliningFinancialReportingBIPage";


const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/home",
    element: <HomePage />,
  },
  {
    path: "/contactus",
    element: <ContactPage />,
  },
  {
    path: "/blog",
    element: <BlogPage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "/insights",
    element: <InsightsPage />,
  },
  {
    path: "/aboutus",
    element: <AboutUsPage />,
  },
  {
    path: "/services/growth-and-marketing-technology",
    element: <GrowthMarketingPage />,
  },
  {
    path: "/services/business-intelligence-and-analytics",
    element: <BusinessIntelligencePage />,
  },
  {
    path: "/services/data-engineering-and-infrastructure",
    element: <DataInfrastructurePage />,
  },
  {
    path: "/blog/datalake-vs-datawarehouse-vs-datalakehouse",
    element: <DataLakeBlogPage />,
  },
  {
    path: "/blog/the-art-and-science-of-data-visualization",
    element: <DataVisualizationBlogPage />,
  },
  {
    path: "/services",
    element: <ServicesPage />,
  },
  {
    path: "/blog/ai-driven-bi",
    element: <AiBiBlogPage />,
  },
  {
    path: "/data",
    element: <DataStrategyPage />,
  },
  {
    path: "/blog/how-business-intelligence-will-transform-healthcare",
    element: <BiTransformHealthcareBlogPage />,
  },
  {
    path: "/blog/how-business-intelligence-will-transform-financial-services",
    element: <BiTransformFinancialBlogPage />,
  },
  {
    path: "/blog/google-third-party-cookies-conundrum",
    element: <GoogleCookieBlogPage />,
  },
  {
    path: "/case-studies",
    element: <CaseStudyPage />,
  },
  {
    path: "/case-studies/marketing-data-analytics-implementation-for-a-financial-services-company",
    element: <CustomerEngagementRevenuePage />,
  },
  {
    path: "/case-studies/bi-driven-analytics-transformation-for-a-lending-company",
    element: <DataDrivenTransformationPage />,
  },
  {
    path: "/marketing-analytics-consulting",
    element: <MarketingAnalyticsConsultingPage />,
  },
  {
    path: "/business-intelligence-consulting",
    element: <BusinessIntelligenceConsultingPage />,
  },
  {
    path: "/blog/benefits-of-business-intelligence",
    element: <BenefitsBIServicesBlogPage />,
  },
  {
    path: "/tableau-consulting",
    element: <TableauConsultingPage />,
  },
  {
    path: "/case-studies/real-time-tableau-dashboards",
    element: <RealTimeOperationalDashboardsPage />,
  },
  {
    path: "/blog/fractional-business-intelligence-consultants",
    element: <FractionalBusinessIntelligenceConsultingBlogPage />,
  },
  {
    path: "/blog/choosing-the-right-marketing-automation-partner",
    element: <MarketingAutomationConsultingPartnerBlogPage />,
  },
  {
    path: "/blog/marketing-analytics-trends-to-watch",
    element: <MarketingAnalyticsTrendsIn2024BlogPage />,
  },
  {
    path: "/blog/marketing-analytics-consulting-a-fractional-approach",
    element: <MarketingAnalyticsConsultingBlogPage />,
  },
  {
    path: "/ai-consulting-services",
    element: <AIConsultingPage />,
  },
  {
    path: "/blog/real-time-marketing-analytics",
    element: <RealTimeMarketingAnalyticsBlogPage />,
  },
  {
    path: "/blog/business-intelligence-in-ecommerce-and-retail",
    element: <ECommerceWithBIBlogPage />,
  },
  {
    path: "/blog/what-is-data-management",
    element: <DataManagementBlogPage />,
  },
  {
    path: "/blog/etl-vs-elt",
    element: <ETLvsELTBlogPage />,
  },
  {
    path: "/blog/best-etl-tools",
    element: <ETLToolsBlogPage />,
  },
  {
    path: "/data-management-consulting",
    element: <DataManagementConsultingPage />,
  },
  {
    path: "/case-studies/data-warehouse-modernization-healthcare",
    element: <DataWarehouseModernizationPage />,
  },
  {
    path: "/case-studies/scalable-data-engineering-solution-ecommerce",
    element: <ScalableDataEngineeringSolutionPage />,
  },
  {
    path: "/case-studies/multi-channel-attribution-marketing-agency",
    element: <BoostingROIMultiChannelAttributionPage />,
  },
  {
    path: "/case-studies/bi-dashboards-sales-performance-retail",
    element: <EnhancingSalesPerformanceBIDashboardsPage />,
  },
  {
    path: "/case-studies/reduce-churn-predictive-analytics-subscription",
    element: <ImprovingCustomerRetentionPredictiveAnalyticsPage />,
  },
  {
    path: "/case-studies/marketing-analytics-b2b-saas-conversions",
    element: <OptimizingMarketingPerformancePage />,
  },
  {
    path: "/case-studies/bi-financial-reporting-healthcare",
    element: <StreamliningFinancialReportingBIPage />,
  },
]);

export default router;
