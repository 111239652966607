import React, { useState, useEffect } from "react";
import HomePageImage from "../Assets/Img/home.png";
import mobile_home from "../Assets/Img/mobile_home.png";
import insights_video from "../Assets/Insights/insights_video.gif";
import { PopupButton } from "react-calendly";
import { Helmet } from "react-helmet-async";
import implementation from "../Assets/icons/ImplementationAdoption.png";
import strategic from "../Assets/icons/StrategicPlanning.png";
import supportscale from "../Assets/icons/SupportScale.png";
import InsightsFormModal from "./InsightsFormModal";
import DataStrategyModal from "./DataStrategyModal";
import i1 from "../Assets/Insights/1.png";
import i2 from "../Assets/Insights/2.png";
import i3 from "../Assets/Insights/3.png";
import i4 from "../Assets/Insights/4.png";
import i5 from "../Assets/Insights/5.png";
import i6 from "../Assets/Insights/6.png";
import i7 from "../Assets/Insights/7.png";
import i8 from "../Assets/Insights/8.png";
import i9 from "../Assets/Insights/9.png";
import i10 from "../Assets/Insights/10.png";
import i11 from "../Assets/Insights/11.png";
import i12 from "../Assets/Insights/12.png";
import i13 from "../Assets/Insights/13.png";
import i14 from "../Assets/Insights/14.png";
import i15 from "../Assets/Insights/15.png";
import i16 from "../Assets/Insights/16.png";
import i17 from "../Assets/Insights/17.png";
import i18 from "../Assets/Insights/18.png";
import i19 from "../Assets/Insights/19.png";
import i20 from "../Assets/Insights/20.png";
import i21 from "../Assets/Insights/21.png";
import i22 from "../Assets/Insights/22.png";
import i23 from "../Assets/Insights/23.png";
import i24 from "../Assets/Insights/24.png";
import i25 from "../Assets/Insights/25.png";
import i26 from "../Assets/Insights/26.png";
import i27 from "../Assets/Insights/27.png";
import i28 from "../Assets/Insights/28.png";
import i29 from "../Assets/Insights/29.png";
import i30 from "../Assets/Insights/30.png";
import i31 from "../Assets/Insights/31.png";
import i32 from "../Assets/Insights/32.png";
import i33 from "../Assets/Insights/33.png";
import i34 from "../Assets/Insights/34.png";
import i35 from "../Assets/Insights/35.png";
import i36 from "../Assets/Insights/36.png";
import i37 from "../Assets/Insights/37.png";
import i38 from "../Assets/Insights/38.png";
import i39 from "../Assets/Insights/39.png";
import i40 from "../Assets/Insights/40.png";
import i41 from "../Assets/Insights/41.png";
import i42 from "../Assets/Insights/42.png";
import i43 from "../Assets/Insights/43.png";
import i44 from "../Assets/Insights/44.png";
import i45 from "../Assets/Insights/45.png";
import i46 from "../Assets/Insights/46.png";
import i47 from "../Assets/Insights/47.png";
import i48 from "../Assets/Insights/48.png";
import i49 from "../Assets/Insights/49.png";
import i50 from "../Assets/Insights/50.png";
import i51 from "../Assets/Insights/51.png";
import i52 from "../Assets/Insights/52.png";
import i53 from "../Assets/Insights/53.png";
import i54 from "../Assets/Insights/54.png";
import home_benefits_of_bi from "../Assets/Img/home_benefits_of_bi.webp";
import home_google_cookie from "../Assets/Img/home_google_cookie.webp";
import home_marketing_trends from "../Assets/Img/home_marketing_trends.webp";
import big_data_solutions_case_study from "../Assets/Img/big_data_solutions_case_study.png";
import big_data_solutions_fractional_blog from "../Assets/Img/big_data_solutions_fractional_blog.png";
import big_data_solutions_fractional_marketing from "../Assets/Img/big_data_solutions_fractional_marketing.png";
import plusIcon from "../Assets/icons/plus_icon.png";
import minus_icon from "../Assets/icons/minus_icon.png";
import big_data_solutions_case_study_mobile from "../Assets/Img/big_data_solutions_case_study_mobile.png";
import bi_ecommernce_blog from "../Assets/Img/bi_ecommernce_blog.png";
import marketing_real_time_marketing_analytics from "../Assets/Img/marketing_real_time_marketing_analytics.png";


import "../Styles/Home.css";

export default function Home() {
  const [isInsightsModalOpen, setIsInsightsModalOpen] = useState(false);
  const [isDataStrategyModalOpen, setIsDataStrategyModalOpen] = useState(false);

  const faqs = [
    {
      question: "Data Analytics & Consulting",
      answer: (
        <div className="faq_list">
          We help craft and execute data strategies that give your business a
          competitive edge. From identifying key analytics use cases to auditing
          your data capabilities, we design custom data architectures that drive
          innovation and future-proof your organization.
          <ul>
            <li>Data Strategy</li>
            <li>Business Use Case PoCs</li>
            <li>Analytics-as-a-Service</li>
            <li>Data-as-a-Service</li>
            <li>Data-as-a-Product</li>
          </ul>
        </div>
      ),
    },
    {
      question: "Business Intelligence Solutions",
      answer: (
        <div className="faq_list">
          Unlock actionable insights with our tailored Business Intelligence
          solutions. We implement BI tools that centralize your data, streamline
          reporting, and empower data-driven decisions, enabling you to
          transform raw data into strategic opportunities that accelerate
          business growth.
          <ul>
            <li>Self-Serve Analytics</li>
            <li>Real-Time Analytics</li>
            <li>AI Driven Business Intelligence</li>
            <li>BI Infrastructure</li>
            <li>Data Visualization & Reporting</li>
            <li>Analytics Framework</li>
            <li>Marketing Automation</li>
            <li>Digital Transformation</li>
            <li>Marketing Analytics</li>
          </ul>
        </div>
      ),
    },

    {
      question: " MarTech Solutions",
      answer: (
        <div className="faq_list">
          Supercharge your marketing with our MarTech solutions. We integrate
          advanced marketing technology to optimize your campaigns, enhance
          customer segmentation, and boost engagement. Our solutions ensure your
          marketing strategy is data-driven, personalized, and scalable for
          long-term success.
          <ul>
            <li>GA4 Implementation</li>
            <li>Martech Strategy</li>
            <li>MarTech Reporting & Analytics</li>
            <li>Marketing Tool Audit</li>
            <li>Customer Data Platform</li>
            <li>CRO Services</li>
          </ul>
        </div>
      ),
    },

    {
      question: " Data Platform & Engineering",
      answer: (
        <div className="faq_list">
          Build a resilient data infrastructure with our Data Platform &
          Engineering expertise. We design scalable platforms, integrate cloud
          technologies (AWS, Azure, Snowflake, GCP, and Databricks) and ensure
          seamless data pipelines, enabling you to manage complex datasets and
          power advanced analytics across your organization.
          <ul>
            <li>Data Warehouse / Data Lakes / Data Lakehouse</li>
            <li>Modern DE Stack</li>
            <li>Data Modeling</li>
            <li>Efficient ETL & ELT processes</li>
            <li>Data Migration</li>
            <li>Cloud Services</li>
            <li>Custom API Integration</li>
            <li>Data Pipeline Monitoring</li>
          </ul>
        </div>
      ),
    },

    {
      question: "Data Management",
      answer: (
        <div className="faq_list">
          Effective data management is crucial to your business success. We help
          you organize, govern, and secure your data assets, ensuring compliance
          and integrity. Our solutions streamline data workflows, improve data
          quality, and enable seamless access for better decision-making.
          <ul>
            <li>Data Governance Policies</li>
            <li>Data Quality Framework</li>
            <li>Data Access Control</li>
            <li>SLA Management</li>
            <li>Data Lineage and Metadata</li>
          </ul>
        </div>
      ),
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const title = "White Box Data - Data Analytics & Infrastructure";
  const DESCRIPTION =
    "Data engineering, Business intelligence, Growth Marketing – White Box Data is your one-stop shop for data-driven solutions. We empower businesses across industries to leverage their data for increased efficiency, profitability, and growth. Explore our services and get a free assessment.";
  useEffect(() => {
    document.title = title;
  });

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonth = monthNames[new Date().getMonth()];

  return (
    <>
      {/* Landing Page */}

      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      >
        <link rel="canonical" href="https://www.whiteboxdata.com/" />
      </Helmet>

      <section className="background-image">
        <div className="background">
          <img
            className="hidden-mobile"
            src={HomePageImage}
            alt="Business Intelligence, Data Analytics and Data Engineering Consulting Services and Managed Solutions."
          ></img>
          <img
            className="visible-mobile"
            src={mobile_home}
            alt="Business Intelligence, Data Analytics and Data Engineering Consulting Services and Managed Solutions."
          ></img>
        </div>
        <div className="home-title">
          <h1>
            Build Future-Ready <br /> Business Intelligence, <br /> Data
            Analytics and <br /> Engineering Solutions
          </h1>

          <a
            href="#"
            onClick={() => setIsDataStrategyModalOpen(true)}
            className="talk-to-expert-btn home-button2"
          >
            Let's Discuss Your Project
          </a>
        </div>
      </section>

      {/* What We Do */}
      <section id="services" className="services-container">
        <h1 className="services-title">
          What <span className="text-[#ffffff]"> We Do?</span>
        </h1>

        <div className="what-we-do-section">
          <div className="what-we-do-box">
            <img src={strategic} alt="strategic_planning" loading="lazy"></img>
            <h3>Strategic Planning</h3>
            <p className="what-we-do-para">
              Balancing day-to-day operations and strategic vision can be
              challenging. We assist in creating and optimizing your North Star
              data strategy to help you reach your business metrics.
            </p>
            <p className="what-we-do-keywords">
              Data Strategy | Business Intelligence | AI Adoption | Tooling &
              Architecture | MarTech Strategy | Growth Marketing
            </p>
          </div>
          <div className="what-we-do-box">
            <img src={implementation} alt="implementation_adoption" loading="lazy"></img>
            <h3> Implementation & Adoption</h3>
            <p className="what-we-do-para">
              Allow us to build robust data analytics solutions while you
              concentrate on your core business. We provide a solid data
              foundation, latest technology, and governance processes to manage,
              visualize & analyze your data.
            </p>
            <p className="what-we-do-keywords">
              Data Platforms | Data Migration | Data Visualizations | Data
              Governance | Marketing Analytics | Cloud Services
            </p>
          </div>
          <div className="what-we-do-box">
            <img src={supportscale} alt="support_scale" loading="lazy"></img>
            <h3>Support & Scale</h3>
            <p className="what-we-do-para">
              Save cost and enhance your ROI through scalable solutions. Get the
              data expertise that you need, when you need it: Stay Relevant,
              Stay Ahead
            </p>
            <p className="what-we-do-keywords">
              Training and Development | Data Consulting | Advisory Services |
              Support & Maintenance | Data Programs
            </p>
          </div>
        </div>
        <a href="/data" className="know-more-btn mr-[1.5rem]">
          Consulting Services
        </a>
        <a href="/services" className="know-more-btn">
          Managed Services
        </a>
      </section>

      <section className="big_data_solutions_main">
        <div className="big_data_solutions_content flex flex-col md:flex-row justify-between">
          <div className="mr-0 md:mr-[5%] md:w-[70%]">
            <h2>
              Big Data Solutions, <br />
              Fractional Commitment.
            </h2>

            <p>Our Fractional Specialization</p>
            <ol>
              <li>Business Intelligence Consultant</li>
              <li>Marketing Analytics Consultant</li>
              <li>Data Governance Consultant</li>
              <li>Data Scientist</li>
              <li>ML Engineer</li>
              <li>Chief Data Officer</li>
            </ol>

            <div className="tailored_expertise_box">
              <h3>Tailored Expertise, When You Need It.</h3>
              <p>
                All our Fractional Consultants have 10+ years of Experience
                working at the best technology Companies in the US.
              </p>
              <a
                href="#"
                onClick={() => setIsDataStrategyModalOpen(true)}
                className="talk-to-expert-btn"
              >
                Talk to a Data Expert
              </a>
            </div>
          </div>
          <div className="home_big_data_blog_boxes">
            <div className="big_data_box">
              <a href="/blog/fractional-business-intelligence-consultants">
                <div className="big_data_image">
                  <img src={big_data_solutions_fractional_blog} alt=""></img>
                </div>
                <div className="big_data_title">
                  <p>Blog</p>
                  <span className="big_data_title_span">
                    Fractional Business Intelligence
                  </span>
                  <span className="big_data_title_read_more">
                    Read More &gt;&gt;
                  </span>
                </div>
              </a>
            </div>
            <div className="big_data_box">
              <a href="/blog/marketing-analytics-consulting-a-fractional-approach">
                <div className="big_data_image">
                  <img
                    src={big_data_solutions_fractional_marketing}
                    alt=""
                  ></img>
                </div>
                <div className="big_data_title">
                  <p>Blog</p>
                  <span className="big_data_title_span">
                    Fractional Marketing Analytics
                  </span>
                  <span className="big_data_title_read_more">
                    Read More &gt;&gt;
                  </span>
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="bi_casestudy_container home_case_study_box">
          <a href="/case-studies/real-time-tableau-dashboards">
            <div className="flex flex-col md:flex-row">
              <div className="consumer_finance_blog_image">
                <img
                  src={big_data_solutions_case_study}
                  className="hidden-mobile"
                  alt="big_data_solutions_case_study"
                ></img>
                <img
                  src={big_data_solutions_case_study_mobile}
                  className="visible-mobile"
                  alt="big_data_solutions_case_study_mobile"
                ></img>
              </div>
              <div className="bi_casestudy_text_div bg-white rounded-b-3xl md:rounded-r-3xl md:rounded-bl-none">
                <div>
                  <span>Case Study</span>
                  <h3>Real time Reporting & Analytics</h3>
                  <p>
                    Our Fractional BI Consultant delivered a Real Time Reporting
                    solution that Business Leaders and CxOs could rely on to
                    make operational strategic decisions. Learn how we delivered
                    a scalable solution that fit perfectly within their existing
                    data ecosystem. Read the full case study to understand our
                    approach, solution and results.
                  </p>
                </div>

                <div>
                  <a href="/case-studies/real-time-tableau-dashboards">
                    Read Case Study &gt;&gt;
                  </a>
                </div>
              </div>
            </div>
          </a>
        </div>
      </section>

      {/* WBD Insights */}
      <div className="wbdinsights-container mb-[2rem]">
        <h1 className="services-title">
          <span className="text-[#ee712b]">Insights Pro</span> - Analytics as a
          Service
        </h1>
        <div className="insights-section">
          <div className="md:flex">
            <div className="insights-quote">
              <p className="insights-text">
                Gain the competitive edge with Insights Pro, our fixed-cost
                analytics service that delivers fully customizable dashboards
                and optimized data pipelines. Stay ahead with data-driven
                decisions powered by our intuitive dashboards and robust data
                pipelines at a flat monthly cost, ensuring that your business
                insights are always clear, precise, and actionable.
              </p>
              <a href="/insights" className="know-more-btn">
                Learn More
              </a>
            </div>

            <div className="animation-video hidden md:block">
              <img
                src={insights_video}
                alt="insights_video"
                loading="lazy"
              ></img>
            </div>
          </div>

          {/* <div className="video-container">
            <div className="insights-video">
              <p className="insights-video-text">
                Making data driven decisions has never been simpler. We help you
                gain deep insights into your operational, financial and
                marketing data with our fully customized Analytics dashboards.
                Save upto 33% extra with our yearly plan or try our cancel
                anytime monthly subscription. We even offer customized package
                if you need something extra. Get in touch and schedule a free
                consultation to figure out which plan works best for you.
              </p>

             
            </div>
            <div className="animation-video">
              <img
                src={insights_video}
                alt="insights_video"
                loading="lazy"
              ></img>
            </div>
          </div> */}

          <div className="insights_pro_section">
            <h2>Get Started in 3 Simple Steps</h2>
            <div className="what-we-do-section">
              <div className="what-we-do-box">
                <h5>1</h5>
                <h3>Schedule a Free Consultation</h3>
                <p className="insights_pro_para">
                  Take the first step towards data-driven success by scheduling
                  a free consultation with our analytics experts. We’ll discuss
                  your business goals, current data challenges, and how
                  Analytics as a Service can transform your operations. Start
                  your journey to better insights today. Book your free
                  consultation now!
                </p>
              </div>
              <div className="what-we-do-box">
                <h5>2</h5>
                <h3> In-depth Discovery Session</h3>
                <p className="insights_pro_para">
                  In this personalized discovery session, we’ll dive deeper into
                  your data needs. Our team will map out the ideal dashboard and
                  data pipeline solutions tailored to your business. We ensure
                  the solution aligns with your goals, providing custom
                  analytics and seamless integrations for accurate, real-time
                  insights. Let's design the perfect solution for your data!
                </p>
              </div>
              <div className="what-we-do-box">
                <h5>3</h5>
                <h3>Implementation & Support</h3>
                <p className="insights_pro_para">
                  Once aligned, our team implements your custom analytics
                  platform, including dashboards and data pipelines. We offer
                  full support to ensure smooth onboarding and continuous
                  improvements. With Analytics as a Service, you benefit from
                  regular updates and optimizations—keeping your data actionable
                  and your business ahead of the curve. Your data, our
                  expertise—fully supported!
                </p>
              </div>
            </div>
          </div>

          <div className="video-container connectors-video-section">
            <div class="connectors-scroll">
              <ul>
                <li>
                  <img src={i1} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i2} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i3} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i4} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i5} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i6} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i7} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i8} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i9} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i10} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i11} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i12} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i13} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i14} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i15} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i16} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i17} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i18} alt="" loading="lazy"/>
                </li>
              </ul>

              <ul>
                <li>
                  <img src={i19} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i20} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i21} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i22} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i23} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i24} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i25} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i26} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i27} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i28} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i29} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i30} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i31} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i32} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i33} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i34} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i35} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i36} alt="" loading="lazy"/>
                </li>
              </ul>

              <ul>
                <li>
                  <img src={i37} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i38} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i39} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i40} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i41} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i42} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i43} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i44} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i45} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i46} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i47} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i48} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i49} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i50} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i51} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i52} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i53} alt="" loading="lazy"/>
                </li>
                <li>
                  <img src={i54} alt="" loading="lazy"/>
                </li>
              </ul>
            </div>

            <div className="insights-video insights-video-section">
              <p className="insights-animation-text">
                <span className="font-semibold">US based</span> Consultants
              </p>{" "}
              <p className="insights-animation-text">
                <span className="font-semibold">150+</span> Plug and Play Connectors
              </p>
              <p className="insights-animation-text">
                <span className="font-semibold">Insights</span> in &lt;2 Weeks
              </p>
              <p className="insights-animation-text mb-5">
                <span className="font-semibold">AI Driven</span> Insights
              </p>
              <a
            href="#"
            onClick={() => setIsInsightsModalOpen(true)}
            className="talk-to-expert-btn"
          >
            Schedule a Consultation
          </a>
            </div>
          </div>
        </div>
      </div>

      {/* Why Choose Us */}
      <div className="mt-3">
        <h1 className="services-title">
          Why <span className="text-[#ffffff]">Choose</span> Us?
        </h1>

        <div className="why-choose-us">
          <div className="choose-text-section1">
            <div className="choose-us-box">
              <h3>Enterprise Solutions with Silicon Valley Consultants</h3>
              <p>
                When working with White Box Data's Consulting team, you get to
                work with consultants from Top Tech Companies from Silicon
                Valley who have years of experience working on analytics & data
                solutions for your problems.
              </p>
            </div>
            <div className="choose-us-box">
              <h3>Vendor & Platform Agnostic</h3>
              <p>
                Unlike a lot of consulting companies out there, we do not
                partner with any vendors or platform providers. We have
                extensive experience working with a variety of Data Products &
                Tools. Hence, we are able to provide you with a solution that
                aligns with your business goals and sets you up for success.
              </p>
            </div>

            <div className="choose-us-box">
              <h3>Full Spectrum Data Services</h3>
              <p>
                We understand what makes a great data team. No data skills are
                interchangeable. We help with the exact data skills, be it
                Business Intelligence, Data Engineering, Data Science, Marketing
                Analytics, Growth Tech, Data Governance or AI Adoption.
              </p>
            </div>
          </div>

          <div className="home_faq_section">
            <h2 className="text-left">
              How We Help your Data Transformation Journey
            </h2>

            <div className="flex flex-col md:flex-row">
              <div className="w-[100%] md:w-[100%]">
                {faqs.map((faq, index) => (
                  <div key={index}>
                    <div className="faq-item">
                      <div
                        onClick={() => toggleAnswer(index)}
                        className="question-container"
                      >
                        <h3 className="faq_question">{faq.question}</h3>
                        <img
                          src={openIndex === index ? minus_icon : plusIcon}
                          alt="Toggle Answer"
                          className="plus_icon"
                          loading="lazy"
                        />
                      </div>
                      {openIndex === index && (
                        <p className="faq_answer">{faq.answer}</p>
                      )}
                    </div>

                    {index !== faqs.length - 1 && (
                      <hr className="faq-divider" />
                    )}
                  </div>
                ))}
              </div>

              <div className="faq_video">
                <img
                  src={insights_video}
                  alt="insights_video"
                  loading="lazy"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="monthly_news_container">
        <h1 className="services-title">Trends & Insights - {currentMonth}</h1>

        <div className="marketing_services_container">
          <div className="marketing_services_box">
            <a href="/blog/marketing-analytics-trends-to-watch">
              <div className="monthly_news_image">
                <img src={home_marketing_trends} alt=""></img>
              </div>
              <div className="bi_consulting_title">
                <p>Blog</p>
                <span className="">
                  Top 5 Marketing Analytics Trends to Watch in 2024
                </span>
              </div>
            </a>
          </div>
          <div className="marketing_services_box">
            <a href="/blog/business-intelligence-in-ecommerce-and-retail">
              <div className="monthly_news_image">
                <img src={bi_ecommernce_blog} alt=""></img>
              </div>
              <div className="bi_consulting_title">
                <p>Blog</p>
                <span className="">Powering E-Commerce with Business Intelligence</span>
              </div>
            </a>
          </div>

          <div className="marketing_services_box">
            <a href="/blog/real-time-marketing-analytics">
              <div className="monthly_news_image">
                <img src={marketing_real_time_marketing_analytics} alt=""></img>
              </div>
              <div className="bi_consulting_title">
                <p>Blog</p>
                <span className="">Real-Time Marketing Analytics</span>
              </div>
            </a>
          </div>
        </div>
      </section>

      <section className="home_quote-container">
        <div className="home_quote_text">
          <h5>Talk data strategy with an expert</h5>
          <p>
            Make an appointment to speak with one of our analytics specialists,
            who will assess your data challenges and assist in creating a
            roadmap for data-driven decision making.
          </p>

          <a
            href="#"
            onClick={() => setIsDataStrategyModalOpen(true)}
            className="talk-to-expert-btn"
          >
            Schedule a Consultation
          </a>
        </div>
      </section>

      <InsightsFormModal
        isOpen={isInsightsModalOpen}
        onClose={() => setIsInsightsModalOpen(false)}
      />

      <DataStrategyModal
        isOpen={isDataStrategyModalOpen}
        onClose={() => setIsDataStrategyModalOpen(false)}
      />
    </>
  );
}
