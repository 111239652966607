import React from "react";

import clsx from "clsx";
import PropTypes from "prop-types";

import Header from "../Components/Shared/Header";
import Footer from "../Components/Shared/Footer";

const Layout = ({
  children,
  id,
  header = true,
  footer = true,
  noFluid = false,
}) => (
  <div className="layout">
    {header && <Header />}
    <main
      id={id}
      className={clsx("layout-main", {
        "layout-main-no-fluid": noFluid,
      })}
    >
      {children}
    </main>
    {footer && <Footer />}
  </div>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  //   header: PropTypes.bool,
  noFluid: PropTypes.bool,
};

export default Layout;
