import React from "react";

import Layout from "../Layouts/Layout";
import ImprovingCustomerRetentionPredictiveAnalytics from "../Components/Case Studies/ImprovingCustomerRetentionPredictiveAnalytics";

export default function ImprovingCustomerRetentionPredictiveAnalyticsPage() {
  return (
    <Layout>
      <div>
        <ImprovingCustomerRetentionPredictiveAnalytics />
      </div>
    </Layout>
  );
}
