import React, { useEffect, useRef, useState } from "react";
import data_infrastructure from "../Assets/Img/data_infrastructure.png";
import etl_Automation from "../Assets/icons/ETL Automation_dataengineering.svg";
import data_warehouse from "../Assets/icons/Data Warehouse & Data Lake_dataengineering.svg";
import api_integration from "../Assets/icons/Custom API Integration_dataengineering.svg";
import cloud_implementation from "../Assets/icons/cloud Implementation_dataengineering.svg";
import data_migration from "../Assets/icons/Data Migration.svg";
import { Helmet } from "react-helmet-async";
import DataStrategyModal from "./DataStrategyModal";

import "../Styles/DataInfrastructure.css";

export default function DataInfrastructure() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const wrapperRef = useRef(null);
  const carouselRef = useRef(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const carousel = carouselRef.current;

    if (!wrapper || !carousel) return;

    const firstCardWidth = carousel.querySelector(".card").offsetWidth;
    const arrowBtns = wrapper.querySelectorAll("i");
    const carouselChildrens = [...carousel.children];

    let isDragging = false,
      isAutoPlay = true,
      startX,
      startScrollLeft,
      timeoutId;

    // Get the number of cards that can fit in the carousel at once
    let cardPerView = Math.round(carousel.offsetWidth / firstCardWidth);

    // Insert copies of the last few cards to beginning of carousel for infinite scrolling
    carouselChildrens
      .slice(-cardPerView)
      .reverse()
      .forEach((card) => {
        carousel.insertAdjacentHTML("afterbegin", card.outerHTML);
      });

    // Insert copies of the first few cards to end of carousel for infinite scrolling
    carouselChildrens.slice(0, cardPerView).forEach((card) => {
      carousel.insertAdjacentHTML("beforeend", card.outerHTML);
    });

    // Scroll the carousel at appropriate position to hide first few duplicate cards on Firefox
    carousel.classList.add("no-transition");
    carousel.scrollLeft = carousel.offsetWidth;
    carousel.classList.remove("no-transition");

    // Add event listeners for the arrow buttons to scroll the carousel left and right
    arrowBtns.forEach((btn) => {
      btn.addEventListener("click", () => {
        carousel.scrollLeft +=
          btn.id === "left" ? -firstCardWidth : firstCardWidth;
      });
    });

    const dragStart = (e) => {
      isDragging = true;
      carousel.classList.add("dragging");
      // Records the initial cursor and scroll position of the carousel
      startX = e.pageX;
      startScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e) => {
      if (!isDragging) return; // if isDragging is false return from here
      // Updates the scroll position of the carousel based on the cursor movement
      carousel.scrollLeft = startScrollLeft - (e.pageX - startX);
    };

    const dragStop = () => {
      isDragging = false;
      carousel.classList.remove("dragging");
    };

    const infiniteScroll = () => {
      // If the carousel is at the beginning, scroll to the end
      if (carousel.scrollLeft === 0) {
        carousel.classList.add("no-transition");
        carousel.scrollLeft = carousel.scrollWidth - 2 * carousel.offsetWidth;
        carousel.classList.remove("no-transition");
      }
      // If the carousel is at the end, scroll to the beginning
      else if (
        Math.ceil(carousel.scrollLeft) ===
        carousel.scrollWidth - carousel.offsetWidth
      ) {
        carousel.classList.add("no-transition");
        carousel.scrollLeft = carousel.offsetWidth;
        carousel.classList.remove("no-transition");
      }

      // Clear existing timeout & start autoplay if mouse is not hovering over carousel
      clearTimeout(timeoutId);
      if (!wrapper.matches(":hover")) autoPlay();
    };

    const autoPlay = () => {
      if (window.innerWidth < 800 || !isAutoPlay) return; // Return if window is smaller than 800 or isAutoPlay is false
      // Autoplay the carousel after every 2500 ms
      timeoutId = setTimeout(() => {
        carousel.scrollLeft += firstCardWidth;
      }, 2500);
    };
    autoPlay();

    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("mousemove", dragging);
    document.addEventListener("mouseup", dragStop);
    carousel.addEventListener("scroll", infiniteScroll);
    wrapper.addEventListener("mouseenter", () => clearTimeout(timeoutId));
    wrapper.addEventListener("mouseleave", autoPlay);

    return () => {
      // Clean up event listeners on component unmount
      carousel.removeEventListener("mousedown", dragStart);
      carousel.removeEventListener("mousemove", dragging);
      document.removeEventListener("mouseup", dragStop);
      carousel.removeEventListener("scroll", infiniteScroll);
      wrapper.removeEventListener("mouseenter", () => clearTimeout(timeoutId));
      wrapper.removeEventListener("mouseleave", autoPlay);
    };
  }, []);

  const title = "Data Engineering & Infrastructure | White Box Data";
  const DESCRIPTION =
    "Data warehousing, ETL/ELT pipelines, cloud migration, data lake implementation – White Box Data offers comprehensive data engineering and infrastructure services to streamline your data processes and drive business growth.Get started with a free assessment.";

  useEffect(() => {
    document.title = title;
  });
  return (
    <div className="datainfrastructure-container">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      >
        <title>{title}</title>
        <link
          rel="canonical"
          href="https://www.whiteboxdata.com/services/data-engineering-and-infrastructure"
        />
      </Helmet>
      <section className="data-home">
        <div className="data-home-content">
          <div className="business_landing">
            <h1>
              Data Engineering & <br /> Infrastructure
            </h1>
            <p>
              With our advanced engineering & automation processes in data
              management, we build enterprise grade solutions that helps you
              focus on getting the maximum value out of your data. From data
              warehouses, data lakes, ETL pipelines, Streaming data services to
              implementing full scale cloud solutions that best serves your
              current & future needs.
            </p>
            <a href="/contactus" className="btn growth-home-btn">
              Get In Touch
            </a>
          </div>
          <div className="wrapper data-wrapper" ref={wrapperRef}>
            <i id="left" className="fa-solid fa-angle-left"></i>
            <ul className="carousel" ref={carouselRef}>
              <li className="card">
                <h1>250+ </h1>
                <h2>Hours Saved Monthly</h2>
                <p>ETL Automation</p>
              </li>

              <li className="card">
                <h1>99%+</h1>
                <h2>Data Accuracy </h2>
                <p>Data warehouse</p>
              </li>
              <li className="card">
                <h1>$650k</h1>
                <h2>Saved</h2>
                <p>Custom Infrastructure</p>
              </li>
            </ul>
            <i id="right" className="fa-solid fa-angle-right"></i>
          </div>
        </div>

        <div className="data-image">
          <img src={data_infrastructure} alt="Data Infrastructure image"></img>
        </div>
      </section>
      <h1 className="icon-box-section-h1">Our Data Engineering Services</h1>

      <section>
        <div className="data-driven-insights-section services_boxes_section_padding">
          <div className="data-driven-insights-box bi_consulting_img_height">
            <div className="bi-infra-img">
              <img
                className="etl_Automation"
                src={etl_Automation}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <h3>ETL Automation</h3>
            <p>
              Streamline your data pipeline and eliminate manual errors with our
              ETL automation solutions. Extract, transform, and load your data
              seamlessly across various sources, ensuring data integrity and
              accelerating time-to-insight. Automate repetitive tasks, reduce
              operational costs, and free your team to focus on higher-value
              activities. Our customized ETL solutions cater to your unique
              business requirements, providing scalability, flexibility, and
              efficiency.
            </p>
          </div>
          <div className="data-driven-insights-box">
            <div className="bi-infra-img">
              <img
                className="data_warehouse"
                src={data_warehouse}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <h3>Data Warehouse & Data Lake</h3>
            <p>
              Build a robust foundation for your data-driven initiatives with
              our data warehouse and data lake solutions. Centralize, manage,
              and analyze structured and unstructured data at scale, enabling
              faster access and deeper insights. Our data warehouse expertise
              ensures optimal performance, scalability, and security for your
              critical business data. With a data lake, harness the power of all
              your data, including raw, unprocessed information, for advanced
              analytics, machine learning, and data discovery.
            </p>
          </div>
          <div className="data-driven-insights-box">
            <div className="bi-infra-img">
              <img
                className="api_integration"
                src={api_integration}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <h3>Custom API Integration</h3>
            <p>
              Seamlessly connect disparate systems and applications with our
              custom API integration services. Unlock the full potential of your
              data by enabling real-time data exchange and communication between
              different platforms. Our experts design, develop, and deploy
              custom APIs that meet your specific business needs, ensuring
              seamless integration and interoperability. Streamline workflows,
              automate processes, and enhance data accessibility with our
              reliable API solutions.
            </p>
          </div>
        </div>
      </section>

      <section className="mt-[-2%]">
        <div className="data-driven-insights-section services_boxes_section_padding">
          <div className="data-driven-insights-box">
            <div className="bi-infra-img">
              <img
                className="cloud_implementation"
                src={cloud_implementation}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <h3>Cloud Implementation</h3>
            <p>
              Migrate your data infrastructure to the cloud with confidence. Our
              cloud implementation experts help you choose the right platform
              (AWS, GCS, or Azure) and seamlessly transition your data,
              applications, and workloads. Leverage the benefits of scalability,
              flexibility, cost-efficiency, and improved performance with our
              customized cloud solutions. Whether you're starting from scratch
              or looking to optimize your existing cloud environment, we guide
              you through every step of the process.
            </p>
          </div>
          <div className="data-driven-insights-box">
            <div className="bi-infra-img">
              <img
                className="data_migration"
                src={data_migration}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <h3>Data Migration</h3>
            <p>
              Move your data safely and efficiently with our expert data
              migration services. Whether you're upgrading systems,
              consolidating data sources, or transitioning to a new platform, we
              ensure a smooth and seamless migration process. Our comprehensive
              approach includes data assessment, planning, extraction,
              transformation, loading, and validation, minimizing downtime and
              ensuring data integrity. Trust our experienced team to handle your
              data migration with precision and care.
            </p>
          </div>
          <div className="data-driven-insights-box flex items-center bg-[#b1ceef]">
            <div className="services_quote_text ">
              <h2>Looking for a custom solution?</h2>

              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
                className="talk-to-expert-btn services_button"
              >
                Request a Consultation
              </a>
            </div>
          </div>
        </div>
      </section>

    <a href="/contactus">
        <button className="fixed-button">Request a Consultation</button>
      </a>
      <DataStrategyModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}
