import React from "react";

import Layout from "../Layouts/Layout";
import BusinessIntelligence from "../Components/BusinessIntelligence";

export default function BusinessIntelligencePage() {
  return (
    <Layout>
      <div>
        <BusinessIntelligence />
      </div>
    </Layout>
  );
}
