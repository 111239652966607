import React, {useEffect} from "react";
import DataDrivenTransformationInner from "../../Assets/Img/DataDrivenTransformationInner.png";

import { Helmet } from "react-helmet-async";

import "../../Styles/CustomerEngagementRevenue.css";

export default function DataDrivenTransformation() {
  const title =
    "Business Intelligence Driven Analytics Transformation | White Box Data";
  const DESCRIPTION =
    "Partner with White Box Data for strategic data consulting. Our expertise in data strategy, cloud solutions and managed BI, Analytics & Data Engineering services unlocks your data's full potential for business growth.";

  useEffect(() => {
    document.title = title;
  });
  return (
    <div className="case-study-main-container">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      ></Helmet>

      <section className="fixed-blog-box">
        <div>
          <p>Get your insights now with White Box Data</p>
        </div>

        <div className="blog-getintouch">
          <a href="/contactus">Get in touch</a>
        </div>
      </section>

      <section className="case-study-content">
        <img
          src={DataDrivenTransformationInner}
          alt=""
          className="case_studies-image"
        ></img>

        <h1>Business Intelligence Driven Analytics Transformation</h1>

        <div className="industry-technology-block">
          <div className="industry-block">
            <h3>Industry</h3>
            <span className="industry-text">
              Lending Services, Consumer Lending, Personal Loans, Credit Scores,
              Micro-Financing, Savings
            </span>
          </div>

          <div className="technology-block">
            <h3>Technology & Tools Stack</h3>
            <span className="industry-text">
              AWS Redshift, AWS S3, EC2, Custom API, Tableau, Python, Airflow,
              SQL
            </span>
          </div>
        </div>

        <h2>Customer</h2>

        <p className="case-study-content-p">A US financial lending company</p>

        <h2>Challenge</h2>
        <p className="case-study-content-p">
          In the turbulent economic landscape of 2020, a consumer financial
          services product company faced a pressing need to cut marketing costs
          while maintaining growth. Their heavy reliance on paid marketing and
          external analytics tools was proving unsustainable.
        </p>

        <h2>Solution</h2>

        <p className="case-study-content-p">
          <strong>Custom Analytics Framework - </strong> We developed a bespoke
          internal analytics system, providing a granular view of business
          performance across verticals. This replaced reliance on costly
          external tools, saving $650K in the first 60 days alone.
        </p>
        <p className="case-study-content-p">
          <strong>Organic Growth Engine - </strong>
          This helped the stakeholders understand the breadth of insights that
          could be achieved with the existing data and the additional effort to
          capture & maintain high quality data for future traffic & engagement.
        </p>
        <p className="case-study-content-p">
          <strong>Internal Success Signals - </strong>
          Our business intelligence & data engineers built ETL processes to
          track and analyze internal conversions, providing crucial feedback
          loops for campaign optimization.
        </p>
        <p className="case-study-content-p">
          <strong>Dormant User Reactivation - </strong>
          In-depth analysis of organic and direct traffic uncovered
          opportunities to re-engage dormant and churned users, improving
          retention by 19% and 27% respectively.
        </p>
        <p className="case-study-content-p">
          <strong>Custom Channel Attribution - </strong>
          Implementing multi-touch and last touch attribution models, with time
          decay and mix modeling, revealed hidden insights into the true value
          of SEO and organic channels.
        </p>

        <p className="case-study-content-p">
          <strong>High-Quality Content Strategy - </strong>We crafted compelling
          organic content to boost brand awareness and attract new customers,
          contributing to improved SEO and direct traffic.{" "}
        </p>

        <h2>Results</h2>
        <span className="results-span">
          <strong>Cost Savings</strong>
        </span>
        <ul className="case-study-ul-list">
          <li>Total savings of $2.2M in the first fiscal year.</li>
        </ul>

        <span className="results-span">
          <strong>Organic Growth</strong>
        </span>
        <ul className="case-study-ul-list">
          <li>
            SEO and direct traffic revenue matched previous levels achieved
            through paid marketing.
          </li>
        </ul>

        <span className="results-span">
          <strong>Customer Retention</strong>
        </span>
        <ul className="case-study-ul-list">
          <li>Overall retention soared by 127% YoY.</li>
        </ul>

        <span className="results-span">
          <strong>New Members</strong>
        </span>
        <ul className="case-study-ul-list">
          <li>Acquired 3 million new matched members while maintaining ARR.</li>
        </ul>

        <h2>Technology Stack</h2>

        <p className="case-study-content-p">
          AWS Redshift, AWS S3, EC2, Custom API, Tableau, Python, Airflow, SQL
        </p>
      </section>

      <a href="/contactus">
        <button className="fixed-button">Request a Consultation</button>
      </a>
    </div>
  );
}
