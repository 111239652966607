import React from "react";

import Layout from "../Layouts/Layout";
import Blog from "../Components/Blog";

export default function BlogPage() {
  return (
    <Layout>
      <div>
        <Blog />
      </div>
    </Layout>
  );
}
