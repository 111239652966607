import React, {useEffect} from "react";
import CustomerEngagementRevenueinner from "../../Assets/Img/CustomerEngagementRevenue.png";
import CustomerEngagementRevenueinner2 from "../../Assets/Img/CustomerEngagementRevenueinner2.png";

import { Helmet } from "react-helmet-async";

import "../../Styles/CustomerEngagementRevenue.css";

export default function CustomerEngagementRevenue() {
  const title =
    "Data Analytics Solution for a 360 Channel Attribution View for Customer Engagement & Revenue | White Box Data";
  const DESCRIPTION =
    "Learn how a financial services company leveraged BigQuery, Looker, Python, and Airflow to gain actionable marketing insights, improve ROI, and drive revenue growth. A Whitebox Data case study.";

  useEffect(() => {
    document.title = title;
  });

  return (
    <div className="case-study-main-container">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      ></Helmet>

      <section className="fixed-blog-box">
        <div>
          <p>Get your insights now with White Box Data</p>
        </div>

        <div className="blog-getintouch">
          <a href="/contactus">Get in touch</a>
        </div>
      </section>

      <section className="case-study-content">
        <img
          src={CustomerEngagementRevenueinner}
          alt=""
          className="case_studies-image"
        ></img>

        <h1>
          Data Analytics Solution for a 360 Channel Attribution View for
          Customer Engagement & Revenue
        </h1>

        <div className="industry-technology-block">
          <div className="industry-block">
            <h3>Industry</h3>
            <span className="industry-text">
              Financial Services, Consumer Finance, Lending, Credit,
              Refinancing, Mortgage{" "}
            </span>
          </div>

          <div className="technology-block">
            <h3>Technology & Tools Stack</h3>
            <span className="industry-text">
              BigQuery, Looker, Python, Airflow
            </span>
          </div>
        </div>

        <h2>Customer</h2>

        <p className="case-study-content-p">A major US financial consumer product company</p>

        <h2>Challenge</h2>
        <p className="case-study-content-p">
          The client wanted actionable insights into conversions & revenue from
          different marketing channels. However, they had historically relied on
          out of the box reporting generated from third party ad platforms to
          get conversions & revenue for paid initiatives. This had led the
          organic teams to set up their own adhoc reporting and rely on ad
          platforms to figure out performance for any new campaigns or past
          performance. It would even involve a bit of guesswork and assumptions
          to arrive at the results.
        </p>

        <h2>Solution</h2>
        <p className="case-study-content-p">
          <strong>Consulting & Proof of Concept - </strong> Our consultants
          conducted in-depth research and interviews with the decision makers to
          understand the use of the current system and identify gaps &
          inefficiencies. We enabled the client to get retroactive performance
          insights into their previous 3 years of data. Data aggregation was
          done with Adhoc ETL & ELT processes from various sources including Ad
          Platforms, Website, GCP containers, Affiliate partners FTP servers and
          internal CRM & Email Marketing tools.
        </p>
        <p className="case-study-content-p">
          This helped the stakeholders understand the breadth of insights that
          could be achieved with the existing data and the additional effort to
          capture & maintain high quality data for future traffic & engagement.
        </p>

        <p className="case-study-content-p">
          <strong>Implementation of Data Analytics Ecosystem - </strong>We
          implemented a multi-faceted solution that combined the power of GCP
          containers, BigQuery, Looker, Python, and Airflow. The entire
          implementation was done completely behind the customer’s firewall to
          maintain the sanctity & security of their data.
        </p>

        <ol>
          <li>
            <strong>Centralized Data Warehouse (BigQuery):</strong>

            <ul className="case-study-li-ul">
              <li>
                We consolidated all marketing data from various sources (ad
                platforms, website analytics, CRM, etc.) into a unified BigQuery
                data warehouse. This eliminated data silos and provided a single
                source of truth for analysis.
              </li>
              <li>
                We developed a robust data pipeline using Airflow to automate
                data extraction, transformation, and loading (ETL) processes.
                This ensured that data was always up-to-date and accurate.
              </li>
            </ul>
          </li>
          <li>
            <strong>Custom Attribution Model (SQL, Python):</strong>

            <ul className="case-study-li-ul">
              <li>
                Recognizing the limitations of last-click attribution models, we
                built a custom attribution model. This model used advanced
                statistical techniques to accurately assign credit to different
                touchpoints throughout the customer journey, revealing the true
                impact of each marketing channel.
              </li>
            </ul>
          </li>
          <li>
            <strong>Interactive Dashboards (Looker):</strong>

            <ul className="case-study-li-ul">
              <li>
                We created intuitive, interactive dashboards in Looker that
                provided stakeholders with real-time visibility into key
                marketing metrics.
              </li>
              <li>
                Dashboards were tailored to different teams (Paid Marketing,
                Analytics, SEO, Email & Product) to ensure that each user had
                access to the most relevant insights for their role.
              </li>
              <li>
                We implemented drill-down capabilities to enable deeper analysis
                of specific campaigns or channels.
              </li>
            </ul>
          </li>
          <li>
            <strong>Data Quality Alerting Framework (Python)</strong>

            <ul className="case-study-li-ul">
              <li>
                Implementation of stern data quality checks for most important
                KPIs making sure relevant teams are made aware and proactive
                measure can be taken.
              </li>
            </ul>
          </li>
        </ol>

        <img
          src={CustomerEngagementRevenueinner2}
          alt=""
          className="case_studies-image2"
        ></img>

        <h2>Results</h2>
        <p className="results-span">
          <strong>By having a Data Warehouse, customer was able to</strong>
        </p>
        <ul className="case-study-ul-list">
          <li>Manage their user data to drive insights</li>
          <li>100x faster insights for key metrics</li>
          <li>Could use the data directly for forecasting models</li>
          <li>Created a source of truth for all their key metrics</li>
        </ul>

        <p className="results-span">
          <strong>Channel Attribution</strong>
        </p>
        <ul className="case-study-ul-list">
          <li>Reduce marketing spend by 6% within the first month</li>
          <li>True conversion rate value for each marketing channel</li>
          <li>
            100% reporting accuracy. No multi counting or overreporting of
            metrics between ad-campaigns
          </li>
          <li>Company wide used model across different teams</li>
        </ul>

        <p className="results-span">
          <strong>Dashboards</strong>
        </p>
        <ul className="case-study-ul-list">
          <li>
            Personalized insights across the organization for key decision
            makers
          </li>
        </ul>

        <span className="results-span">
          <strong>Data Quality Framework</strong>
        </span>
        <ul className="case-study-ul-list">
          <li>Helped implement SLA for critical reporting</li>
          <li>95% SLA achieved within Month 1</li>
          <li>99.99% SLA achieved within Month 6</li>
        </ul>

        <h2>Technology Stack</h2>

        <p className="case-study-content-p">
          BigQuery, GCP Containers, SQL ETLs, Python, AWS S3, Airflow, Python
          ETLs, Python Scripts, Looker, Google Analytics
        </p>
      </section>

      <a href="/contactus">
        <button className="fixed-button">Request a Consultation</button>
      </a>
    </div>
  );
}
