import React from "react";
import logo from "../../Assets/icons/logo.webp";
import india from "../../Assets/icons/india.svg";
import usa from "../../Assets/icons/usa.svg";
import gmail from "../../Assets/icons/email.svg";
import linkedin from "../../Assets/icons/linkedin.svg";
import insta from "../../Assets/icons/insta.svg";
import twi from "../../Assets/icons/twiter.svg";

import "../../Styles/Footer.css";

export default function Footer() {
  return (
    <div className="footer-main">
      <div>
        <a href="/home" aria-label="Whiteboxdata logo">
          <img className="footer-logo" src={logo} alt=""></img>
        </a>

        <p className="footer-logo-content">
          Unlock the full potential of your data. Our data-driven solutions
          deliver measurable results. <br />
          Get a free data consultation and discover how we can transform your
          business.
        </p>
      </div>
      <div className="links-div">
        <p class="connect-links ">Connect with us!</p>
        <div className="contact-info">
          {/* <div>
            <img className="usa-image absolute" src={usa} alt=""></img>

            <p>+1(408)805-9626</p>
          </div>

          <div>
            <img className="india-image absolute" src={india} alt=""></img>

            <p>+91-7990527375</p>
          </div> */}

          <div>
            <img className="gmail-image absolute" src={gmail} alt=""></img>
            <a href="mailto:consulting@whiteboxdata.com">
              <p>consulting@whiteboxdata.com</p>
            </a>
          </div>
        </div>
      </div>

      <div className="social-links">
        <a
          href="https://www.linkedin.com/company/white-box-data/"
          aria-label="Linkedin handle of White box data"
        >
          <img className="linkedin-icon" src={linkedin} alt=""></img>
        </a>
        <a
          href="https://www.instagram.com/whiteboxdata/"
          aria-label="Instagram handle of White box data"
        >
          <img className="insta-icon" src={insta} alt=""></img>
        </a>
        <a
          href="https://x.com/whiteboxdata"
          aria-label="Twitter handle of White box data"
        >
          <img className="twitter-icon" src={twi} alt=""></img>
        </a>
      </div>
      <div className="links-container">
        <p className="quick-links-text"> Quick Links</p>
        <div className="quick-links ">
          <a href="/services">
            <u>Our Services</u>
          </a>

          <a href="/contactus">
            <u>Contact Us</u>
          </a>

          <a href="/aboutus">
            <u>About Us</u>
          </a>

          <a href="/blog">
            <u>Blog</u>
          </a>

          <a href="/privacy-policy">
            <u>Privacy Policy</u>
          </a>

          <a href="/aboutus#careers">
            <u>Careers</u>
          </a>
        </div>
        <div>
          <p className="copyright-text">
            © 2024 White Box Data. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}
