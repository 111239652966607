import React from "react";

import Services from "../Components/Services";
import Layout from "../Layouts/Layout";

export default function ServicesPage() {
  return (
    <Layout>
      <div>
        <Services />
      </div>
    </Layout>
  );
}
