import React, { useState, useEffect } from "react";
import rate_optimization from "../Assets/icons/rate_optimization.svg";
import data_visualization from "../Assets/icons/data_visualization.svg";
import api_integration from "../Assets/icons/Custom API Integration.svg";
import comma from "../Assets/Img/inverted comma.svg";
import DataStrategyModal from "./DataStrategyModal";
import { Helmet } from "react-helmet-async";

import "../Styles/Services.css";
export default function Services() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const title = "Services | White Box Data";
  const DESCRIPTION =
    "White Box Data is a leading Data Analytics & Strategic Consulting firm specializing in Business Intelligence, Data Engineering, Marketing Analytics, and AI-driven solutions. Partner with us to elevate your data strategy and achieve measurable results.";
  useEffect(() => {
    document.title = title;
  });
  return (
    <div className="services-container-main">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      >
        <link rel="canonical" href="https://www.whiteboxdata.com/services" />

      </Helmet>

      <section className="services-container-landing">
        <div className="services-container-landing1">
          <h1>
            Business Intelligence, Marketing Analytics <br />& Data Engineering
            Services
          </h1>
          <p>
            White Box Data is a full-service Data Analytics solutions &
            consulting partner. We help set your organization on a successful
            data transformation journey. We implement the best solutions that
            helps you manage, analyze and gain insights out of your data to
            empower Growth.
          </p>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setIsModalOpen(true);
            }}
            className="talk-to-expert-btn consult-services"
          >
            Consult with Our Expert
          </a>
        </div>

        <div className="services-container-landing2">
          <div className="consulting-services">
            <div className="consulting-heading">
              <div>
                <h2> Strategic Data Consulting</h2>
              </div>
              <div>
                <a href="/data" className="know-more-btn button-outline">
                  Learn More
                </a>
              </div>
            </div>
            <div className="consulting-keywords">
              <div className="keywords">
                <span>Business Intelligence</span>
                <span>Data Governance</span>
                <span>ETL Automation</span>
                <span>Data Engineering</span>
              </div>

              <div className="keywords">
                <span>Managed Data Services</span>
                <span>Growth Marketing</span>
                <span>Data Infrastructure</span>
                <span>Generative AI</span>
              </div>

              <div className="keywords">
                <span>Customer Data Platform</span>
                <span>Cloud Implementation</span>
                <span>Customer Analytics</span>
                <span>Marketing Analytics</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="offer-section">
        <h2 className="we-offer-h2">
          What we Offer
        </h2>
        <div className="what-we-offer">
          <div className="growth-box">
            <div className="rate_optimization_services">
              <img src={rate_optimization} alt="growth_marketing" loading="lazy"></img>
            </div>
            <div className="offer-box">
              <h4>Growth & Marketing Technology</h4>
              <div className="subservices-div">
                <h5>Paid Marketing</h5>
                <p>
                  From DV360 to Youtube Ads, Search Ads to Social Media content,
                  we manage your ad platforms to improve your Conversions, ROI &
                  Brand.
                </p>
              </div>

              <div className="subservices-div">
                <h5>Social Media Marketing</h5>
                <p>
                  Design bespoke creatives to increase engagement rate with your
                  existing & new customers.
                </p>
              </div>

              <div className="subservices-div">
                <h5>GA4</h5>
                <p>
                  Transform your raw impressions & clicks into meaningful
                  insights. Use our enterprise GA4 Audit, Implementation &
                  Migration services.
                </p>
              </div>

              <div className="subservices-div">
                <h5>Conversion Rate Optimization</h5>
                <p>
                  Increase your conversion rate with analytics services
                  customized for your sucess.
                </p>
              </div>

              <div className="subservices-div">
                <h5>SEO, SEM & Brand</h5>
                <p>
                  Get better visibility and brand awareness with our
                  personalized organic and paid search services.
                </p>
              </div>

              <div className="mt-[1.5rem] subservices-learn-more">
                <a
                  href="/services/growth-and-marketing-technology"
                  className="know-more-btn"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="growth-box">
            <div className="data_visualization_services">
              <img src={data_visualization} alt="business intelligence" loading="lazy"></img>
            </div>
            <div className="offer-box">
              <h4>Business Intelligence & Analytics</h4>

              <div className="subservices-div">
                <h5>Channel Attribution</h5>
                <p>
                  Don't rely on broken third party attribution metrics.
                  Implement a custom accurate attribution model that defines
                  success for you.
                </p>
              </div>

              <div className="subservices-div">
                <h5>Customer Analytics</h5>
                <p>
                  Retain and reward your best customers with personalized
                  content & experiences. Know your customers with data driven
                  insights.
                </p>
              </div>

              <div className="subservices-div">
                <h5>Data Visualization & Reporting</h5>
                <p>
                  We use state of the art BI tools to provide you with highly
                  customized dashboards that cater to a variety of audiences.
                </p>
              </div>
              <div className="subservices-div">
                <h5>Web Analytics</h5>
                <p>
                  Optimize your funnel by getting actionable insights into your
                  customer journey.
                </p>
              </div>
              <div className="subservices-div">
                <h5>Data Governance & Security</h5>
                <p>
                  We implement data governance and quality processes that helps
                  you stay on top your data.
                </p>
              </div>
              <div className="mt-[1.5rem] subservices-learn-more">
                <a
                  href="/services/business-intelligence-and-analytics"
                  className="know-more-btn"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          <div className="growth-box">
            <div className="api_integration_services">
              <img src={api_integration} alt="data engineering" loading="lazy"></img>
            </div>
            <div className="offer-box">
              <h4>Data Engineering & Infrastructure</h4>

              <div className="subservices-div">
                <h5>ETL Automation</h5>
                <p>
                  Gather data from disparate sources and build a scalable and
                  self service data process system for all your data needs.
                </p>
              </div>

              <div className="subservices-div">
                <h5>Data Warehouse & Data Lakes</h5>
                <p>
                  Creating an enterprise level data warehouse or a small data
                  lake, we help you build scalable data solutions for the
                  future.
                </p>
              </div>

              <div className="subservices-div">
                <h5>Custom API Integration</h5>
                <p>
                  Tired of waiting to get critical data on time? We create
                  custom API solutions that reduces wait time and manual
                  interventions.
                </p>
              </div>

              <div className="subservices-div">
                <h5>Cloud Implementation</h5>
                <p>
                  We help you onboard and implement any of the leading cloud
                  services like AWS, GCP or Azure.
                </p>
              </div>

              <div className="subservices-div">
                <h5>Data Migration</h5>
                <p>
                  Migrate data between different systems with 99.9% accuracy.
                  Consult with our experts today.
                </p>
              </div>
              <div className="mt-[1.5rem] subservices-learn-more">
                <a
                  href="/services/data-engineering-and-infrastructure"
                  className="know-more-btn"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="outcomes-you-can-expect">
        <h2>
          Outcomes you can expect
        </h2>
        <div className="outcomes-expect">
          <div className="outcomes-box">
            <h5>Strategic Decision-Making</h5>
            <p>
              Empower your organization with precise and actionable insights.
              Our Business Intelligence, Marketing Analytics & Data Engineering
              solutions enable informed decision-making, aligning your team's
              efforts with your strategic objectives and enhancing your business
              performance.
            </p>
          </div>
          <div className="outcomes-box">
            <h5>Streamlined Data Operations</h5>
            <p>
              Transform your data environment to reduce dependency on manual
              processes and lower technology costs. By optimizing your data
              infrastructure, we ensure your data teams can focus on strategic,
              high-value tasks, driving innovation and efficiency.
            </p>
          </div>
        </div>
        <div className="outcomes-expect">
          <div className="outcomes-box">
            <h5>Comprehensive Customer Insights</h5>
            <p>
              Achieve a 360-degree view of your customers, allowing for deeper
              understanding of their preferences and behaviors. This data-driven
              approach enhances customer experience, minimizes risks, and boosts
              revenue through personalized interactions and targeted marketing.
            </p>
          </div>
          <div className="outcomes-box">
            <h5>Robust Data Governance</h5>
            <p>
              Stay prepared for growth opportunities such as mergers,
              acquisitions, and expansions with our proactive data governance
              solutions. We help you manage and mitigate risks, ensuring
              compliance and readiness for any business transformation.
            </p>
          </div>
        </div>
      </section>

      <section>
        <h2>
          What Our Clients Say
        </h2>
        <div className="what-they-say">
          <div className="what-they-say-box">
            <img className="services-comma" src={comma} alt="comma" loading="lazy"></img>
            <p className="what-they-say-text">
              "White Box Data's  business intelligence and analytics services
              have been a game-changer for us. Implementing a custom attribution
              model and providing detailed customer analytics has enabled us to
              retain and reward our best customers effectively.”
            </p>
            <p className="services-name">
              -Brian M. - Senior Director, Marketing
            </p>
          </div>
          <div className="what-they-say-box">
            <img className="services-comma" src={comma} alt="comma" loading="lazy"></img>
            <p className="what-they-say-text">
               "Our metrics & reporting accuracy have been greatly streamlined
              with the new Data Warehouse solution. They have become our go to
              team for anything BI or Data Engineering."
            </p>
            <p className="services-name">
              -Robert McShane - Head of BI & Data Engineering
            </p>
          </div>
          <div className="what-they-say-box">
            <img className="services-comma" src={comma} alt="comma" loading="lazy"></img>
            <p className="what-they-say-text">
              “We couldn't be happier! One of the best teams to work with. They
              are proactive and are a great asset to have for your Data needs."
            </p>
            <p className="services-name">-Monica C. - Head of Organic Search</p>
          </div>
        </div>
      </section>

      <section className="data-strategy-expert">
        <h5>Talk data strategy with an expert</h5>
        <p>
          Make an appointment to speak with one of our analytics specialists,
          who will assess your data challenges and assist in creating a roadmap
          for data-driven decision making.
        </p>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setIsModalOpen(true);
          }}
          className="talk-to-expert-btn mb-[10%]"
        >
          Schedule a Session
        </a>
      </section>

      <DataStrategyModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}
