import React from "react";

import Layout from "../Layouts/Layout";
import ScalableDataEngineeringSolution from "../Components/Case Studies/ScalableDataEngineeringSolution";

export default function ScalableDataEngineeringSolutionPage() {
  return (
    <Layout>
      <div>
        <ScalableDataEngineeringSolution />
      </div>
    </Layout>
  );
}
