import React, { useEffect, useRef, useState } from "react";
import business_intelligence from "../Assets/Img/business_intelligence.png";
import arrow_up from "../Assets/icons/arrow_up.svg";
import channel_attribution from "../Assets/icons/Channel Attribution_BI.svg";
import customer_analytics from "../Assets/icons/Customer Analytics_BI.svg";
import data_governance from "../Assets/icons/Data Governance & Security_BI.svg";
import data_visualization from "../Assets/icons/Data Visualization & Reporting_BI.svg";
import web_analytics from "../Assets/icons/Web Analytics_BI.svg";
import quote_box from "../Assets/Img/quote-box.svg";
import { Helmet } from "react-helmet-async";
import DataStrategyModal from "./DataStrategyModal";

import "../Styles/BusinessIntelligence.css";

export default function BusinessIntelligence() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const wrapperRef = useRef(null);
  const carouselRef = useRef(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const carousel = carouselRef.current;

    if (!wrapper || !carousel) return;

    const firstCardWidth = carousel.querySelector(".card").offsetWidth;
    const arrowBtns = wrapper.querySelectorAll("i");
    const carouselChildrens = [...carousel.children];

    let isDragging = false,
      isAutoPlay = true,
      startX,
      startScrollLeft,
      timeoutId;

    // Get the number of cards that can fit in the carousel at once
    let cardPerView = Math.round(carousel.offsetWidth / firstCardWidth);

    // Insert copies of the last few cards to beginning of carousel for infinite scrolling
    carouselChildrens
      .slice(-cardPerView)
      .reverse()
      .forEach((card) => {
        carousel.insertAdjacentHTML("afterbegin", card.outerHTML);
      });

    // Insert copies of the first few cards to end of carousel for infinite scrolling
    carouselChildrens.slice(0, cardPerView).forEach((card) => {
      carousel.insertAdjacentHTML("beforeend", card.outerHTML);
    });

    // Scroll the carousel at appropriate position to hide first few duplicate cards on Firefox
    carousel.classList.add("no-transition");
    carousel.scrollLeft = carousel.offsetWidth;
    carousel.classList.remove("no-transition");

    // Add event listeners for the arrow buttons to scroll the carousel left and right
    arrowBtns.forEach((btn) => {
      btn.addEventListener("click", () => {
        carousel.scrollLeft +=
          btn.id === "left" ? -firstCardWidth : firstCardWidth;
      });
    });

    const dragStart = (e) => {
      isDragging = true;
      carousel.classList.add("dragging");
      // Records the initial cursor and scroll position of the carousel
      startX = e.pageX;
      startScrollLeft = carousel.scrollLeft;
    };

    const dragging = (e) => {
      if (!isDragging) return; // if isDragging is false return from here
      // Updates the scroll position of the carousel based on the cursor movement
      carousel.scrollLeft = startScrollLeft - (e.pageX - startX);
    };

    const dragStop = () => {
      isDragging = false;
      carousel.classList.remove("dragging");
    };

    const infiniteScroll = () => {
      // If the carousel is at the beginning, scroll to the end
      if (carousel.scrollLeft === 0) {
        carousel.classList.add("no-transition");
        carousel.scrollLeft = carousel.scrollWidth - 2 * carousel.offsetWidth;
        carousel.classList.remove("no-transition");
      }
      // If the carousel is at the end, scroll to the beginning
      else if (
        Math.ceil(carousel.scrollLeft) ===
        carousel.scrollWidth - carousel.offsetWidth
      ) {
        carousel.classList.add("no-transition");
        carousel.scrollLeft = carousel.offsetWidth;
        carousel.classList.remove("no-transition");
      }

      // Clear existing timeout & start autoplay if mouse is not hovering over carousel
      clearTimeout(timeoutId);
      if (!wrapper.matches(":hover")) autoPlay();
    };

    const autoPlay = () => {
      if (window.innerWidth < 800 || !isAutoPlay) return; // Return if window is smaller than 800 or isAutoPlay is false
      // Autoplay the carousel after every 2500 ms
      timeoutId = setTimeout(() => {
        carousel.scrollLeft += firstCardWidth;
      }, 2500);
    };
    autoPlay();

    carousel.addEventListener("mousedown", dragStart);
    carousel.addEventListener("mousemove", dragging);
    document.addEventListener("mouseup", dragStop);
    carousel.addEventListener("scroll", infiniteScroll);
    wrapper.addEventListener("mouseenter", () => clearTimeout(timeoutId));
    wrapper.addEventListener("mouseleave", autoPlay);

    return () => {
      // Clean up event listeners on component unmount
      carousel.removeEventListener("mousedown", dragStart);
      carousel.removeEventListener("mousemove", dragging);
      document.removeEventListener("mouseup", dragStop);
      carousel.removeEventListener("scroll", infiniteScroll);
      wrapper.removeEventListener("mouseenter", () => clearTimeout(timeoutId));
      wrapper.removeEventListener("mouseleave", autoPlay);
    };
  }, []);

  const title = "Business Intelligence & Analytics | White Box Data";
  const DESCRIPTION =
    "Unlock the full potential of your data with White Box Data's comprehensive Business Intelligence & Analytics solutions. Our experienced consultants deliver tailored strategies to help you achieve your business goals. Contact us now!";
  useEffect(() => {
    document.title = title;
  });
  return (
    <div className="businessintelligence-container">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      >
        <title>{title}</title>
        <link
          rel="canonical"
          href="https://www.whiteboxdata.com/services/business-intelligence-and-analytics"
        />
      </Helmet>
      <section className="business-home">
        <div className="business-home-content">
          <div className="business_landing">
            <h1>
              Business Intelligence & <br /> Analytics
            </h1>
            <p>
              When it comes to Analytics, one size NEVER fits all. Transform the
              effectiveness and efficiency of your data with a strategic
              approach that has a positive impact at all levels of your
              organization. We help you build a sustainable and data driven
              culture of the future.
            </p>
            <a href="/contactus" className="btn growth-home-btn">
              Get In Touch
            </a>
          </div>
          <div>
            <div className="wrapper business-wrapper" ref={wrapperRef}>
              <i id="left" className="fa-solid fa-angle-left"></i>
              <ul className="carousel" ref={carouselRef}>
                <li className="card">
                  <div className="arrow_up_growth">
                    <h1>55% </h1>
                    <img
                      className="arrow_down"
                      src={arrow_up}
                      alt="Arrow down"
                      loading="lazy"
                    ></img>
                  </div>
                  <h2>
                    Resource & <br /> Infrastructure Cost
                  </h2>
                  <p className="business_slider_p">Business Intelligence</p>
                </li>

                <li className="card">
                  <div className="arrow_up_growth">
                    <h1>37%</h1>
                    <img
                      className="arrow_up"
                      src={arrow_up}
                      alt="Arrow up"
                      loading="lazy"
                    ></img>
                  </div>
                  <h2>
                    Email <br /> Engagement{" "}
                  </h2>
                  <p className="business_slider_p">Channel Attribution</p>
                </li>
                <li className="card">
                  <div className="arrow_up_growth">
                    <h1>&lt;35%</h1>
                  </div>
                  <h2>
                    Bounce <br /> Rate{" "}
                  </h2>
                  <p className="business_slider_p">Funnel Optimization</p>
                </li>
                <li className="card">
                  <div className="arrow_up_growth">
                    <h1>80%</h1>

                    <img
                      className="arrow_down"
                      src={arrow_up}
                      alt="Arrow up"
                      loading="lazy"
                    ></img>
                  </div>
                  <h2>
                    Data Quality <br /> Issues
                  </h2>
                  <p className="business_slider_p">Data Governance</p>
                </li>
                <li className="card">
                  <div className="arrow_up_growth">
                    <h1>3x</h1>
                  </div>
                  <h2>
                    Faster <br /> Insights
                  </h2>
                  <p className="business_slider_p">Data Visualization</p>
                </li>
                <li className="card">
                  <div className="arrow_up_growth">
                    <h1>28%</h1>
                    <img
                      className="arrow_up"
                      src={arrow_up}
                      alt="Arrow up"
                      loading="lazy"
                    ></img>
                  </div>
                  <h2>
                    Dormant <br /> Reactivations
                  </h2>
                  <p className="business_slider_p">Customer Analytics</p>
                </li>
              </ul>
              <i id="right" className="fa-solid fa-angle-right"></i>
            </div>
          </div>
        </div>
        <div className="business-image">
          <img
            src={business_intelligence}
            alt="Business Intelligence image"
          ></img>
        </div>
      </section>
      <h1 className="icon-box-section-h1">
        Our Business Intelligence Services
      </h1>

      <section>
        <div className="data-driven-insights-section services_boxes_section_padding">
          <div className="data-driven-insights-box bi_consulting_img_height">
            <div className="bi-infra-img">
              <img
                className="channel_attribution"
                src={channel_attribution}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <h3>Channel Attribution</h3>
            <p>
              Stop guessing which marketing channels are driving results. Our
              data-driven approach reveals the true impact of your efforts,
              helping you optimize spending and maximize ROI. Uncover hidden
              opportunities, understand customer journeys, and make informed
              decisions that fuel growth with our comprehensive channel
              attribution analysis. Identify underperforming channels,
              reallocate resources effectively, and achieve marketing success
              with White Box Data.
            </p>
          </div>
          <div className="data-driven-insights-box">
            <div className="bi-infra-img">
              <img
                className="customer_analytics"
                src={customer_analytics}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <h3>Customer Analytics</h3>
            <p>
              Unlock the secrets to customer satisfaction, retention, and
              lifetime value. Our customer analytics solutions dive deep into
              behaviors, preferences, demographics, and purchase patterns,
              empowering you to create personalized experiences that drive
              loyalty and growth. Segment your audience, anticipate their needs,
              and deliver targeted campaigns that resonate. Understand what
              drives churn and proactively address pain points to retain
              valuable customers.
            </p>
          </div>
          <div className="data-driven-insights-box">
            <div className="bi-infra-img">
              <img
                className="web_analytics"
                src={web_analytics}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <h3>Web Analytics</h3>
            <p>
              Transform your website into a high-performing lead generation
              machine. Track user behavior, measure engagement, and analyze key
              metrics to identify opportunities for improvement. Optimize
              content, streamline navigation, and personalize the user
              experience to drive conversions and revenue. With our web
              analytics platform, you'll gain actionable insights to make
              data-driven decisions that enhance your website's effectiveness.
            </p>
          </div>
        </div>
      </section>

      <section className="mt-[-2%]">
        <div className="data-driven-insights-section services_boxes_section_padding">
          <div className="data-driven-insights-box">
            <div className="bi-infra-img">
              <img
                className="data_visualization"
                src={data_visualization}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <h3>Data Visualization & Reporting</h3>
            <p>
              Tired of drowning in spreadsheets? Our experts transform complex
              data into clear, visually stunning dashboards and reports. Make
              sense of your data at a glance, identify trends, and unlock
              actionable insights to propel your business forward. Whether you
              need to track KPIs, monitor performance, or communicate
              data-driven stories, our customized visualizations simplify
              complex information and empower you to make informed decisions
              with confidence.
            </p>
          </div>
          <div className="data-driven-insights-box">
            <div className="bi-infra-img">
              <img
                className="data_governance"
                src={data_governance}
                alt=""
                loading="lazy"
              ></img>
            </div>
            <h3>Data Governance & Security</h3>
            <p>
              Protect your valuable data assets and ensure compliance with our
              comprehensive data governance and security solutions. Mitigate
              risk, build trust with customers, and safeguard your reputation
              with robust security measures. Implement data access controls,
              encryption, and monitoring to prevent unauthorized access and
              breaches. Develop data governance policies and procedures to
              ensure data quality, integrity, and accountability. Safeguard your
              business with White Box Data's expertise in data governance and
              security.
            </p>
          </div>
          <div className="data-driven-insights-box flex items-center bg-[#b1ceef]">
            <div className="services_quote_text ">
              <h2>Looking for a custom solution?</h2>

              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
                className="talk-to-expert-btn services_button"
              >
                Request a Consultation
              </a>
            </div>
          </div>
        </div>
      </section>

      <a href="/contactus">
        <button className="fixed-button">Request a Consultation</button>
      </a>

      <DataStrategyModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}
