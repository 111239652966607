import React from "react";

import Layout from "../Layouts/Layout";
import GoogleCookieBlog from "../Components/Blogs/GoogleCookieBlog";

export default function GoogleCookieBlogPage() {
  return (
    <Layout>
      <div>
        <GoogleCookieBlog />
      </div>
    </Layout>
  );
}
