import React from "react";

import Layout from "../Layouts/Layout";
import DataManagementBlog from "../Components/Blogs/DataManagementBlog";

export default function DataManagementBlogPage() {
  return (
    <Layout>
      <div>
        <DataManagementBlog />
      </div>
    </Layout>
  );
}
