import React, { useState, useEffect } from "react";
import "../Styles/DataStrategy.css";
import DataStrategyModal from "./DataStrategyModal";
import aws from "../Assets/DataStrategy/aws.png";
import microsoft_azure from "../Assets/DataStrategy/microsoft azure.png";
import google_cloud from "../Assets/DataStrategy/google cloud.png";
import Airbyte from "../Assets/DataStrategy/Airbyte.png";
import Alation from "../Assets/DataStrategy/Alation.png";
import amazon_aurora from "../Assets/DataStrategy/amazon aurora.png";
import amazon_document_DB from "../Assets/DataStrategy/amazon document DB.png";
import amazon_dynamo_DB from "../Assets/DataStrategy/Amazon Dynamo DB.png";
import amazon_keyspaces from "../Assets/DataStrategy/amazon keyspaces.png";
import amazon_RDS from "../Assets/DataStrategy/amazon RDS.png";
import amazon_redshift from "../Assets/DataStrategy/amazon redshift.png";
import amazon_s3 from "../Assets/DataStrategy/amazon s3.png";
import ApacheAirflow from "../Assets/DataStrategy/ApacheAirflow.png";
import ApacheHbase from "../Assets/DataStrategy/ApacheHbase.png";
import ApacheSpark from "../Assets/DataStrategy/ApacheSpark.png";
import AWSDataPipeline from "../Assets/DataStrategy/AWSDataPipeline.png";
import AWSGlue from "../Assets/DataStrategy/AWSGlue.png";
import azure_Blob_Storage from "../Assets/DataStrategy/azure Blob Storage.png";
import azure_data_lake from "../Assets/DataStrategy/azure data lake.png";
import azure_synpase_analytics from "../Assets/DataStrategy/azure synpase analytics.png";
import Azure_Cosmos_DB from "../Assets/DataStrategy/Azure_Cosmos_DB.png";
import AzureDataFactory from "../Assets/DataStrategy/AzureDataFactory.png";
import Cassandra from "../Assets/DataStrategy/Cassandra.png";
import chartblocks from "../Assets/DataStrategy/chartblocks.png";
import Chartist from "../Assets/DataStrategy/Chartist.js.png";
import Cloudera from "../Assets/DataStrategy/Cloudera.png";
import D3 from "../Assets/DataStrategy/D3.png";
import Datawrapper from "../Assets/DataStrategy/Datawrapper.png";
import DeltaLake from "../Assets/DataStrategy/DeltaLake.png";
import ember from "../Assets/DataStrategy/ember.png";
import Fusion_charts from "../Assets/DataStrategy/Fusion Charts.png";
import teradata from "../Assets/DataStrategy/teradata.png";
import Talend from "../Assets/DataStrategy/Talend.png";
import tableau from "../Assets/DataStrategy/tableau.png";
import Stitch from "../Assets/DataStrategy/Stitch.png";
import SQLServer from "../Assets/DataStrategy/SQLServer.png";
import SQL_Server from "../Assets/DataStrategy/SQL Server.png";
import Snowflake from "../Assets/DataStrategy/Snowflake.png";
import Sisense from "../Assets/DataStrategy/Sisense.png";
import SAPDataServices from "../Assets/DataStrategy/SAPDataServices.png";
import QlikView from "../Assets/DataStrategy/QlikView.png";
import Qlik from "../Assets/DataStrategy/Qlik.png";
import QlikSense from "../Assets/DataStrategy/Qlik Sense.png";
import powerBI from "../Assets/DataStrategy/powerBI.png";
import postgre_SQL from "../Assets/DataStrategy/postgre SQL.png";
import Portable from "../Assets/DataStrategy/Portable.png";
import Pentaho from "../Assets/DataStrategy/Pentaho.png";
import oracle_data_warehouse from "../Assets/DataStrategy/oracle data warehouse.png";
import oracle_business_intelligence from "../Assets/DataStrategy/oracle business intelligence.png";
import OracleDataIntegrator from "../Assets/DataStrategy/OracleDataIntegrator.png";
import mongo_db from "../Assets/DataStrategy/mongo db.png";
import MicroStrategy from "../Assets/DataStrategy/MicroStrategy.png";
import Microsoft_excel from "../Assets/DataStrategy/Microsoft excel.png";
import MySql from "../Assets/DataStrategy/MySql.png";
import Looker from "../Assets/DataStrategy/Looker.png";
import Kafka from "../Assets/DataStrategy/Kafka.png";
import Informatica from "../Assets/DataStrategy/Informatica.png";
import IBMDataStage from "../Assets/DataStrategy/IBMDataStage.png";
import IBM_Cognos from "../Assets/DataStrategy/IBM Cognos.png";
import Hive from "../Assets/DataStrategy/Hive.png";
import Hevo from "../Assets/DataStrategy/Hevo.png";
import Hadoop from "../Assets/DataStrategy/Hadoop.png";
import Grafana from "../Assets/DataStrategy/Grafana.png";
import GoogleCloudDataflow from "../Assets/DataStrategy/GoogleCloudDataflow.png";
import google_developers_chart from "../Assets/DataStrategy/google developers chart.png";
import google_big_query from "../Assets/DataStrategy/google big query.png";
import amazonlex from "../Assets/DataStrategy/amazonlex.png";
import amazonpolly from "../Assets/DataStrategy/amazonpolly.png";
import amazonsaagemaker from "../Assets/DataStrategy/amazonsaagemaker.png";
import amazontranscribe from "../Assets/DataStrategy/amazontranscribe.png";
import azuremachinglearning from "../Assets/DataStrategy/azuremachinglearning.png";
import googlecloudai from "../Assets/DataStrategy/googlecloudai.png";
import keras from "../Assets/DataStrategy/keras.png";
import mahout from "../Assets/DataStrategy/mahout.png";
import microsoft_cognitiveservices from "../Assets/DataStrategy/microsoft cognitiveservices.png";
import mxnet from "../Assets/DataStrategy/mxnet.png";
import scikitlearn from "../Assets/DataStrategy/scikitlearn.png";
import spacy from "../Assets/DataStrategy/spacy.png";
import spark from "../Assets/DataStrategy/spark.png";
import tensorflow from "../Assets/DataStrategy/tensorflow.png";
import vertexai from "../Assets/DataStrategy/vertexai.png";
import resource_alloation from "../Assets/icons/resource_ alloation.png";
import resource_allocation from "../Assets/icons/resource_allocation.png";
import project_scoping from "../Assets/icons/project_scoping.png";
import dedicated_consultants from "../Assets/icons/dedicated_consultants.png";
import comma from "../Assets/Img/inverted comma.svg";
import CustomerEngagementRevenueOuter from "../Assets/Img/CustomerEngagementRevenueOuter.jpg";
import DataDrivenTransformationOuter from "../Assets/Img/DataDrivenTransformationOuter.png";
import { Helmet } from "react-helmet-async";

export default function DataStrategy() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const categories = [
    {
      label: "Business Intelligence",
      text: "Transform your data into actionable insights. White Box Data's BI solutions empower decision-making, optimize operations, and drive growth.",
      services: [
        {
          name: "Business Intelligence services",
          url: "/services/business-intelligence-and-analytics",
        },
        {
          name: "Business Intelligence consulting",
          url: "/business-intelligence-consulting",
        },
      ],
    },
    {
      label: "Data Analytics & Management",
      text: "Unlock the power of your data. Our data analytics and management services clean, organize, and analyze your information to reveal hidden opportunities.",
      services: [
        {
          name: "Data Analytics & Management services",
          url: "/services/data-engineering-and-infrastructure",
        },
        {
          name: "Data Analytics & Management consulting",
          url: "/data-management-consulting",
        },
      ],
    },
    {
      label: "Data Warehouse & Data Lake",
      text: "Centralize and streamline your data assets. Our data warehousing and data lake solutions provide scalable, secure, and accessible storage for your business intelligence needs.",
      services: [
        {
          name: "Data Warehouse & Data Lake services",
          url: "/services/data-engineering-and-infrastructure",
        },
        {
          name: "Data Warehouse & Data Lake consulting",
          url: "/data-management-consulting",
        },
      ],
    },
    {
      label: "Marketing Analytics",
      text: "Measure and optimize your marketing efforts. White Box Data's marketing analytics services identify high-performing campaigns, improve customer targeting, and drive ROI.",
      services: [
        {
          name: "Marketing Analytics services",
          url: "/services/business-intelligence-and-analytics",
        },
        {
          name: "Marketing Analytics consulting",
          url: "/marketing-analytics-consulting",
        },
      ],
    },
    {
      label: "Growth Marketing",
      text: "Accelerate your business growth. Our growth marketing strategies combine data-driven insights with creative tactics to acquire, engage, and retain customers.",
      services: [
        {
          name: "Growth Marketing services",
          url: "/services/growth-and-marketing-technology",
        },
        { name: "Growth Marketing consulting" },
      ],
    },
    {
      label: "Data Science & AI",
      text: "Our data science and AI solutions leverage advanced algorithms and machine learning to predict trends, automate processes, and drive innovation.",
      services: [
        {
          name: "Data Science & AI consulting",
          url: "/ai-consulting-services",
        },
      ],
    },
    {
      label: "Big Data",
      text: "Harness the power of big data. White Box Data's big data solutions process vast volumes of information to uncover trends, patterns, and valuable insights.",
      services: [{ name: "Big Data consulting" }],
    },
  ];

  const [displayText, setDisplayText] = useState(categories[0].text);

  const [activeIndex, setActiveIndex] = useState(0);

  const handleTextClick = (index) => {
    setDisplayText(categories[index].text);
    setActiveIndex(index);
  };

  const handleServiceClick = (e, service) => {
    if (service.url) {
      window.location.href = service.url;
    } else {
      e.preventDefault();
      setIsModalOpen(true);
    }
  };

  const title = "Strategic Data Consulting | White Box Data";
  const DESCRIPTION =
    "Partner with White Box Data for strategic data consulting. Our expertise in data strategy, cloud solutions and managed BI, Analytics & Data Engineering services unlocks your data's full potential for business growth.";

  useEffect(() => {
    document.title = title;
  });

  return (
    <div className="data-strategy-container-main">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      >
        <link rel="canonical" href="https://www.whiteboxdata.com/data" />
      </Helmet>
      <section className="data-strategy-landing">
        <div className="data-strategy-landing-text">
          <h1>Strategic Data Consulting</h1>
          <p>
            Thriving with your data starts with a solid strategy. Our expert
            data consulting & development services helps you build a robust data
            strategy, leverage advanced Business Intelligence, Analytics & Cloud
            solutions, and manage your data for actionable insights and business
            growth
          </p>
        </div>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setIsModalOpen(true);
          }}
          className="talk-to-expert-btn"
        >
          Talk to a Data Expert
        </a>
        <div className="data-strategy-landing-box">
          <div className="matrix-box">
            <h3>25+ years</h3>
            <p>
              Founders & Consultants experience in business intelligence,
              analytics & data warehousing services
            </p>
          </div>
          <span className="vertical"></span>
          <div className="matrix-box">
            <h3>$3.78 M</h3>
            <p>
              Saved in last 2 years with scalable and effective data solutions
              across companies in financial, healthcare, retail & e-commerce
              industries
            </p>
          </div>
          <span className="vertical"></span>
          <div className="matrix-box">
            <h3>Silicon Valley</h3>
            <p>
              Founders, Advisors & Consultants are a team of data experts who
              have worked at top tech silicon valley companies
            </p>
          </div>
        </div>
      </section>

      <section className="data-services-offering-desktop-screen">
        <h2>Our Data Offerings</h2>
        <div className="offering-box1">
          <p>{displayText}</p>
          {activeIndex !== null && categories[activeIndex].services && (
            <ul className="services-list">
              {categories[activeIndex].services.map((service, index) => (
                <li key={index}>
                  <a
                    href={service.url || "#"}
                    onClick={(e) => handleServiceClick(e, service)}
                  >
                    {service.name}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="offering-box2">
          {categories.map((category, index) => (
            <p
              key={index}
              onClick={() => handleTextClick(index)}
              className={index === activeIndex ? "active" : ""}
            >
              {category.label}
            </p>
          ))}
        </div>
      </section>

      <section className="data-services-offering-mobile-screen">
        <h2>Our Data Offerings</h2>

        <div className="data-services-offering-boxes">
          <div>
            <h3>Business Intelligence</h3>
            <p>
              Transform your data into actionable insights. White Box Data's BI
              solutions empower decision-making, optimize operations, and drive
              growth.
            </p>
            <a href="/services/business-intelligence-and-analytics">
              <p className="font-medium underline">
                Business Intelligence services
              </p>
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsModalOpen(true);
              }}
            >
              <p className="font-medium underline">
                Business Intelligence consulting
              </p>
            </a>
          </div>
          <div>
            <h3>Data Analytics & Management</h3>
            <p>
              Unlock the power of your data. Our data analytics and management
              services clean, organize, and analyze your information to reveal
              hidden opportunities.
            </p>
            <a href="/services/data-engineering-and-infrastructure">
              <p className="font-medium underline">
                Data Analytics & Management services
              </p>
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsModalOpen(true);
              }}
            >
              <p className="font-medium underline">
                Data Analytics & Management consulting
              </p>
            </a>
          </div>
          <div>
            <h3>Data Warehouse & Data Lake</h3>
            <p>
              Centralize and streamline your data assets. Our data warehousing
              and data lake solutions provide scalable, secure, and accessible
              storage for your business intelligence needs.
            </p>
            <a href="/services/data-engineering-and-infrastructure">
              <p className="font-medium underline">
                Data Warehouse & Data Lake services
              </p>
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsModalOpen(true);
              }}
            >
              <p className="font-medium underline">
                Data Warehouse & Data Lake consulting
              </p>
            </a>
          </div>
          <div>
            <h3>Marketing Analytics</h3>
            <p>
              Measure and optimize your marketing efforts. White Box Data's
              marketing analytics services identify high-performing campaigns,
              improve customer targeting, and drive ROI.
            </p>
            <a href="/services/business-intelligence-and-analytics">
              <p className="font-medium underline">
                Marketing Analytics services
              </p>
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsModalOpen(true);
              }}
            >
              <p className="font-medium underline">
                Marketing Analytics consulting
              </p>
            </a>
          </div>
          <div>
            <h3>Growth Marketing</h3>
            <p>
              Accelerate your business growth. Our growth marketing strategies
              combine data-driven insights with creative tactics to acquire,
              engage, and retain customers.
            </p>
            <a href="/services/growth-and-marketing-technology">
              <p className="font-medium underline">Growth Marketing services</p>
            </a>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsModalOpen(true);
              }}
            >
              <p className="font-medium underline">
                Growth Marketing consulting
              </p>
            </a>
          </div>
          <div>
            <h3>Data Science & AI</h3>
            <p>
              Our data science and AI solutions leverage advanced algorithms and
              machine learning to predict trends, automate processes, and drive
              innovation.
            </p>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsModalOpen(true);
              }}
            >
              <p className="font-medium underline">
                Data Science & AI consulting
              </p>
            </a>
          </div>
          <div>
            <h3>Big Data</h3>
            <p>
              Harness the power of big data. White Box Data's big data solutions
              process vast volumes of information to uncover trends, patterns,
              and valuable insights.
            </p>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setIsModalOpen(true);
              }}
            >
              <p className="font-medium underline">Big Data consulting</p>
            </a>
          </div>
        </div>
      </section>

      <section className="success-project-container-main">
        <h2 className="success-project-h2">
          Results-Driven Data Solutions for Your Success
        </h2>

        <div className="success-project-box-section">
          <div className="success-project-box">
            <img
              src={project_scoping}
              alt="project_scoping"
              loading="lazy"
            ></img>
            <h3>Project Scoping</h3>
            <p>
              {" "}
              We start with conducting in-depth interviews with all the relevant
              stakeholders before meticulously defining your project's scope,
              ensuring clear goals, deliverables, and timelines. Our
              collaborative approach ensures that your vision aligns perfectly
              with our actionable data strategies, setting the foundation for
              success.
            </p>
          </div>
          <div className="success-project-box">
            <img
              src={resource_allocation}
              alt="resource_allocation"
              loading="lazy"
            ></img>

            <h3>Cost Estimation</h3>
            <p>
              Transparent and detailed cost estimations are our hallmark. We
              provide a clear breakdown of project costs, including resource
              allocation, ensuring there are no surprises and you have a clear
              understanding of the investment required. We even account for
              investment cost associated with softwares, licensing, tech stack
              and operational expenses.
            </p>
          </div>
        </div>
        <div className="success-project-box-section">
          <div className="success-project-box">
            <img
              src={dedicated_consultants}
              alt="dedicated_consultants"
              loading="lazy"
            ></img>

            <h3>Dedicated Consultants</h3>
            <p>
              Our team boasts seasoned data consultants with expertise across
              industries and technologies. We leverage this experience to craft
              tailored solutions that address your unique challenges and unlock
              the full potential of your data. We have a lead silicon valley
              consultant for each of our client's project for the entire project
              duration.
            </p>
          </div>
          <div className="success-project-box">
            <img
              src={resource_alloation}
              alt="resource_alloation"
              loading="lazy"
            ></img>

            <h3>Resource Allocation</h3>
            <p>
              Efficient resource allocation is critical to project success. We
              assemble the right team of data experts for your specific needs,
              ensuring that your project is staffed with the optimal blend of
              skills and experience to deliver results. Be it BI Analysts, Data
              Engineers, Product & Marketing Analyst, Data Scientist, Growth
              Marketer or a Data Architect, we do it all in-house.
            </p>
          </div>
        </div>
      </section>
      <section className="case-study-data-strategy">
        <h2>Case Studies</h2>
        <div className="casestudy-container">
          <div className="casestudy-box">
            <a href="/case-studies/marketing-data-analytics-implementation-for-a-financial-services-company">
              <div className="casestudy-image">
                <img src={CustomerEngagementRevenueOuter} alt=""></img>
              </div>
              <div className="casestudy-title">
                <span className="casestudy-title-span">
                  Data Analytics Solution for a 360 Channel Attribution View for
                  Customer Engagement & Revenue
                </span>
                <span className="read-more">Read More &gt;&gt;&gt;</span>
              </div>
            </a>
          </div>

          <div className="casestudy-box">
            <a href="/case-studies/bi-driven-analytics-transformation-for-a-lending-company">
              <div className="casestudy-image">
                <img src={DataDrivenTransformationOuter} alt=""></img>
              </div>
              <div className="casestudy-title">
                <span className="casestudy-title-span">
                  Business Intelligence Driven Analytics Transformation
                </span>
                <span className="read-more">Read More &gt;&gt;&gt;</span>
              </div>
            </a>
          </div>
        </div>

        <div className="case-studies-btn">
          <a href="/case-studies" className="know-more-btn">
            View all Case Studies
          </a>
        </div>
      </section>

      <section className="tech-tools-container-main">
        <h2 className="text-center">
          Our Expertise for Scalable Data Solutions
        </h2>
        <div className="tech-tools-main-box">
          <h3>Cloud Services</h3>
          <div className="white-main-box">
            <div className="horizontal-box">
              <div className="individual-box">
                <img className="aws" src={aws} alt="aws" loading="lazy"></img>
              </div>
              <div className="individual-box">
                <img
                  className="google_cloud"
                  src={google_cloud}
                  alt="google_cloud"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="microsoft_azure"
                  src={microsoft_azure}
                  alt="microsoft_azure"
                  loading="lazy"
                ></img>
              </div>
            </div>
          </div>
          <h3>Data Visualization Tools</h3>
          <div className="white-main-box">
            <div className="horizontal-box">
              <div className="individual-box">
                <img
                  className="tableau"
                  src={tableau}
                  alt="tableau"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="google_developers_chart"
                  src={google_developers_chart}
                  alt="google_developers_chart"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="powerBI"
                  src={powerBI}
                  alt="individual"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="QlikSense"
                  src={QlikSense}
                  alt="QlikSense"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Fusion_charts"
                  src={Fusion_charts}
                  alt="Fusion_charts"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Looker"
                  src={Looker}
                  alt="Looker"
                  loading="lazy"
                ></img>
              </div>
            </div>

            <div className="horizontal-box">
              <div className="individual-box">
                <img
                  className="Grafana"
                  src={Grafana}
                  alt="Grafana"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="chartblocks"
                  src={chartblocks}
                  alt="chartblocks"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Microsoft_excel"
                  src={Microsoft_excel}
                  alt="Microsoft_excel"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Chartist"
                  src={Chartist}
                  alt="Chartist"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Datawrapper"
                  src={Datawrapper}
                  alt="Datawrapper"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="oracle_business_intelligence"
                  src={oracle_business_intelligence}
                  alt="oracle_business_intelligence"
                  loading="lazy"
                ></img>
              </div>
            </div>

            <div className="horizontal-box">
              <div className="individual-box">
                <img
                  className="MicroStrategy"
                  src={MicroStrategy}
                  alt="MicroStrategy"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="IBM_Cognos"
                  src={IBM_Cognos}
                  alt="IBM_Cognos"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img className="D3" src={D3} alt="D3" loading="lazy"></img>
              </div>
              <div className="individual-box">
                <img
                  className="QlikView"
                  src={QlikView}
                  alt="QlikView"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Sisense"
                  src={Sisense}
                  alt="QlikView"
                  loading="lazy"
                ></img>
              </div>
            </div>
          </div>

          <h3>Data Warehouse & storage Solutions</h3>
          <div className="white-main-box">
            <div className="horizontal-box">
              <div className="individual-box">
                <img
                  className="amazon_redshift"
                  src={amazon_redshift}
                  alt="amazon_redshift"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="google_big_query"
                  src={google_big_query}
                  alt="google_big_query"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="postgre_SQL"
                  src={postgre_SQL}
                  alt="google_big_query"
                  loading="lazy"
                ></img>
              </div>

              <div className="individual-box">
                <img
                  className="SQL_Server"
                  src={SQL_Server}
                  alt="SQL_Server"
                  loading="lazy"
                ></img>
              </div>

              <div className="individual-box">
                <img
                  className="Snowflake"
                  src={Snowflake}
                  alt="Snowflake"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="MySql"
                  src={MySql}
                  alt="MySql"
                  loading="lazy"
                ></img>
              </div>
            </div>

            <div className="horizontal-box">
              <div className="individual-box">
                <img
                  className="amazon_RDS"
                  src={amazon_RDS}
                  alt="amazon_RDS"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="amazon_dynamo_DB"
                  src={amazon_dynamo_DB}
                  alt="amazon_dynamo_DB"
                  loading="lazy"
                ></img>
              </div>

              <div className="individual-box">
                <img
                  className="amazon_s3"
                  src={amazon_s3}
                  alt="amazon_s3"
                  loading="lazy"
                ></img>
              </div>

              <div className="individual-box">
                <img
                  className="Azure_Cosmos_DB"
                  src={Azure_Cosmos_DB}
                  alt="Azure_Cosmos_DB"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="mongo_db"
                  src={mongo_db}
                  alt="mongo_db"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="amazon_aurora"
                  src={amazon_aurora}
                  alt="amazon_aurora"
                  loading="lazy"
                ></img>
              </div>
            </div>

            <div className="horizontal-box">
              <div className="individual-box">
                <img
                  className="azure_data_lake"
                  src={azure_data_lake}
                  alt="azure_data_lake"
                  loading="lazy"
                ></img>
              </div>

              <div className="individual-box">
                <img
                  className="amazon_document_DB"
                  src={amazon_document_DB}
                  alt="amazon_document_DB"
                  loading="lazy"
                ></img>
              </div>

              <div className="individual-box">
                <img
                  className="teradata"
                  src={teradata}
                  alt="teradata"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="oracle_data_warehouse"
                  src={oracle_data_warehouse}
                  alt="oracle_data_warehouse"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="azure_Blob_Storage"
                  src={azure_Blob_Storage}
                  alt="azure_Blob_Storage"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="azure_synpase_analytics"
                  src={azure_synpase_analytics}
                  alt="azure_synpase_analytics"
                  loading="lazy"
                ></img>
              </div>
            </div>
          </div>

          <h3>ETL, Automation & Integration</h3>
          <div className="white-main-box">
            <div className="horizontal-box">
              <div className="individual-box">
                <img
                  className="ApacheAirflow"
                  src={ApacheAirflow}
                  alt="ApacheAirflow"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="AzureDataFactory"
                  src={AzureDataFactory}
                  alt="AzureDataFactory"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="AWSGlue"
                  src={AWSGlue}
                  alt="AWSGlue"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="OracleDataIntegrator"
                  src={OracleDataIntegrator}
                  alt="OracleDataIntegrator"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="SQLServer"
                  src={SQLServer}
                  alt="SQLServer"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Talend"
                  src={Talend}
                  alt="Talend"
                  loading="lazy"
                ></img>
              </div>
            </div>

            <div className="horizontal-box">
              <div className="individual-box">
                <img
                  className="Pentaho"
                  src={Pentaho}
                  alt="Pentaho"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="AWSDataPipeline"
                  src={AWSDataPipeline}
                  alt="AWSDataPipeline"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="IBMDataStage"
                  src={IBMDataStage}
                  alt="IBMDataStage"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Portable"
                  src={Portable}
                  alt="Portable"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="GoogleCloudDataflow"
                  src={GoogleCloudDataflow}
                  alt="GoogleCloudDataflow"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Stitch"
                  src={Stitch}
                  alt="Stitch"
                  loading="lazy"
                ></img>
              </div>
            </div>

            <div className="horizontal-box">
              <div className="individual-box">
                <img
                  className="Hevo"
                  src={Hevo}
                  alt="Hevo"
                  loading="lazy"
                ></img>
              </div>

              <div className="individual-box">
                <img
                  className="SAPDataServices"
                  src={SAPDataServices}
                  alt="SAPDataServices"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Qlik"
                  src={Qlik}
                  alt="Qlik"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Airbyte"
                  src={Airbyte}
                  alt="Airbyte"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Informatica"
                  src={Informatica}
                  alt="Informatica"
                  loading="lazy"
                ></img>
              </div>
            </div>
          </div>

          <h3>Data Governance & Privacy Tools</h3>
          <div className="white-main-box">
            <div className="horizontal-box">
              <div className="individual-box">
                <img
                  className="ApacheAirflow"
                  src={ApacheAirflow}
                  alt="ApacheAirflow"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Talend"
                  src={Talend}
                  alt="Talend"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Informatica"
                  src={Informatica}
                  alt="Informatica"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Snowflake"
                  src={Snowflake}
                  alt="Snowflake"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Alation"
                  src={Alation}
                  alt="Alation"
                  loading="lazy"
                ></img>
              </div>
            </div>
          </div>

          <h3>Big Data Tools</h3>
          <div className="white-main-box">
            <div className="horizontal-box">
              <div className="individual-box">
                <img
                  className="ApacheSpark"
                  src={ApacheSpark}
                  alt="ApacheSpark"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Cassandra"
                  src={Cassandra}
                  alt="Cassandra"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="ApacheHbase"
                  src={ApacheHbase}
                  alt="ApacheHbase"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Hive"
                  src={Hive}
                  alt="Hive"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Kafka"
                  src={Kafka}
                  alt="Kafka"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Cloudera"
                  src={Cloudera}
                  alt="Cloudera"
                  loading="lazy"
                ></img>
              </div>
            </div>

            <div className="horizontal-box">
              <div className="individual-box">
                <img
                  className="DeltaLake"
                  src={DeltaLake}
                  alt="DeltaLake"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="Hadoop"
                  src={Hadoop}
                  alt="Hadoop"
                  loading="lazy"
                ></img>
              </div>
            </div>
          </div>

          <h3>Machine Learning & AI Tools</h3>
          <div className="white-main-box">
            <div className="horizontal-box">
              <div className="individual-box">
                <img
                  className="spark"
                  src={spark}
                  alt="spark"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="scikitlearn"
                  src={scikitlearn}
                  alt="scikitlearn"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="spacy"
                  src={spacy}
                  alt="spacy"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="tensorflow"
                  src={tensorflow}
                  alt="tensorflow"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="amazonlex"
                  src={amazonlex}
                  alt="amazonlex"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="googlecloudai"
                  src={googlecloudai}
                  alt="googlecloudai"
                  loading="lazy"
                ></img>
              </div>
            </div>

            <div className="horizontal-box">
              <div className="individual-box">
                <img
                  className="azuremachinglearning"
                  src={azuremachinglearning}
                  alt="azuremachinglearning"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="microsoft_cognitiveservices"
                  src={microsoft_cognitiveservices}
                  alt="microsoft_cognitiveservices"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="amazonsaagemaker"
                  src={amazonsaagemaker}
                  alt="amazonsaagemaker"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img className="keras" src={keras} alt="keras"></img>
              </div>
              <div className="individual-box">
                <img
                  className="amazontranscribe"
                  src={amazontranscribe}
                  alt="amazontranscribe"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="amazonpolly"
                  src={amazonpolly}
                  alt="amazonpolly"
                  loading="lazy"
                ></img>
              </div>
            </div>

            <div className="horizontal-box">
              <div className="individual-box">
                <img
                  className="mahout"
                  src={mahout}
                  alt="mahout"
                  loading="lazy"
                ></img>
              </div>

              <div className="individual-box">
                <img
                  className="mxnet"
                  src={mxnet}
                  alt="mxnet"
                  loading="lazy"
                ></img>
              </div>
              <div className="individual-box">
                <img
                  className="vertexai"
                  src={vertexai}
                  alt="vertexai"
                  loading="lazy"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="what-our-clients-say-container-main">
        <h2>
          Data Experts, Trusted Advisors: <br></br>
          Hear from Our Valued Clients
        </h2>
        <div className="what-our-clients-say">
          <div className="what-they-say-box">
            <img
              className="services-comma"
              src={comma}
              alt="comma"
              loading="lazy"
            ></img>
            <p className="what-they-say-text height-mobile-box">
              "We have had a phenomenal experience with their BI & Marketing
              Analytics consulting. The Multi Touch channel attribution model
              and the reporting ecosystem they have built has changed the way
              our team functions and makes decisions. We have never had this
              level of insights into our channels and conversions.”
            </p>
            <p className="services-name">
              -Sherry G. - Head of Analytics, Consumer Finance
            </p>
          </div>
          <div className="what-they-say-box">
            <img
              className="services-comma"
              src={comma}
              alt="comma"
              loading="lazy"
            ></img>
            <p className="what-they-say-text height-mobile-box">
               "WBD team has helped us make some real progress with our entire
              migration from Azure to AWS. This is what real data expertise
              should look like. Everyone on the team is extremely humble and
              they go out of their way to answer all of our million queries from
              non technical data folks."
            </p>
            <p className="services-name">
              -Neil P. - Manager, Customer Success
            </p>
          </div>
        </div>
      </section>

      <section className="consulting_cta_button">
        <h2>
          Your Data Tells a Story. <br />
          Let Us Help You Write the Next Chapter.
        </h2>
        <p>
          Make an appointment to speak with of our lead analytics consultant for
          your <br /> complementary Data Strategy Session.
        </p>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setIsModalOpen(true);
          }}
          className="talk-to-expert-btn mb-[10%]"
        >
          Complementary Session
        </a>
      </section>

      <DataStrategyModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}
