import React, { useEffect } from "react";
import BoostingROIMultiChannelAttributionInner from "../../Assets/Img/BoostingROIMultiChannelAttributionInner.webp";

import { Helmet } from "react-helmet-async";

import "../../Styles/CustomerEngagementRevenue.css";

export default function BoostingROIMultiChannelAttribution() {
  const title =
    "Boosting ROI with Multi-Channel Attribution for a Digital Advertising & Marketing Agency | White Box Data";
  const DESCRIPTION =
    "See how White Box Data helped a digital marketing agency boost ROI by 40% with a custom multi-channel attribution model and optimized ad spend.";

  useEffect(() => {
    document.title = title;
  });

  return (
    <div className="case-study-main-container">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      ></Helmet>

      <section className="fixed-blog-box">
        <div>
          <p>Get your insights now with White Box Data</p>
        </div>

        <div className="blog-getintouch">
          <a href="/contactus">Get in touch</a>
        </div>
      </section>

      <section className="case-study-content">
        <img
          src={BoostingROIMultiChannelAttributionInner}
          alt=""
          className="case_studies-image"
        ></img>

        <h1>
          Boosting ROI with Multi-Channel Attribution for a Digital Advertising
          & Marketing Agency
        </h1>

        <div className="industry-technology-block">
          <div className="industry-block">
            <h3>Industry</h3>
            <span className="industry-text">Digital Marketing</span>
          </div>

          <div className="technology-block">
            <h3>Technology & Tools Stack</h3>
            <span className="industry-text">
              Google Cloud Platform, Google Ads, Facebook Ads, Google Data
              Studio
            </span>
          </div>
        </div>

        <h2>Customer</h2>

        <p className="case-study-content-p">
          <strong>Client: </strong>A UK digital marketing agency specializing in
          paid search, social media and influencer marketing for mid-sized
          businesses.
        </p>
        <p className="case-study-content-p">
          <strong>Customer Overview: </strong>
          The client manages marketing campaigns across multiple
          platforms—Google Ads, Facebook, Instagram and TikTok - but initially
          struggled to attribute conversions accurately. They needed better
          visibility into which channels were driving the most ROI for their
          clients.
        </p>

        <h2>Challenge</h2>
        <p className="case-study-content-p">The key challenges included:</p>

        <ul className="case-study-ul-list">
          <li>
            <strong>Lack of Attribution Clarity: </strong>
            The client had difficulty identifying which channels contributed
            most to customer conversions, leading to inefficient ad spend.
          </li>
          <li>
            <strong>Siloed Data: </strong>
            Data was coming from different marketing platforms, each with its
            own attribution model, making it hard to unify insights.
          </li>
          <li>
            <strong>Ineffective Budget Allocation: </strong>
            Without proper attribution, the client couldn’t optimize marketing
            budgets across platforms, leading to overspending on low-performing
            channels.
          </li>
        </ul>
        <p className="case-study-content-p">
          The client needed a<strong> multi-channel attribution model </strong>
          that could unify their data and provide clarity on channel
          performance, allowing them to optimize ad spend for their clients.
        </p>

        <h2>Solution</h2>
        <p className="case-study-content-p">
          <strong>White Box Data</strong> designed and implemented a custom
          <strong> multi-channel attribution model </strong>integrated data from
          all marketing platforms and provided insights into conversion paths.
        </p>
        <p className="case-study-content-p">
          Key components of the solution included:
        </p>

        <ol>
          <li>
            <strong>Data Integration from Multiple Sources:</strong>
            <p>
              We integrated data from{" "}
              <strong> Google Ads, Facebook Ads, TikTok, </strong>and
              <strong> Instagram </strong>into a central data warehouse using
              <strong> ETL pipelines </strong>built on
              <strong> Google Cloud Platform (GCP). </strong>This allowed for
              seamless data consolidation.
            </p>
          </li>
          <li>
            <strong>Attribution Model Development:</strong>
            <p>
              We developed a <strong> custom attribution model </strong>using
              <strong> Markov Chains </strong>and
              <strong> multi-touch attribution </strong>to understand each
              channel’s contribution to customer conversions. This model
              accounted for touchpoints across the entire customer journey.
            </p>
          </li>
          <li>
            <strong>Advanced Reporting with Google Data Studio:</strong>
            <p>
              To make insights actionable, we set up
              <strong> Google Data Studio </strong>
              dashboards that visualized the performance of each marketing
              channel, campaign, and conversion path. This helped the agency
              make real-time budget decisions.
            </p>
          </li>
          <li>
            <strong>Budget Optimization and Recommendations:</strong>
            <p>
              Using the attribution data, we provided insights and
              recommendations on budget reallocation, focusing on
              high-performing channels while reducing spend on underperforming
              ones.
            </p>
          </li>
        </ol>

        <h2>Results</h2>
        <p className="case-study-content-p">
          The custom multi-channel attribution model delivered substantial
          results:
        </p>

        <ul className="case-study-ul-list">
          <li>
            <strong>40% Increase in ROI: </strong>
            By reallocating budgets to high-performing channels, the client was
            able to improve overall campaign ROI by 40%.
          </li>
          <li>
            <strong>Better Channel Insights: </strong>
            The unified data view gave the agency a deeper understanding of how
            customers interact with multiple channels, allowing for more
            effective strategies.
          </li>
          <li>
            <strong>Optimized Ad Spend: </strong>
            With clear insights, the agency reduced wasted ad spend on
            low-performing channels by 25%.
          </li>
          <li>
            <strong>Improved Client Reporting: </strong>
            The dashboards allowed for clearer, more detailed reporting to the
            agency’s clients, improving client satisfaction and retention.
          </li>
        </ul>
        <h2>Technology Stack</h2>

        <ul className="case-study-ul-list">
          <li>
            <strong>Attribution Model: </strong>
            Python, Markov Chains
          </li>
          <li>
            <strong>ETL Pipeline: </strong>
            Google Cloud Platform (GCP)
          </li>
          <li>
            <strong>Visualization: </strong>
            Google Data Studio
          </li>
          <li>
            <strong>Marketing Platforms: </strong>
            Google Ads, Facebook Ads, Instagram, TikTok
          </li>
        </ul>
      </section>

      <a href="/contactus">
        <button className="fixed-button">Request a Consultation</button>
      </a>
    </div>
  );
}
