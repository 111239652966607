import React, { useState, useRef } from "react";
import PhoneInput from "react-phone-number-input";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";

import "../Styles/DataStrategyForm.css";

export default function InsightsForm() {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [location, setLocation] = useState("");
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);

  const LocationData = [
    { id: 1, title: "USA", label: "usa" },
    { id: 2, title: "UK", label: "uk" },
    { id: 3, title: "EU (EUROPEAN UNION)", label: "eu" },
    { id: 4, title: "AUSTRALIA", label: "au" },
    { id: 5, title: "OTHER", label: "other" },
  ];

  const PositionData = [
    { id: 1, title: "Manager", label: "manager" },
    { id: 2, title: "Director", label: "director" },
    { id: 3, title: "VP", label: "vp" },
    { id: 4, title: "CxO", label: "cxo" },
    { id: 5, title: "Other", label: "other" },
  ];

  const ServiceData = [
    { id: 1, title: "Dashboards and Reporting" },
    { id: 2, title: "Advanced BI Solutions" },
    { id: 3, title: "Advanced Analytics Solutions" },
    { id: 4, title: "Data Management" },
    { id: 5, title: "Other" },
  ];

  const form = useRef();
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_rjnzoj6", "template_sfgrr6b", form.current, {
        publicKey: "dLGaSfNHHKUpDuLYk",
      })

      .then(
        () => {
          setSubmissionSuccess(true);
          console.log("SUCCESS!");
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert("An error occurred while sending the message.");
        }
      );

    form.current.reset();
    setLocation("");
    setPosition("");
    setSelectedServices([]);
  };

  const handleServiceChange = (e) => {
    const value = e.target.value;
    setSelectedServices((prev) =>
      prev.includes(value)
        ? prev.filter((service) => service !== value)
        : [...prev, value]
    );
  };

  return (
    <div className="datastrategy-container">
      <div className="insights_form_section1_text">
        <h1>
          Insights Pro <br /> Consultation
        </h1>
        <p>
          Fill out this form to get a 30-minute Consultation with one of our
          analytics experts.
        </p>
      </div>

      <div className="datastrategy-form">
        {!submissionSuccess && (
          <form ref={form} onSubmit={sendEmail}>
            <div className="grid gap-6 mb-6 md:grid-cols-2 mt-[-1.5rem]">
              <div>
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  First Name*
                </label>
                <input
                  type="text"
                  id="first_name"
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_first_name"
                  required
                  pattern="[A-Za-z\s]+"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Last Name*
                </label>
                <input
                  type="text"
                  id="last_name"
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_last_name"
                  required
                  pattern="[A-Za-z\s]+"
                  value={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>

              <div className="mb-6">
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Phone*
                </label>
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  name="from_mobile"
                  required
                  defaultCountry="US"
                  value={phone}
                  onChange={setPhone}
                  className="text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full flex"
                />
              </div>

              <div className="mb-6">
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Email Address*
                </label>
                <input
                  type="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div>
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Company Name*
                </label>
                <input
                  type="text"
                  id="company"
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_company"
                  required
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                />
              </div>

              <div>
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Position*
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_position"
                  required
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                >
                  <option value="" disabled>
                    Select a Position
                  </option>
                  {PositionData.map((item) => (
                    <option key={item.id} value={item.title}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                  Location*
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  name="from_location"
                  required
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                >
                  <option value="" disabled>
                    Select a location
                  </option>
                  {LocationData.map((item) => (
                    <option key={item.id} value={item.title}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                Message
              </label>
              <textarea
                className="bg-gray-50 border border-gray-300 text-[#002c5f] text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 h-[7rem]"
                type="text"
                as="textarea"
                name="message"
                // required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div> */}

            <div>
              <label className="block mb-2 text-sm font-medium text-[#002c5f]">
                What are you looking to get out of Insights Pro?
              </label>
              <div className="checkbox-group">
                {ServiceData.map((service) => (
                  <div key={service.id}>
                    <input
                      type="checkbox"
                      id={`service-${service.id}`}
                      value={service.title}
                      checked={selectedServices.includes(service.title)}
                      onChange={handleServiceChange}
                      name="from_services"
                    />
                    <label
                      htmlFor={`service-${service.id}`}
                      className="ml-2 text-sm text-[#002c5f]"
                    >
                      {service.title}
                    </label>
                  </div>
                ))}
              </div>
            </div>

            <button
              type="submit"
              className="text-white bg-[#0156b8] font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-3.5 text-center mt-4 mb-[2rem]"
            >
              Request a Consultation
            </button>
            {/* <div className="flex items-start mb-6">
              <div className="flex items-center h-5">
                <input
                  id="remember"
                  type="checkbox"
                  value=""
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
                  required
                />
              </div>

              <label
                for="remember"
                className="ms-2 text-sm font-medium text-[#002c5f]"
              >
                By checking this box, you agree to our{" "}
                <a href="https://www.whiteboxdata.com/privacy-policy">
                  <u>Privacy Policy,</u>{" "}
                </a>{" "}
                and we may contact you about our products and services
              </label>
            </div> */}
          </form>
        )}
        {submissionSuccess && (
          <div className="message-datastrategy">
            <div className="success-datastrategy" id="success">
              Thank you! We have received your Information and one of our Expert
              Consultant will reach out within 24 hrs. We are eager to learn
              more and help you on your Data Journey.
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
