import React, { useEffect } from "react";
import CustomerEngagementRevenueinner from "../../Assets/Img/CustomerEngagementRevenue.png";
import RealTimeOperationalDashboardsInner from "../../Assets/Img/RealTimeOperationalDashboardsInner.png";

import { Helmet } from "react-helmet-async";

import "../../Styles/RealTimeOperationalDashboards.css";

export default function RealTimeOperationalDashboards() {
  const title =
    "Real Time Reporting and Analytics Solution for an EdTech company | White Box Data";
  const DESCRIPTION =
    "Learn how White Box Data helped a US based E-Learning and EdTech company by creating Real Time Reporting solutions which helped them beat revenue expectations.";

  useEffect(() => {
    document.title = title;
  });

  return (
    <div className="case-study-main-container">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      ></Helmet>

      <section className="fixed-blog-box">
        <div>
          <p>Get your insights now with White Box Data</p>
        </div>

        <div className="blog-getintouch">
          <a href="/contactus">Get in touch</a>
        </div>
      </section>

      <section className="case-study-content">
        <img
          src={RealTimeOperationalDashboardsInner}
          alt=""
          className="case_studies-image"
        ></img>

        <h1>
          Real Time Reporting and Analytics Solution for an EdTech company
        </h1>

        <div className="industry-technology-block">
          <div className="industry-block">
            <h3>Industry</h3>
            <span className="industry-text">
              EdTech Marketplace, ECommerce, E-Learning Platform
            </span>
          </div>

          <div className="technology-block">
            <h3>Technology & Tools Stack</h3>
            <span className="industry-text">Tableau, MySQL, Redshift</span>
          </div>
        </div>

        <h2>Customer</h2>

        <p className="case-study-content-p">
          US based E-Learning and EdTech Marketplace services company
        </p>

        <h2>Challenge</h2>
        <p className="case-study-content-p">
          The client is in the EdTech space and their offerings are focused on
          college students. They have specific business verticals and offerings
          that would peak during specific times of the year. Their business had
          3 different seasonal peaks during the year where the company's
          performance would have a direct impact on their revenue and stock
          price.
        </p>

        <p className="case-study-content-p">
          They needed Real Time reporting on key metrics for GMs, Business
          Leaders and CxOs that could be used to make critical strategic
          decisions based on the actual conversions compared with hourly
          forecasted value for those metrics.
        </p>

        <h2>Solution</h2>
        <p className="case-study-content-p">
          Tableau was the default dashboarding tool within the company. A
          lot of cross functional teams, analysts and business leaders would rely on Tableau for their
          daily dose of reporting.
        </p>
        <p className="case-study-content-p">
          We had to make sure to provide a solution which is not only scalable
          and reliable but fits within their current analytics ecosystem where
          users are able to adapt quickly.
        </p>

        <ol>
          <li>
            A mockup design for a KPI dashboard was created and shared with the
            relevant stakeholders. Multiple customized views were created which
            would be relevant to specific teams, business verticals and specific
            use cases.
          </li>

          <li>
            Data was supposed to be integrated from 3 different sources.
            <ul className="case-study-li-ul">
            <li>Actual Metrics - MySQL Prod. Replica</li>
            <li>Hourly Forecast - Redshift Data Warehouse</li>
            <li>Website Add to Cart Metrics - Front End Tracking Systems</li>
            </ul>
           
          </li>

          <li>
            Implementation of the dashboard was near real time as it would
            refresh every 60 secs with new data flowing in. 60 secs Buffer was
            kept to avoid overloading Tableau Server processes which used to
            trigger the refresh using TabCMD (Yes, TABCMD was a big thing with
            Tableau On Premise Server Implementation prior to version 2018).
          </li>

          <li>
            Since Tableau was a shared resource across the company we had to
            make sure that any upstream bottlenecks with any shared memory or
            server usage on Tableau doesn’t impact the refresh of the Real time
            dashboards.
          </li>

          <li>
            A higher priority for the Real Time KPI dashboard was set using
            TabCMD which would make sure it would be refreshed as a priority
            when there are other dashboards and data sources competing for
            Tableau server resources.
          </li>

          <li>
            Data Source Ingestion In Tableau
            <ul className="case-study-li-ul">
              <li>
                Live Connection with Orders System - A live connection was
                established with the MySQL Replica of the PROD which would
                report all KPIs based on the ongoing performance of the
                business.
              </li>
              <li>
                Hourly Forecast - A tableau custom SQL query was used on a view
                created in Redshift which would automatically be fetched from
                the hourly data uploaded by the Finance Team based on specified
                format.
              </li>
              <li>
                Hourly views for Tracking data were defined on Redshift where
                the tracking data was being tracked via a streaming service.
                Creating hourly views helped in processing of the data as
                tracking data had metrics at impressions level.
              </li>
            </ul>
          </li>
          <li>
            Finally we had used Tableau alerting mechanism along with a Python
            script which would send out PagerDuty alerts to relevant on-call
            members who can proactively communicate with stakeholders when
            things are not looking right.
          </li>
        </ol>

        <h2>Results</h2>
        <p className="results-span">
          <strong>
            By having real time dashboard, business leaders were able to get
          </strong>
        </p>
        <ul className="case-study-ul-list">
          <li>Real Time insights into Booked Revenue</li>
          <li>Comparison of Marketing Spend vs Realized Revenue</li>
          <li>Insights into business performance in real time</li>
          <li>
            Take corrective action when performance is not matching the forecast
          </li>
        </ul>

        <p className="results-span">
          <strong>
            Extension to additional Business Verticals across the organization
          </strong>
        </p>
        <ul className="case-study-ul-list">
          <li>
            The solution proved to be extremely successful with the stakeholders
            and we replicated the results to the other 3 Business Verticals for
            the company
          </li>
          <li>
            This Real Time dashboards helped them increase their revenue and
            optimize their operational performance
          </li>
        </ul>

        <p className="results-span">
          <strong>Python Data Alerting</strong>
        </p>
        <ul className="case-study-ul-list">
          <li>
            The simple alerting mechanism setup using Python helped gain
            valuable insights for DevOPS team who were able to save cost by
            reducing the spend on multiple alerting tools for real time
            reporting.
          </li>
        </ul>

        <h2>Technology Stack</h2>

        <p className="case-study-content-p">
          Amazon Redshift, MySQL, Tableau, Python, Jupyter Notebooks, TabCMD,
          Tableau Server, Windows .exe Files
        </p>
      </section>

      <a href="/contactus">
        <button className="fixed-button">Request a Consultation</button>
      </a>
    </div>
  );
}
