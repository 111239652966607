import React, { useState, useEffect } from "react";
import "../Styles/MarketingAnalyticsConsulting.css";
import DataStrategyModal from "./DataStrategyModal";
import marketing_image1 from "../Assets/Img/marketing_image1.png";
import marketing_image2 from "../Assets/Img/marketing_image2.png";
import marketing_image3 from "../Assets/Img/marketing_image3.png";
import problem_statement from "../Assets/icons/problem statement .png";
import data_sources from "../Assets/icons/data sources.png";
import analytics from "../Assets/icons/analytics.png";
import marketing_business_intelligence from "../Assets/Img/marketing_business_intelligence.png";
import marketing_real_time_marketing_analytics from "../Assets/Img/marketing_real_time_marketing_analytics.png";
import marketing_data_strategy_consulting from "../Assets/Img/marketing_data_strategy_consulting.png";

import { Helmet } from "react-helmet-async";

export default function MarketingAnalyticsConsulting() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const title = "Marketing Analytics Consulting | White Box Data";
  const DESCRIPTION =
    "Marketing Analytics consultants will help you get maximum ROI on your paid and organic marketing efforts. Gain a competitive edge with smarter decisions.";

  useEffect(() => {
    document.title = title;
  });

  return (
    <div className="data-strategy-container-main">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      >
        <link rel="canonical" href="https://www.whiteboxdata.com/marketing-analytics-consulting" />

      </Helmet>
      <section className="bi_consulting_landing">
        <div className="bi_consulting_landing_text">
          <h1>Marketing Analytics Consulting</h1>
          <div className="mobile_landing_boxes">
            <div className="marketing_analytics_landing_boxes">
              <div className="marketing_analytics_box">
                <h3>Faster Data Driven Decisions</h3>
              </div>
              <div className="marketing_analytics_box">
                <h3>Insights into your Customer Behavior</h3>
              </div>
              <div className="marketing_analytics_box">
                <h3>Enabling Growth with your Data</h3>
              </div>
            </div>
            <p className="text-left">
              Our Marketing Analytics consultants and data experts help you gain
              a clear understanding of every dollar you spend. How each
              campaign, each ad, each ad variant is performing? Get deep
              insights into how your customers are reacting and get the best
              return on investment (ROI) for your marketing spend.
            </p>
          </div>
        </div>
      </section>

      <section className="data-driven-insights-container">
        <h2>
          Empowering your team with Data Driven Insights
        </h2>

        <p>
          How your marketing spend is being utilized is more important than
          ever. If campaigns are performing well and what can be done to
          optimize it. We live in a time where marketing teams are the new age
          digital warriors who must be able to grab your customer’s attention
          and keep it (atleast for a short while) and get the maximum engagement
          out of it. The end goal for most marketing organizations is to
          identify the optimal target audience, reaching the widest audience,
          having the customers engage with your service or product or content
          and eventually retain them by having them want more.
        </p>
        <p>
          Over 85% of companies that spend on marketing believe in utilizing
          data to gain insights into their customers, yet most struggle with
          measuring the true effectiveness of their campaigns and how it relates
          to their customer interactions.
        </p>
        <p>
          With how much confidence are you able to answer some critical business
          questions like
        </p>

        <ul>
          <li>Who is our most valuable customer? What Demographic & Age?</li>
          <li>Which of our Paid marketing channels are most effective?</li>
          <li>How does our marketing spend impact our organic traffic?</li>
          <li>
            Are our marketing campaigns cannibalizing our Email or SEO revenue?
          </li>
          <li>What is the exact revenue associated with a certain campaign?</li>
          <li>
            Why are our conversion numbers not matching between Meta Ads and our
            internal data?
          </li>
        </ul>

        <p>
          White Box Data is here to help you answer all your questions and much
          more. Our Marketing Analytics, Business Intelligence and Data
          Engineering teams help you build a scalable analytics solution that
          helps you track, analyze & gain insights into your business and
          customers.
        </p>
        <ul>
          <li>Channel Attribution</li>
          <li>Customer Insights</li>
          <li>Campaign Performance</li>
          <li>Marketing Spend Analysis</li>
          <li>Customer Journey Mapping</li>
          <li>Ad Optimization Signals</li>
        </ul>
        <p>
          For any marketing analytics project to be successful, it is crucial to
          have a solid understanding of the problem or the questions that we are
          trying to answer, data that is accurate & consistent and tools that
          help you get the best insights quicker.
        </p>
      </section>

      <section>
        <div className="data-driven-insights-section">
          <div className="data-driven-insights-box">
            <div className="data-driven-insights-box-img">
              <img src={problem_statement} alt="problem_statement" loading="lazy"></img>
            </div>
            <h3>Problem Statement</h3>
            <p>
              Define our end goal. What is it that we want to achieve? What &
              How is it that we are trying to measure? 
            </p>
          </div>
          <div className="data-driven-insights-box">
            <div className="data-driven-insights-box-img">
              <img src={data_sources} alt="data_sources" loading="lazy"></img>
            </div>
            <h3>Data Sources & Data Management</h3>
            <p>
              Identifying, implementing & systematically aggregating all of the
              relevant data sources.
            </p>
          </div>
          <div className="data-driven-insights-box">
            <div className="data-driven-insights-box-img">
              <img src={analytics} alt="analytics" loading="lazy"></img>
            </div>
            <h3>Analytics & Reporting</h3>
            <p>
              Identifying KPIs and metrics and developing personalized
              dashboards for efficient decision making.
            </p>
          </div>
        </div>

        <p className="data-driven-insights-para">
          We help you put all these pieces together and empower you to make most
          efficient decisions out of your marketing data. Our experienced
          marketing analytics consultants have over a decade of experience
          working at the best tech companies in the US.
        </p>
      </section>

      <section className="customized_marketing_container">
        <h2>
          Customized Marketing Analytics Solution
        </h2>

        <p>
          One size never fits all. This is especially true when we think about
          data analytics consulting. Some agencies, firms or companies might be
          able to help with part of problem statement, we at White Box Data
          combine our expertise in Analytics, Business Intelligence, Data
          Engineering and Growth Marketing to provide you with a full scale
          marketing analytics solution that serves your specific needs. We work
          with organizations of all sizes, large or small.
        </p>

        <h3>What to Expect?</h3>
        <ul>
          <li>
            We conduct in depth research with stakeholder interviews, Q&As,
            auditing current data systems to get a 360 view around all your
            marketing efforts.
          </li>
          <li>GAP analysis for As-Is vs To-Be solution.</li>
          <li>
            Create a North Star technical documentation for your data teams to
            effectively collect, store and manage data points.
          </li>
          <li>
            Create data extraction processes, data transformation layers and
            properly store them in your data warehouse for seamless analytics
            reporting
          </li>
          <li>
            Automate all ETL processes for daily, weekly, monthly reporting.
          </li>
          <li>
            Creating dashboards and visualizations that effectively communicate
            success for key metrics. Dashboards built are completely
            customizable and built for specific audiences, like Product Owners,
            Marketing Leads, Growth Marketers, Analysts C-Staff and such.
          </li>
          <li>
            Develop ETL processes based on the current KPIs so that you can
            optimize campaigns within a few hours of going live.
          </li>
          <li>
            Ensure data quality and governance around all your customer data
            with a scalable data quality framework.
          </li>
        </ul>

        <h3>Specific Marketing Analytics Services</h3>
        <ul>
          <li>
            Marketing Channel Attribution (Last Touch, First Touch, Multi Touch)
          </li>
          <li>Customer Insights & Engagement</li>
          <li>Personalization Insights</li>
          <li>Marketing Automation</li>
          <li>Funnel Optimization</li>
          <li>Campaign Optimization</li>
          <li>Custom API Integration for Signal Passback to Ad-platforms</li>
        </ul>

        <h3>Customer Analytics</h3>
        <ul>
          <li>Dormant & Churned Analysis</li>
          <li>Customer Retargeting & Retention</li>
          <li>Web Analytics</li>
          <li>Customer Analytics</li>
        </ul>

        <h3>Business Intelligence</h3>
        <ul>
          <li>Dashboards & Visualizations</li>
          <li>ETL & ELT Automation</li>
          <li>Data Quality Framework</li>
        </ul>

        <h2 className="">
          Our Clients' Success: <br /> Marketing Analytics with White Box Data
        </h2>

        <p>
          Our team has experience working with a variety of Marketing Analytics
          and Business Intelligence projects. As an example we enabled one of
          our US Financial Services client to gain insights into their marketing
          spend and customer tracking data.
        </p>

        <p>
          We conducted in-depth analysis of our client’s website tracking data
          to identify all touch points that their customers go through before a
          revenue conversion. Identified was the actual revenue & conversion
          split by different social ad campaigns.
        </p>

        <img className="marketing_image2" src={marketing_image2} alt=""></img>

        <div className="marketing_images_section">
          <img className="marketing_image1" src={marketing_image1} alt=""></img>

          <img className="marketing_image3" src={marketing_image3} alt=""></img>
        </div>

        <p>
          This enabled our client to optimize their campaigns effectively. Focus
          their marketing spend and efforts for high yield campaigns and fix
          their low converting campaigns. They were able to even understand
          which of their paid marketing efforts led to halo revenue conversions
          for other products and services.
        </p>

        <p>
          White Box consultants are seasoned marketing and data analytics
          professionals who are eager to help you. Whether you are just getting
          started on your data or marketing analytics journey or are
          experimenting or have a full blown ongoing marketing analytics
          initiative, White Box Data is here to help. Set up a Marketing
          Analytics Consultation today!
        </p>
      </section>

      <section className="consulting_cta_button">
        <div className="marketing_cta_text">
          <p>
            Transform your Marketing Analytics efforts. Schedule a complementary
            session with our lead Marketing Analytics consultant today!
          </p>

          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setIsModalOpen(true);
            }}
            className="talk-to-expert-btn"
          >
            Request a Consultation
          </a>
        </div>
      </section>

      <div className="marketing_services_container">
        <div className="marketing_services_box">
          <a href="/data">
            <div className="marketing_services_image">
              <img src={marketing_data_strategy_consulting} alt="" loading="lazy"></img>
            </div>

            <div className="bi_consulting_title">
              <p>Consulting</p>
              <span className="">Data Strategy Consulting</span>
            </div>
          </a>
        </div>
        <div className="marketing_services_box">
          <a href="/services/business-intelligence-and-analytics">
            <div className="marketing_services_image">
              <img src={marketing_business_intelligence} alt="" loading="lazy"></img>
            </div>

            <div className="bi_consulting_title">
              <p>Services</p>
              <span className="">Business Intelligence Services</span>
            </div>
          </a>
        </div>

        <div className="marketing_services_box">
          <a href="/blog/real-time-marketing-analytics">
            <div className="marketing_services_image">
              <img src={marketing_real_time_marketing_analytics} alt="" loading="lazy"></img>
            </div>

            <div className="bi_consulting_title">
              <p>Blog</p>
              <span className="">Real-Time Marketing Analytics</span>
            </div>
          </a>
        </div>
      </div>

      <a href="/contactus">
        <button className="fixed-button">Request a Consultation</button>
      </a>

      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setIsModalOpen(true);
        }}
        className="consulting_fixed_btn"
      >
        Talk to a Data Expert
      </a>

      <DataStrategyModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}
