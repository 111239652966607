import React from "react";

import AboutUs from "../Components/AboutUs";
import Layout from "../Layouts/Layout";

export default function AboutUsPage() {
  return (
    <Layout>
      <div>
        <AboutUs />
      </div>
    </Layout>
  );
}
