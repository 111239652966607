import React, { useState } from "react";
import PoweringBIOuter from "../Assets/Img/PoweringBIOuter.png";
import RealTimeMaOuter from "../Assets/Img/RealTimeMaOuter.png";
import MaTrends2024Outer from "../Assets/Img/MaTrends2024Outer.png";
import MarketingAutomationOuter from "../Assets/Img/MarketingAutomationOuter.png";
import FractionalBIOuter from "../Assets/Img/FractionalBIOuter.png";
import BiServicesOuter from "../Assets/Img/BiServicesOuter.png";
import GoogleCookiesOuter from "../Assets/Img/GoogleCookiesOuter.png";
import BiFinancialOuter from "../Assets/Img/BiFinancialOuter.png";
import BiHealthcareOuter from "../Assets/Img/BiHealthcareOuter.png";
import AiDrivenBiOuter from "../Assets/Img/AiDrivenBiOuter.png";
import MaAnalyticsOuter from "../Assets/Img/MaAnalyticsOuter.png";
import ArtScienceDataVisOuter from "../Assets/Img/ArtScienceDataVisOuter.png";
import DataLakeOuter from "../Assets/Img/DataLakeOuter.png";
import DataManagementOuter from "../Assets/Img/DataManagementOuter.png";
import ETLvsELTOuter from "../Assets/Img/ETLvsELTOuter.png";
import ETLToolsOuter from "../Assets/Img/ETLToolsOuter.png";
import { Helmet } from "react-helmet-async";
import "../Styles/Blog.css";

export default function Blog() {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const title = "Blog | White Box Data";
  const DESCRIPTION =
    "Explore White Box Data's blog for in-depth insights on data analytics, business intelligence, data science, and emerging technologies. Gain expert knowledge to transform your business through data-driven decision-making.";

  const blogs = [
    {
      id: 16,
      title: "Best 5 ETL Tools - Overview, Advantages and Disadvantages",
      category: "Data Engineering",
      filterCategory: ["Data Engineering & Infrastructure"],
      image: ETLToolsOuter,
      link: "/blog/best-etl-tools",
      time: "6 Min",
      date: "September 25, 2024",
    },
    {
      id: 15,
      title: "What is Data Management?",
      category: "Data Engineering",
      filterCategory: ["Data Engineering & Infrastructure"],
      image: DataManagementOuter,
      link: "/blog/what-is-data-management",
      time: "8 Min",
      date: "September 24, 2024",
    },
    {
      id: 14,
      title: "ETL vs. ELT: A Comprehensive Guide for Modern Data Integration",
      category: "Data Engineering",
      filterCategory: ["Data Engineering & Infrastructure"],
      image: ETLvsELTOuter,
      link: "/blog/etl-vs-elt",
      time: "8 Min",
      date: "September 24, 2024",
    },
    {
      id: 13,
      title: "Powering E-Commerce with Business Intelligence",
      category: "Business Intelligence",
      filterCategory: ["Business Intelligence & Analytics"],
      image: PoweringBIOuter,
      link: "/blog/business-intelligence-in-ecommerce-and-retail",
      time: "7 Min",
      date: "September 19, 2024",
    },
    {
      id: 12,
      title:
        "Real-Time Marketing Analytics: How Instant Data is Changing the Game",
      category: "Marketing Analytics",
      filterCategory: ["Growth & Marketing Technology"],
      image: RealTimeMaOuter,
      link: "/blog/real-time-marketing-analytics",
      time: "4 Min",
      date: "September 18, 2024",
    },
    {
      id: 11,
      title: "Marketing Analytics Consulting - A Fractional Approach",
      category: "Marketing Analytics",
      filterCategory: ["Growth & Marketing Technology"],
      image: MaAnalyticsOuter,
      link: "/blog/marketing-analytics-consulting-a-fractional-approach",
      time: "5 Min",
      date: "August 29, 2024",
    },
    {
      id: 10,
      title: "Top 5 Marketing Analytics Trends to Watch in 2024",
      category: "Marketing Analytics",
      filterCategory: ["Growth & Marketing Technology"],
      image: MaTrends2024Outer,
      link: "/blog/marketing-analytics-trends-to-watch",
      time: "3 Min",
      date: "August 20, 2024",
    },
    {
      id: 9,
      title:
        "Choosing the Right Consulting Partner for Marketing Automation: Why Data Analytics Expertise is Crucial",
      category: "Marketing Analytics",
      filterCategory: [
        "Business Intelligence & Analytics",
        "Growth & Marketing Technology",
      ],
      image: MarketingAutomationOuter,
      link: "/blog/choosing-the-right-marketing-automation-partner",
      time: "5 Min",
      date: "August 20, 2024",
    },
    {
      id: 8,
      title: "Fractional Business Intelligence Consultants",
      category: "Business Intelligence",
      filterCategory: ["Business Intelligence & Analytics"],
      image: FractionalBIOuter,
      link: "/blog/fractional-business-intelligence-consultants",
      time: "5 Min",
      date: "August 20, 2024",
    },
    {
      id: 7,
      title:
        "5 Benefits of Business Intelligence Services: Empower Your Organization with Data Driven Insights",
      category: "Business Intelligence",
      filterCategory: ["Business Intelligence & Analytics"],
      image: BiServicesOuter,
      link: "/blog/benefits-of-business-intelligence",
      time: "3 Min",
      date: "August 14, 2024",
    },
    {
      id: 6,
      title:
        "Google's Cookie Conundrum: The Rise and Fall (and Rise Again?) of Third-Party Cookies",
      category: "Marketing Analytics",
      filterCategory: [
        "Growth & Marketing Technology",
        "Business Intelligence & Analytics",
      ],
      image: GoogleCookiesOuter,
      link: "/blog/google-third-party-cookies-conundrum",
      time: "4 Min",
      date: "July 23, 2024",
    },
    {
      id: 5,
      title:
        "How Business Intelligence is Transforming Financial Services: 5 Key Areas to Watch",
      category: "Business Intelligence",
      filterCategory: ["Business Intelligence & Analytics"],
      image: BiFinancialOuter,
      link: "/blog/how-business-intelligence-will-transform-financial-services",
      time: "5 Min",
      date: "July 22, 2024",
    },
    {
      id: 4,
      title:
        "How Business Intelligence Will Transform Healthcare: 3 Key Areas to Watch",
      category: "Business Intelligence",
      filterCategory: ["Business Intelligence & Analytics"],
      image: BiHealthcareOuter,
      link: "/blog/how-business-intelligence-will-transform-healthcare",
      time: "5 Min",
      date: "July 18, 2024",
    },
    {
      id: 3,
      title:
        "AI Driven Business Intelligence (BI): Not New, But Now It's Necessary",
      category: "Business Intelligence",
      filterCategory: ["Business Intelligence & Analytics"],
      image: AiDrivenBiOuter,
      link: "/blog/ai-driven-bi",
      time: "4 Min",
      date: "July 12, 2024",
    },
    {
      id: 2,
      title:
        "The Art and Science of Data Visualization: Transforming Raw Data into Actionable Insights",
      category: "Data Visualization",
      filterCategory: ["Business Intelligence & Analytics"],
      image: ArtScienceDataVisOuter,
      link: "/blog/the-art-and-science-of-data-visualization",
      time: "4 Min",
      date: "June 26, 2024",
    },
    {
      id: 1,
      title:
        "Data Lake vs. Data Warehouse vs. Data Lakehouse: Unlocking Your Organization’s Data Potential",
      category: "Data Infrastructure",
      filterCategory: ["Data Engineering & Infrastructure"],
      image: DataLakeOuter,
      link: "/blog/datalake-vs-datawarehouse-vs-datalakehouse",
      time: "7 Min",
      date: "June 26, 2024",
    },
  ];

  const filteredBlogs =
    selectedCategory === "All"
      ? blogs
      : blogs.filter((blog) =>
          blog.filterCategory.some((category) => category === selectedCategory)
        );

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  return (
    <div className="blog-container">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      >
        <link rel="canonical" href="https://www.whiteboxdata.com/blog" />
      </Helmet>

      <section className="blog-home">
        <div className="blog-home-content">
          <h1>BLOG</h1>
          <p>
            Get the latest updates and deeper data experience from White Box
            Data
          </p>
          <div className="blog-home-box">
            <h3
              onClick={() => setSelectedCategory("All")}
              className={selectedCategory === "All" ? "active" : ""}
            >
              All
            </h3>
            <h3
              onClick={() =>
                setSelectedCategory("Business Intelligence & Analytics")
              }
              className={
                selectedCategory === "Business Intelligence & Analytics"
                  ? "active"
                  : ""
              }
            >
              Business Intelligence & Analytics
            </h3>
            <h3
              onClick={() =>
                setSelectedCategory("Growth & Marketing Technology")
              }
              className={
                selectedCategory === "Growth & Marketing Technology"
                  ? "active"
                  : ""
              }
            >
              Growth & Marketing Technology
            </h3>
            <h3
              onClick={() =>
                setSelectedCategory("Data Engineering & Infrastructure")
              }
              className={
                selectedCategory === "Data Engineering & Infrastructure"
                  ? "active"
                  : ""
              }
            >
              Data Engineering & Infrastructure
            </h3>
          </div>

          <div className="mobile-filters">
            <select value={selectedCategory} onChange={handleCategoryChange}>
              <option value="All">All</option>
              <option value="Business Intelligence & Analytics">Business Intelligence & Analytics</option>
              <option value="Growth & Marketing Technology">Growth & Marketing Technology</option>
              <option value="Data Engineering & Infrastructure">Data Engineering & Infrastructure</option>
            </select>
          </div>
        </div>
      </section>

      <section className="article-list">
        {filteredBlogs.map((blog, index) => (
          <a href={blog.link}>
            <div className="data-lake-blog">
              <div>
                <img src={blog.image} alt={blog.title} />
              </div>
              <div className="blog1-content">
                <h4>{blog.category}</h4>
                <h1>{blog.title}</h1>

                <div className="flex justify-between">
                  <p>{blog.date}</p>
                  {/* <p>{blog.time} Read</p> */}
                  <a href={blog.link}>Read More &gt;&gt;</a>
                </div>
              </div>
            </div>
          </a>
        ))}
      </section>
    </div>
  );
}
