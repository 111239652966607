import "../Styles/ContactUs.css";
import Form from "react-bootstrap/Form";
import emailjs from "@emailjs/browser";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import React, { useState, useRef, useEffect } from "react";
import contactus_image from "../Assets/Img/new contact us.png";
import email_contactus from "../Assets/icons/email_contactus.png";
import { Helmet } from "react-helmet-async";

export const ContactUs = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");

  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    // navigate("/");

    setSubmissionSuccess(true);

    emailjs
      .sendForm("service_rjnzoj6", "template_q33ji2w", form.current, {
        publicKey: "dLGaSfNHHKUpDuLYk",
      })

      .then(
        () => {
          console.log("SUCCESS!");
          // success.style.display = "block";
        },
        (error) => {
          console.log("FAILED...", error.text);
          alert("An error occurred while sending the message.");
        }
      );
  };

  console.log("Before rendering:", form);

  const title = "Contact Us | White Box Data";
  const DESCRIPTION =
    "Learn more about our Business Intelligence, Marketing Analytics, Data Engineering & AI Augmentation consulting services and projects. Consult with the top 0.1% of data consultants in the US.";

  useEffect(() => {
    document.title = title;
  });

  return (
    <section className="contactus-container">
      <Helmet
        onChangeClientState={(newState) => {
          const metaDescription = document.querySelector(
            'meta[name="description"]'
          );
          const metaOgTitle = document.querySelector(
            'meta[property="og:title"]'
          );

          if (metaDescription) {
            metaDescription.setAttribute("content", DESCRIPTION || "");
          }

          if (metaOgTitle) {
            metaOgTitle.setAttribute("content", title || "");
          }
        }}
      >
        <link rel="canonical" href="https://www.whiteboxdata.com/contactus" />

      </Helmet>
      <div className="contactus-main">


        <div className="contactus-image-text-section">
        <div className="contactus-image">
          <img src={contactus_image} alt="contactus_landing"></img>
        </div>


        <div className="contactus-text">
        <p>
          Questions, comments, or suggestions? Simply fill in the form or email
          us and we’ll be in touch shortly.
        </p>
        <div className="contactus-email-text">
        <img src={email_contactus} alt="email_logo"></img>
        <a href="mailto:consulting@whiteboxdata.com">
        <p className="emailid-text">consulting@whiteboxdata.com</p>
          </a>
        
        </div>
       
      </div>

        </div>
        
        <div className="contactus-form">
          <div class="container px-6 py-12 mx-auto">
            <div class="grid grid-cols-1 gap-12 mt-10 lg:grid-cols-1">
              <div class="p-4 py-6 form-bg md:p-8">
                {!submissionSuccess && (
                  <Form ref={form} onSubmit={sendEmail}>
                    <div className="-mx-2 md:items-center md:flex">
                      <div className="flex-1 px-2 mt-2 md:mt-0">
                      <label className="block mb-2 text-sm text-[#002c5f]">First Name*</label>
                        <input
                          type="text"
                          className="block w-full px-3 py-2.5 bg-[#f9f9f9] border border-[#cdd2db] rounded-lg focus:border-[#002c5f] focus:ring-opacity-0.01"
                          name="from_first_name"
                          required
                          pattern="[A-Za-z\s]+"
                          value={firstname}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>

                      <div className="flex-1 px-2 mt-2 md:mt-0">
                      <label className="block mb-2 text-sm text-[#002c5f]">Last Name*</label>

                        <input
                          type="text"
                         
                          className="block w-full px-3 py-2.5 bg-[#f9f9f9] border border-[#cdd2db] rounded-lg focus:border-[#002c5f] focus:ring-opacity-0.01"
                          name="from_last_name"
                          required
                          pattern="[A-Za-z\s]+"
                          value={lastname}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="mt-2">
                    <label className="block mb-2 text-sm text-[#002c5f]">Email*</label>
                      
                      <input
                        type="email"
                        
                        className="block w-full px-3 py-2.5 border bg-[#f9f9f9] border-[#cdd2db] rounded-lg focus:border-[#002c5f] focus:ring-opacity-0.01"
                        name="email_id"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <div className="mt-2">
                    <label className="block mb-2 text-sm text-[#002c5f]">Phone Number*</label>

                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        name="from_mobile"
                        required
                        defaultCountry="US"
                        value={mobile}
                        onChange={setMobile}
                        className="phoneinput-contact"
                      />
                    </div>

                    <div className="w-full mt-2">
                    <label className="block mb-2 text-sm text-[#002c5f]">Message</label>

                      <textarea
                        className="block w-full px-3 py-2.5 bg-[#f9f9f9] border border-[#cdd2db] rounded-lg md:h-56 focus:border-[#002c5f] focus:ring-opacity-0.01"
                        name="message"
                        type="text"
                        as="textarea"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>

                    <button className="contact-btn mt-4 tracking-wide" type="submit">
                      Send message
                    </button>
                  </Form>
                )}

                {submissionSuccess && (
                  <div className="message">
                    <div className="success" id="success">
                      Thank you! Our team will get back to you within 24-48
                      hours.
                    </div>
                    <a href="/">
                      <button
                        className="returnhome-btn tracking-wide w-full"
                        type="submit"
                      >
                        Return to Home
                      </button>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

     
    </section>
  );
};

export default ContactUs;

